import React from 'react';
import styled from 'styled-components';

import useMediaQuery from '@hooks/useMediaQuery';

export const Tos: React.FC = () => {
  const currentDeviceSize = useMediaQuery();

  return (
    <PageWrapper $isMobile={currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile'}>
      <TermsHeader>ZKP2P Terms of Service</TermsHeader>
      <TermsSubheader>Last modified: September 29th, 2024</TermsSubheader>
      <TermsContent>
        <TermsContentSection>
          These Terms of Service (the "Agreement") explain the terms and conditions by which you may
          access and use the Products provided by us (referred to herein as "P2P Labs", "we", "our", or "us"). The
          Products shall include, but shall not necessarily be limited to, (a) domains.zkp2p.xyz, a website-hosted
          user interface (the "Interface" or "App"). You must read this Agreement carefully as it governs
          your use of the Products. By accessing or using any of the Products, you signify that you have
          read, understand, and agree to be bound by this Agreement in its entirety. If you do not agree,
          you are not authorized to access or use any of our Products and should not use our Products.
          To access or use any of our Products, you must be able to form a legally binding contract with
          us. Accordingly, you represent that you are at least the age of majority in your jurisdiction (e.g.,
          18 years old in the United States) and have the full right, power, and authority to enter into and
          comply with the terms and conditions of this Agreement on behalf of yourself and any company
          or legal entity for which you may access or use the Interface. If you are entering into this
          Agreement on behalf of an entity, you represent to us that you have the legal authority to bind
          such entity.
        </TermsContentSection>

        <TermsContentSection>
          You further represent that you are not (a) the subject of economic or trade sanctions
          administered or enforced by any governmental authority or otherwise designated on any list of
          prohibited or restricted parties (including but not limited to the list maintained by the Office of
          Foreign Assets Control of the U.S. Department of the Treasury) or (b) a citizen, resident, or
          organized in a jurisdiction or territory that is the subject of comprehensive country-wide,
          territory-wide, or regional economic sanctions by the United States. Finally, you represent that
          your access and use of any of our Products will fully comply with all applicable laws and
          regulations, and that you will not access or use any of our Products to conduct, promote, or
          otherwise facilitate any illegal activity.
          NOTICE: This Agreement contains important information, including a binding arbitration
          provision and a class action waiver, both of which impact your rights as to how disputes are
          resolved. Our Products are only available to you — and you should only access any of our
          Products — if you agree completely with these terms.
        </TermsContentSection>

        <TermsContentHeader>
          1. Our Products
        </TermsContentHeader>

        <TermsContentSubHeader>
          1.1 The Interface
        </TermsContentSubHeader>

        <TermsContentSection>
          The Interface provides a web or mobile-based means of access to (a) a decentralized protocol
          on various public blockchains, including but not limited to Ethereum, that facilitates escrow
          transactions between counterparties who engage in transactions involving domain ownership
          proofs on Namecheap and stable currencies on-chain.

          The Interface is distinct from the Protocol and is one, but not the exclusive, means of accessing
          the Protocol. By using the Interface, you understand that you are not transacting with us and
          that we do not control trade execution on the Protocol.

          To access the Interface, you must use a non-custodial wallet software, which allows you to
          interact with public blockchains. Your relationship with that non-custodial wallet provider is
          governed by the applicable terms of service. We do not have custody or control over the
          contents of your wallet and have no ability to retrieve or transfer its contents. By connecting your
          wallet to our Interface, you agree to be bound by this Agreement and all of the terms
          incorporated herein by reference.
        </TermsContentSection>

        <TermsContentHeader>
          2. Modifications of this Agreement or our Products
        </TermsContentHeader>

        <TermsContentSubHeader>
          2.1 Modifications of this Agreement
        </TermsContentSubHeader>

        <TermsContentSection>
          We reserve the right, in our sole discretion, to modify this Agreement from time to time. If we
          make any material modifications, we will notify you by updating the date at the top of the
          Agreement and by maintaining a current version of the Agreement. All modifications will be
          effective when they are posted, and your continued accessing or use of any of the Products will
          serve as confirmation of your acceptance of those modifications. If you do not agree with any
          modifications to this Agreement, you must immediately stop accessing and using all of our
          Products.
        </TermsContentSection>

        <TermsContentSubHeader>
          2.2 Modifications of our Products
        </TermsContentSubHeader>

        <TermsContentSection>
          We reserve the following rights, which do not constitute obligations of ours: (a) with or without
          notice to you, to modify, substitute, eliminate or add to any of the Products; (b) to review, modify,
          filter, disable, delete and remove any and all content and information from any of the Products.
        </TermsContentSection>

        <TermsContentHeader>
          3. Intellectual Property Rights
        </TermsContentHeader>

        <TermsContentSubHeader>
          3.1 IP Rights Generally
        </TermsContentSubHeader>

        <TermsContentSection>
          We own all intellectual property and other rights in each of our Products and its respective
          contents, including, but not limited to, software, text, images, trademarks, service marks,
          copyrights, patents, designs, and its "look and feel." Subject to the terms of this Agreement, we
          grant you a limited, revocable, non-exclusive, non-sublicensable, non-transferable license to
          access and use our Products solely in accordance with this Agreement. You agree that you will
          not use, modify, distribute, tamper with, reverse engineer, disassemble or decompile any of our
          Products for any purpose other than as expressly permitted pursuant to this Agreement. Except
          as set forth in this Agreement, we grant you no rights to any of our Products, including any
          intellectual property rights.

          You understand and acknowledge that the Protocol is not a Product and we do not control the
          Protocol.
        </TermsContentSection>

        <TermsContentSection>
          By using any of our Products (including to list, post, or promote), you grant us a worldwide,
          non-exclusive, sublicensable, royalty-free license to use, copy, modify, and display any content,
          including but not limited to text, materials, images, files, communications, comments, feedback,
          suggestions, ideas, concepts, questions, data, or otherwise, that you post on or through any of
          our Products for our current and future business purposes, including to provide, promote, and
          improve the services. You grant to us a non-exclusive, transferable, worldwide, perpetual,
          irrevocable, fully-paid, royalty-free license, with the right to sublicense, under any and all
          intellectual property rights that you own or control to use, copy, modify, create derivative works
          based upon any suggestions or feedback for any purpose.
        </TermsContentSection>

        <TermsContentSection>
          You represent and warrant that you have, or have obtained, all rights, licenses, consents,
          permissions, power and/or authority necessary to grant the rights granted herein for any
          material that you list, post, promote, or display on or through any of our Products. You represent
          and warrant that such content does not contain material subject to copyright, trademark,
          publicity rights, or other intellectual property rights, unless you have necessary permission or
          are otherwise legally entitled to post the material and to grant us the license described above,
          and that the content does not violate any laws.
        </TermsContentSection>

        <TermsContentSubHeader>
          3.2 Third-Party Resources and Promotions
        </TermsContentSubHeader>

        <TermsContentSection>
          Our Products may contain references or links to third-party resources, including, but not limited
          to, information, materials, products, or services, that we do not own or control. In addition, third
          parties may offer promotions related to your access and use of our Products. We do not
          approve, monitor, endorse, warrant or assume any responsibility for any such resources or
          promotions. If you access any such resources or participate in any such promotions, you do so
          at your own risk, and you understand that this Agreement does not apply to your dealings or
          relationships with any third parties. You expressly relieve us of any and all liability arising from
          your use of any such resources or participation in any such promotions.
        </TermsContentSection>

        <TermsContentSubHeader>
          3.3 Additional Rights
        </TermsContentSubHeader>

        <TermsContentSection>
          We reserve the right to cooperate with any law enforcement, court or government investigation
          or order or third party requesting or directing that we disclose information or content or
          information that you provide.
        </TermsContentSection>

        <TermsContentHeader>
          4. Your Responsibilities
        </TermsContentHeader>

        <TermsContentSubHeader>
          4.1 Prohibited Activity
        </TermsContentSubHeader>

        <TermsContentSection>
          You agree not to engage in, or attempt to engage in, any of the following categories of
          prohibited activity in relation to your access and use of the Interface:
        </TermsContentSection>

        <TermsContentSection>
          ○ Intellectual Property Infringement. Activity that infringes on or violates any copyright,
          trademark, service mark, patent, right of publicity, right of privacy, or other proprietary or
          intellectual property rights under the law.
        </TermsContentSection>

        <TermsContentSection>
          ○ Cyberattack. Activity that seeks to interfere with or compromise the integrity, security, or
          proper functioning of any computer, server, network, personal device, or other
          information technology system, including, but not limited to, the deployment of viruses
          and denial of service attacks.
        </TermsContentSection>

        <TermsContentSection>
          ○ Fraud and Misrepresentation. Activity that seeks to defraud us or any other person or
          entity, including, but not limited to, providing any false, inaccurate, or misleading
          information in order to unlawfully obtain the property of another.
        </TermsContentSection>

        <TermsContentSection>
          ○ Market Manipulation. Activity that violates any applicable law, rule, or regulation
          concerning the integrity of trading markets, including, but not limited to, manipulative
          tactics and fraudulent activities.
        </TermsContentSection>

        <TermsContentSection>
          ○ Securities and Derivatives Violations. Activity that violates any applicable law, rule, or
          regulation concerning the trading of securities or derivatives, including, but not limited to,
          the unregistered offering of securities and the offering of leveraged and margined
          commodity products to retail customers in the United States.
        </TermsContentSection>

        <TermsContentSection>
          ○ Sale of Stolen Property. Buying, selling, or transferring of stolen items, fraudulently
          obtained items, items taken without authorization, and/or any other illegally obtained
          items.
        </TermsContentSection>

        <TermsContentSection>
          ○ Data Mining or Scraping. Activity that involves data mining, robots, scraping, or similar
          data gathering or extraction methods of content or information from any of our Products.
        </TermsContentSection>

        <TermsContentSection>
          ○ Objectionable Content. Activity that involves soliciting information from anyone under the
          age of 18 or that is otherwise harmful, threatening, abusive, harassing, tortious,
          excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of
          another's privacy, hateful, discriminatory, or otherwise objectionable.
        </TermsContentSection>

        <TermsContentSection>
          ○ Any Other Unlawful Conduct. Activity that violates any applicable law, rule, or regulation
          of the United States or another relevant jurisdiction, including, but not limited to, the
          restrictions and regulatory requirements imposed by U.S. law.
        </TermsContentSection>

        <TermsContentSubHeader>
          4.2 Trading
        </TermsContentSubHeader>

        <TermsContentSection>
          You agree and understand that: (a) all trades you submit through any of our Products are
          considered unsolicited, which means that they are solely initiated by you; (b) you have not
          received any investment advice from us in connection with any trades, including those you place
          via automated means; and (c) we do not conduct a suitability review of any trades you submit.
        </TermsContentSection>

        <TermsContentSubHeader>
          4.3 Non-Custodial and No Fiduciary Duties
        </TermsContentSubHeader>

        <TermsContentSection>
          Each of the Products is a purely non-custodial application, meaning we do not ever have
          custody, possession, or control of your digital assets at any time. It further means you are solely
          responsible for the custody of the cryptographic private keys to the digital asset wallets you hold
          and you should never share your wallet credentials or seed phrase with anyone. We accept no
          responsibility for, or liability to you, in connection with your use of a wallet and make no
          representations or warranties regarding how any of our Products will operate with any specific
          wallet. Likewise, you are solely responsible for any associated wallet and we are not liable for
          any acts or omissions by you in connection with or as a result of your wallet being
          compromised.
        </TermsContentSection>

        <TermsContentSection>
          This Agreement is not intended to, and does not, create or impose any fiduciary duties on us. To
          the fullest extent permitted by law, you acknowledge and agree that we owe no fiduciary duties
          or liabilities to you or any other party, and that to the extent any such duties or liabilities may
          exist at law or in equity, those duties and liabilities are hereby irrevocably disclaimed, waived,
          and eliminated. You further agree that the only duties and obligations that we owe you are those
          set out expressly in this Agreement.
        </TermsContentSection>

        <TermsContentSubHeader>
          4.4 Compliance and Tax Obligations
        </TermsContentSubHeader>

        <TermsContentSection>
          One or more of our Products may not be available or appropriate for use in your jurisdiction. By
          accessing or using any of our Products, you agree that you are solely and entirely responsible
          for compliance with all laws and regulations that may apply to you.

          Specifically, your use of our Products or the Protocol may result in various tax consequences,
          such as income or capital gains tax, value-added tax, goods and services tax, or sales tax in
          certain jurisdictions.

          It is your responsibility to determine whether taxes apply to any transactions you initiate or
          receive and, if so, to report and/or remit the correct tax to the appropriate tax authority.
        </TermsContentSection>

        <TermsContentSubHeader>
          4.5 Gas Fees
        </TermsContentSubHeader>

        <TermsContentSection>
          Blockchain transactions require the payment of transaction fees to the appropriate network
          ("Gas Fees"). Except as otherwise expressly set forth in the terms of another offer by us, you
          will be solely responsible to pay the Gas Fees for any transaction that you initiate via any of our
          Products.
        </TermsContentSection>

        <TermsContentSubHeader>
          4.6 Release of Claims
        </TermsContentSubHeader>

        <TermsContentSection>
          You expressly agree that you assume all risks in connection with your access and use of any of
          our Products. You further expressly waive and release us from any and all liability, claims,
          causes of action, or damages arising from or in any way relating to your use of any of our
          Products. If you are a California resident, you waive the benefits and protections of California
          Civil Code § 1542, which provides: "[a] general release does not extend to claims that the
          creditor or releasing party does not know or suspect to exist in his or her favor at the time of
          executing the release and that, if known by him or her, would have materially affected his or her
          settlement with the debtor or released party."
        </TermsContentSection>

        <TermsContentHeader>
          5. DISCLAIMERS
        </TermsContentHeader>

        <TermsContentSubHeader>
          5.1 ASSUMPTION OF RISK — GENERALLY
        </TermsContentSubHeader>

        <TermsContentSection>
          BY ACCESSING AND USING ANY OF OUR PRODUCTS, YOU REPRESENT THAT YOU ARE
          FINANCIALLY AND TECHNICALLY SOPHISTICATED ENOUGH TO UNDERSTAND THE
          INHERENT RISKS ASSOCIATED WITH USING CRYPTOGRAPHIC AND
          BLOCKCHAIN-BASED SYSTEMS, AND THAT YOU HAVE A WORKING KNOWLEDGE OF
          THE USAGE AND INTRICACIES OF DIGITAL ASSETS SUCH AS ETHER (ETH), SO-CALLED
          STABLECOINS, AND OTHER DIGITAL TOKENS SUCH AS THOSE FOLLOWING THE
          ETHEREUM TOKEN STANDARD (ERC-20).

          IN PARTICULAR, YOU UNDERSTAND THAT THE MARKETS FOR THESE DIGITAL ASSETS
          ARE NASCENT AND HIGHLY VOLATILE DUE TO RISK FACTORS INCLUDING, BUT NOT
          LIMITED TO, ADOPTION, SPECULATION, TECHNOLOGY, SECURITY, AND REGULATION.
          YOU UNDERSTAND THAT ANYONE CAN CREATE A TOKEN, INCLUDING FAKE VERSIONS
          OF EXISTING TOKENS AND TOKENS THAT FALSELY CLAIM TO REPRESENT PROJECTS,
          AND ACKNOWLEDGE AND ACCEPT THE RISK THAT YOU MAY MISTAKENLY TRADE
          THOSE OR OTHER TOKENS. SO-CALLED STABLECOINS MAY NOT BE AS STABLE AS
          THEY PURPORT TO BE, MAY NOT BE FULLY OR ADEQUATELY COLLATERALIZED, AND
          MAY BE SUBJECT TO PANICS AND RUNS.

          FURTHER, YOU UNDERSTAND THAT SMART CONTRACT TRANSACTIONS
          AUTOMATICALLY EXECUTE AND SETTLE, AND THAT BLOCKCHAIN-BASED
          TRANSACTIONS ARE IRREVERSIBLE WHEN CONFIRMED. YOU ACKNOWLEDGE AND
          ACCEPT THAT THE COST AND SPEED OF TRANSACTING WITH CRYPTOGRAPHIC AND
          BLOCKCHAIN-BASED SYSTEMS SUCH AS ETHEREUM ARE VARIABLE AND MAY
          INCREASE DRAMATICALLY AT ANY TIME. YOU FURTHER ACKNOWLEDGE AND ACCEPT
          THE RISK OF SELECTING TO TRADE IN EXPERT MODES, WHICH CAN EXPOSE YOU TO
          POTENTIALLY SIGNIFICANT PRICE SLIPPAGE AND HIGHER COSTS.

          FINALLY, YOU UNDERSTAND THAT WE DO NOT CREATE, OWN, OR OPERATE
          CROSS-CHAIN BRIDGES AND WE DO NOT MAKE ANY REPRESENTATION OR
          WARRANTY ABOUT THE SAFETY OR SOUNDNESS OF ANY CROSS-CHAIN BRIDGE.

          IN SUMMARY, YOU ACKNOWLEDGE THAT WE ARE NOT RESPONSIBLE FOR ANY OF
          THESE VARIABLES OR RISKS, DO NOT OWN OR CONTROL THE PROTOCOL, AND
          CANNOT BE HELD LIABLE FOR ANY RESULTING LOSSES THAT YOU EXPERIENCE
          WHILE ACCESSING OR USING ANY OF OUR PRODUCTS. ACCORDINGLY, YOU
          UNDERSTAND AND AGREE TO ASSUME FULL RESPONSIBILITY FOR ALL OF THE RISKS
          OF ACCESSING AND USING THE INTERFACE TO INTERACT WITH THE PROTOCOL.
        </TermsContentSection>

        <TermsContentSubHeader>
          5.2 NO WARRANTIES
        </TermsContentSubHeader>

        <TermsContentSection>
          EACH OF OUR PRODUCTS IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.
          TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ANY REPRESENTATIONS
          AND WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY,
          INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY AND
          FITNESS FOR A PARTICULAR PURPOSE. YOU ACKNOWLEDGE AND AGREE THAT YOUR
          USE OF EACH OF OUR PRODUCTS IS AT YOUR OWN RISK. WE DO NOT REPRESENT OR
          WARRANT THAT ACCESS TO ANY OF OUR PRODUCTS WILL BE CONTINUOUS,
          UNINTERRUPTED, TIMELY, OR SECURE; THAT THE INFORMATION CONTAINED IN ANY
          OF OUR PRODUCTS WILL BE ACCURATE, RELIABLE, COMPLETE, OR CURRENT; OR
          THAT ANY OF OUR PRODUCTS WILL BE FREE FROM ERRORS, DEFECTS, VIRUSES, OR
          OTHER HARMFUL ELEMENTS. NO ADVICE, INFORMATION, OR STATEMENT THAT WE
          MAKE SHOULD BE TREATED AS CREATING ANY WARRANTY CONCERNING ANY OF
          OUR PRODUCTS. WE DO NOT ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
          FOR ANY ADVERTISEMENTS, OFFERS, OR STATEMENTS MADE BY THIRD PARTIES
          CONCERNING ANY OF OUR PRODUCTS.
        </TermsContentSection>

        <TermsContentSection>
          SIMILARLY, THE PROTOCOL IS PROVIDED "AS IS", AT YOUR OWN RISK, AND WITHOUT
          WARRANTIES OF ANY KIND. NO DEVELOPER OR ENTITY INVOLVED IN CREATING THE PROTOCOL WILL BE
          LIABLE FOR ANY CLAIMS OR DAMAGES WHATSOEVER ASSOCIATED WITH YOUR USE,
          INABILITY TO USE, OR YOUR INTERACTION WITH OTHER USERS OF, THE PROTOCOL,
          INCLUDING ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR
          CONSEQUENTIAL DAMAGES, OR LOSS OF PROFITS, CRYPTOCURRENCIES, TOKENS, OR
          ANYTHING ELSE OF VALUE. WE DO NOT ENDORSE, GUARANTEE, OR ASSUME
          RESPONSIBILITY FOR ANY ADVERTISEMENTS, OFFERS, OR STATEMENTS MADE BY
          THIRD PARTIES CONCERNING ANY OF OUR PRODUCTS.
        </TermsContentSection>

        <TermsContentSection>
          ANY PAYMENTS OR FINANCIAL TRANSACTIONS THAT YOU ENGAGE IN WILL BE
          PROCESSED VIA AUTOMATED SMART CONTRACTS. ONCE EXECUTED, WE HAVE NO
          CONTROL OVER THESE PAYMENTS OR TRANSACTIONS, NOR DO WE HAVE THE
          ABILITY TO REVERSE ANY PAYMENTS OR TRANSACTIONS.
        </TermsContentSection>

        <TermsContentSubHeader>
          5.3 NO INVESTMENT ADVICE
        </TermsContentSubHeader>

        <TermsContentSection>
          WE MAY PROVIDE INFORMATION ABOUT TOKENS IN OUR PRODUCTS SOURCED FROM
          THIRD-PARTY DATA PARTNERS THROUGH FEATURES SUCH AS TOKEN EXPLORERS OR
          TOKEN LISTS. WE MAY ALSO PROVIDE WARNING LABELS FOR CERTAIN TOKENS. THE
          PROVISION OF INFORMATIONAL MATERIALS DOES NOT MAKE TRADES IN THOSE
          TOKENS SOLICITED; WE ARE NOT ATTEMPTING TO INDUCE YOU TO MAKE ANY
          PURCHASE AS A RESULT OF INFORMATION PROVIDED. ALL SUCH INFORMATION
          PROVIDED BY ANY OF OUR PRODUCTS IS FOR INFORMATIONAL PURPOSES ONLY AND
          SHOULD NOT BE CONSTRUED AS INVESTMENT ADVICE OR A RECOMMENDATION THAT A
          PARTICULAR TOKEN IS A SAFE OR SOUND INVESTMENT. YOU SHOULD NOT TAKE, OR
          REFRAIN FROM TAKING, ANY ACTION BASED ON ANY INFORMATION CONTAINED IN ANY
          OF OUR PRODUCTS. BY PROVIDING TOKEN INFORMATION FOR YOUR CONVENIENCE, WE
          DO NOT MAKE ANY INVESTMENT RECOMMENDATIONS TO YOU OR OPINE ON THE
          MERITS OF ANY TRANSACTION OR OPPORTUNITY. YOU ALONE ARE RESPONSIBLE FOR
          DETERMINING WHETHER ANY INVESTMENT, INVESTMENT STRATEGY OR RELATED
          TRANSACTION IS APPROPRIATE FOR YOU BASED ON YOUR PERSONAL INVESTMENT
          OBJECTIVES, FINANCIAL CIRCUMSTANCES, AND RISK TOLERANCE.
        </TermsContentSection>

        <TermsContentHeader>
          6. Indemnification
        </TermsContentHeader>

        <TermsContentSection>
          You agree to hold harmless, release, defend, and indemnify us and our officers, directors,
          employees, contractors, agents, affiliates, and subsidiaries from and against all claims,
          damages, obligations, losses, liabilities, costs, and expenses arising from: (a) your access and
          use of any of our Products; (b) your violation of any term or condition of this Agreement, the
          right of any third party, or any other applicable law, rule, or regulation; (c) any other party's
          access and use of any of our Products with your assistance or using any device or account that
          you own or control; and (d) any dispute between you and (i) any other user of any of the
          Products or (ii) any of your own customers or users.
        </TermsContentSection>

        <TermsContentHeader>
          7. Limitation of Liability
        </TermsContentHeader>

        <TermsContentSection>
          UNDER NO CIRCUMSTANCES SHALL WE OR ANY OF OUR OFFICERS, DIRECTORS,
          EMPLOYEES, CONTRACTORS, AGENTS, AFFILIATES, OR SUBSIDIARIES BE LIABLE TO
          YOU FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
          EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
          PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE PROPERTY, ARISING OUT OF
          OR RELATING TO ANY ACCESS OR USE OF OR INABILITY TO ACCESS OR USE ANY OF
          THE PRODUCTS, NOR WILL WE BE RESPONSIBLE FOR ANY DAMAGE, LOSS, OR
          INJURY RESULTING FROM HACKING, TAMPERING, OR OTHER UNAUTHORIZED ACCESS
          OR USE OF ANY OF THE PRODUCTS OR THE INFORMATION CONTAINED WITHIN IT,
          WHETHER SUCH DAMAGES ARE BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT
          LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED
          OR UNAUTHORIZED USE OF ANY OF THE PRODUCTS, EVEN IF AN AUTHORIZED
          REPRESENTATIVE OF US HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN
          OF THE POSSIBILITY OF SUCH DAMAGES. WE ASSUME NO LIABILITY OR
          RESPONSIBILITY FOR ANY: (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT;
          (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
          RESULTING FROM ANY ACCESS OR USE OF THE INTERFACE; (C) UNAUTHORIZED
          ACCESS OR USE OF ANY SECURE SERVER OR DATABASE IN OUR CONTROL, OR THE
          USE OF ANY INFORMATION OR DATA STORED THEREIN; (D) INTERRUPTION OR
          CESSATION OF FUNCTION RELATED TO ANY OF THE PRODUCTS; (E) BUGS, VIRUSES,
          TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE
          INTERFACE; (F) ERRORS OR OMISSIONS IN, OR LOSS OR DAMAGE INCURRED AS A
          RESULT OF THE USE OF, ANY CONTENT MADE AVAILABLE THROUGH ANY OF THE
          PRODUCTS; AND (G) THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY
          THIRD PARTY.
        </TermsContentSection>

        <TermsContentSection>
          WE HAVE NO LIABILITY TO YOU OR TO ANY THIRD PARTY FOR ANY CLAIMS OR
          DAMAGES THAT MAY ARISE AS A RESULT OF ANY PAYMENTS OR TRANSACTIONS THAT
          YOU ENGAGE IN VIA ANY OF OUR PRODUCTS, OR ANY OTHER PAYMENT OR
          TRANSACTIONS THAT YOU CONDUCT VIA ANY OF OUR PRODUCTS. EXCEPT AS
          EXPRESSLY PROVIDED FOR HEREIN, WE DO NOT PROVIDE REFUNDS FOR ANY
          PURCHASES THAT YOU MIGHT MAKE ON OR THROUGH ANY OF OUR PRODUCTS.

          WE MAKE NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, ABOUT
          LINKED THIRD-PARTY SERVICES, THE THIRD PARTIES THEY ARE OWNED AND
          OPERATED BY, THE INFORMATION CONTAINED ON THEM, ASSETS AVAILABLE
          THROUGH THEM, OR THE SUITABILITY, PRIVACY, OR SECURITY OF THEIR PRODUCTS
          OR SERVICES. YOU ACKNOWLEDGE SOLE RESPONSIBILITY FOR AND ASSUME ALL
          RISK ARISING FROM YOUR USE OF THIRD-PARTY SERVICES, THIRD-PARTY WEBSITES,
          APPLICATIONS, OR RESOURCES. WE SHALL NOT BE LIABLE UNDER ANY
          CIRCUMSTANCES FOR DAMAGES ARISING OUT OF OR IN ANY WAY RELATED TO
          SOFTWARE, PRODUCTS, SERVICES, AND/OR INFORMATION OFFERED OR PROVIDED
          BY THIRD PARTIES AND ACCESSED THROUGH ANY OF OUR PRODUCTS.

          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL
          INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY
          NOT APPLY TO YOU. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL
          DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES
          INVOLVING PERSONAL INJURY) EXCEED THE AMOUNT OF ONE HUNDRED U.S.
          DOLLARS ($100.00 USD) OR ITS EQUIVALENT IN THE LOCAL CURRENCY OF THE
          APPLICABLE JURISDICTION.
        </TermsContentSection>

        <TermsContentSection>
          THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED BY LAW.
        </TermsContentSection>

        <TermsContentHeader>
          8. Governing Law and Venue
        </TermsContentHeader>

        <TermsContentSection>
          This Agreement and your access to and use of the Site will be governed by the laws of the State
          of California, U.S.A., without regard to conflict of law rules or principles (whether of the State of
          California or any other jurisdiction) that would cause the application of the laws of any other
          jurisdiction. Any dispute between the parties that is not subject to arbitration or cannot be heard
          in small claims court shall be resolved in the state or federal courts of the State of California and
          the United States, respectively, sitting in San Francisco County, California.
        </TermsContentSection>

        <TermsContentHeader>
          9. Miscellaneous
        </TermsContentHeader>

        <TermsContentSubHeader>
          9.1 Entire Agreement
        </TermsContentSubHeader>

        <TermsContentSection>
          These terms constitute the entire agreement between you and us with respect to the subject
          matter hereof. This Agreement supersedes any and all prior or contemporaneous written and
          oral agreements, communications and other understandings (if any) relating to the subject
          matter of the terms.
        </TermsContentSection>

        <TermsContentSubHeader>
          9.2 Assignment
        </TermsContentSubHeader>

        <TermsContentSection>
          You may not assign or transfer this Agreement, by operation of law or otherwise, without our
          prior written consent. Any attempt by you to assign or transfer this Agreement without our prior
          written consent shall be null and void. We may freely assign or transfer this Agreement. Subject
          to the foregoing, this Agreement will bind and inure to the benefit of the parties, their successors
          and permitted assigns.
        </TermsContentSection>

        <TermsContentSubHeader>
          9.3 Rewards
        </TermsContentSubHeader>

        <TermsContentSection>
          In connection with your historic or current use of one or more of our Products, we may provide
          you certain incentives, prizes, or rewards for completing certain activities, such as completing a
          certain number of transactions ("User Rewards"). Details regarding the criteria for earning a
          reward will be described within the applicable Product or official documentation. Upon
          satisfaction of the criteria for obtaining a reward and subject to your compliance with the
          associated rewards terms, this Agreement, and applicable law—to be determined exclusively
          by us—we will use commercially reasonable efforts to promptly transfer the earned reward to
          the digital wallet that you designate or have connected to the applicable Product. We reserve the
          right to change, modify, discontinue, or cancel any rewards programs (including the frequency
          and criteria for earning such User Rewards), at any time and without notice to you.
        </TermsContentSection>

        <TermsContentSubHeader>
          9.4 Not Registered with the SEC or Any Other Agency
        </TermsContentSubHeader>

        <TermsContentSection>
          We are not registered with the U.S. Securities and Exchange Commission as a national
          securities exchange or in any other capacity. You understand and acknowledge that we do not
          broker trading orders on your behalf. We also do not facilitate the execution or settlement of
          your trades, which occur entirely on public distributed blockchains like Ethereum. As a result, we
          do not (and cannot) guarantee market best pricing or best execution through our Products.
        </TermsContentSection>

        <TermsContentSubHeader>
          9.5 Notice
        </TermsContentSubHeader>

        <TermsContentSection>
          We may provide any notice to you under this Agreement using commercially reasonable means,
          including using public communication channels. Notices we provide by using public
          communication channels will be effective upon posting.
        </TermsContentSection>

        <TermsContentSubHeader>
          9.6 Severability
        </TermsContentSubHeader>

        <TermsContentSection>
          If any provision of this Agreement shall be determined to be invalid or unenforceable under any
          rule, law, or regulation of any local, state, or federal government agency, such provision will be
          changed and interpreted to accomplish the objectives of the provision to the greatest extent
          possible under any applicable law and the validity or enforceability of any other provision of this
          Agreement shall not be affected.
        </TermsContentSection>
      </TermsContent>
    </PageWrapper>
  );
};

const PageWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 12px 32px 0px;
  }
  padding-bottom: ${props => props.$isMobile ? '7rem' : '3rem'};

  margin: auto;
  max-width: 660px;
`;

const TermsHeader = styled.div`
  font-size: 28px;
  padding-bottom: 1.5rem;
  text-align: left;
  font-weight: 600;
`;

const TermsSubheader = styled.div`
  font-size: 18px;
  padding-bottom: 1.5rem;
  text-align: left;
  font-weight: 600;
`;

const TermsContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1rem;
`;

const TermsContentHeader = styled.div`
  font-size: 20px;
  padding-bottom: 1rem;
  text-align: left;
`;

const TermsContentSubHeader = styled.div`
  font-size: 18px;
  padding-bottom: 1rem;
  text-align: left;
`;

const TermsContentSection = styled.div`
  font-size: 16px;
  padding-bottom: 1rem;
  text-align: left;
`;
