import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Inbox } from 'react-feather';
import styled, { css } from 'styled-components/macro';

import { Button } from '@components/common/Button';
import { DomainsRow } from '@components/SellerListings/NewListing/DomainsRow'; 
import { Domain } from '@helpers/types';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useTableScroll from '@hooks/useTableScroll';
import useAccount from '@hooks/contexts/useAccount';
import useSellers from '@hooks/contexts/useSellers';


export interface DomainRowData {
  domainId: string;
  name: string;
}

interface DomainsTableProps {
  listedDomainIds: string[];
  handleVerifyDomainsPressed: () => void;
  setSelectedDomainId: Dispatch<SetStateAction<string>>;
}

export const DomainsTable: React.FC<DomainsTableProps> = ({
  listedDomainIds,
  handleVerifyDomainsPressed,
  setSelectedDomainId
}) => {
  /*
   * Contexts
   */

  const { isLoggedIn } = useAccount();
  const { sellerDomains } = useSellers();

  /*
   * State
   */

  const [domainsRowData, setDomainsRowData] = useState<DomainRowData[]>([]);

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  /*
   * Handlers
   */

  const handleRowClick = (index: number) => {
    setSelectedIndex(index);

    if (domainsRowData && domainsRowData.length > 0) {
      const selectedDomainId = domainsRowData[index]?.domainId;
      
      setSelectedDomainId(selectedDomainId);
    } else {
      setSelectedDomainId('');
    }
  };
  
  /*
   * Hooks
   */

  useEffect(() => {
    if (sellerDomains && sellerDomains.length > 0) {
      const sanitizedDomains: DomainRowData[] = [];
      sellerDomains.forEach((domain: Domain) => {
        const domainId = domain.domainId;
        const isDomainAlreadyListed = listedDomainIds.includes(domainId);
        if (isDomainAlreadyListed) {
          return;
        }

        const name = domain.name;

        sanitizedDomains.push({
          domainId,
          name,
        } as DomainRowData);
      });

      setDomainsRowData(sanitizedDomains);
    } else {
      setDomainsRowData([]);
    }
  }, [sellerDomains, listedDomainIds]);

  const {
    tableRef,
    isScrolling
  } = useTableScroll();

  /*
   * Component
   */

  function renderContent() {
    return (
      <Content>
        {domainsRowData.length === 0 ? (
          <ErrorContainer>
            <SelectDomainsAndAddDomainsContainer>
              <ThemedText.BodySmall textAlign="left">
                Select Domain
              </ThemedText.BodySmall>
            </SelectDomainsAndAddDomainsContainer>

            <ErrorIconAndMessageContainer>
              <InboxIcon strokeWidth={1}/>

              <ThemedText.DeprecatedBody textAlign="center" paddingBottom={'0.5rem'}>
                You have no unlisted domains. To create a listing, verify ownership of your Namecheap domains
              </ThemedText.DeprecatedBody>

              <Button
                onClick={handleVerifyDomainsPressed}
                height={44}
              >
                  Verify Domains
              </Button>
            </ErrorIconAndMessageContainer>
          </ErrorContainer>
        ) : (
          <>
            <SelectDomainsAndAddDomainsContainer>
              <ThemedText.BodySmall textAlign="left">
                Select Domain
              </ThemedText.BodySmall>

              {isLoggedIn ? (
                <Button onClick={handleVerifyDomainsPressed} height={40}>
                  + Verify Domains
                </Button>
              ) : null}
            </SelectDomainsAndAddDomainsContainer>

            <Table ref={tableRef}>
              {domainsRowData.map((domainRowData, rowIndex) => (
                <DomainRowStyled key={rowIndex}>
                  <DomainsRow
                    isScrolling={isScrolling}
                    name={domainRowData.name}
                    isSelected={selectedIndex === rowIndex}
                    onRowClick={() => handleRowClick(rowIndex)}
                    rowIndex={rowIndex}
                  />
                </DomainRowStyled>
              ))}
            </Table>
          </>
        )}
      </Content>
    )
  }

  return (
    <>
      {renderContent()}
    </>
  )
};

const Content = styled.div`
  display: flex;
  background-color: #FAF9F6;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: 600px) {
    border-radius: 16px;
    border: 1px solid ${colors.defaultBorderColor};
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 25vh;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const SelectDomainsAndAddDomainsContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.defaultBorderColor};
  padding: 0.75rem 1rem;
`;

const ErrorIconAndMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  padding: 2rem;
  line-height: 1.3;
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;
  max-height: 268px;
  overflow-y: auto;

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const DomainRowStyled = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;
