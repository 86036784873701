import React, { useState, useEffect } from 'react';
import { Inbox } from 'react-feather';
import styled, { css } from 'styled-components/macro';
import Link from '@mui/material/Link';

import { RowBetween } from '@components/layouts/Row';
import { BidState } from '@helpers/types/bid';
import { toEthStringWithDecimals } from '@helpers/units';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { DetailedListing } from '@helpers/types';
import { Bid } from '@helpers/types';
import { BidRow } from '@components/Listing/BidRow';
import { getBidState } from '@helpers/bidTableHelpers';
import QuestionHelper from '@components/common/QuestionHelper';
import { commonStrings } from '@helpers/strings';
import { BID_STATE_DOCS_LINK, INSTANT_ACCEPT_DOCS_LINK } from '@helpers/docUrls';
import useTableScroll from '@hooks/useTableScroll';
import useMediaQuery from '@hooks/useMediaQuery';


interface BidRowData {
  listingId: string;
  buyerAddress: string;
  bidPrice: string;
  createdAt: bigint;
  bidState: BidState;
  instantAccept: boolean;
}

interface BidTableProps {
  listing: DetailedListing | null;
}

export const BidTable: React.FC<BidTableProps> = ({
  listing
}) => {
  const isMobile = useMediaQuery() === 'mobile';

  /*
   * State
   */

  const [bidRowsData, setBidRowsData] = useState<BidRowData[]>([]);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);

  /*
   * Hooks
   */

  const { tableRef, isScrolling } = useTableScroll();

  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      setIsScrollEnabled(tableElement.scrollHeight > tableElement.clientHeight);
    }
  }, [bidRowsData, tableRef]);


  useEffect(() => {
    const processSellerListingsForBids = async () => {
      if (listing && listing.bids && listing.bids.length > 0) {
       
        const listingBids: Bid[] = listing.bids;

        // Sort bids by price
        listingBids.sort((a, b) => Number(b.price - a.price));

        const bidRowsData: BidRowData[] = [];
        
        // Extract Bid Info
        for (const bid of listingBids) {
          const bidPrice = `${toEthStringWithDecimals(bid.price, false, 4)} ETH`;
          const bidState = getBidState(bid, listing);
          if (bidState === BidState.Expired) {
            continue;
          }
          
          bidRowsData.push({
            listingId: listing.listingId.toString(),
            buyerAddress: bid.buyer,
            bidPrice: bidPrice,
            createdAt: bid.createdAt,
            instantAccept: bid.instantAccept,
            bidState: bidState
          });
        }

        setBidRowsData(bidRowsData);
      } else {
        setBidRowsData([]);
      }
    };
  
    processSellerListingsForBids();
  }, [listing]);



  /*
   * Component
   */


  return (
    <Container>
      <Content>        
        {bidRowsData.length === 0 ? (
            <ErrorContainer>
              <ThemedText.DeprecatedBody textAlign="center">
                <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                <div>
                  No bids yet
                </div>
              </ThemedText.DeprecatedBody>
            </ErrorContainer>
        ) : (
          <>
            <TitleRow>
              <ThemedText.LabelSmall>
                Bids
              </ThemedText.LabelSmall>
            </TitleRow>

            <TableContainer>
              <TableHeaderRow isMobile={isMobile} isScrollEnabled={isScrollEnabled}>
                <ColumnHeader>
                  <ColumnHeaderText>#</ColumnHeaderText>
                </ColumnHeader>

                {!isMobile && (
                  <ColumnHeaderWithTooltip>
                    <ColumnHeaderText>State</ColumnHeaderText>
                    <StateQuestionHelper 
                      text={
                        <>
                          {commonStrings.get('BID_STATE_TOOLTIP')}
                          {' '}
                          <Link
                            href={BID_STATE_DOCS_LINK}
                            target='_blank'
                            style={{ color: 'inherit' }}
                          >
                            Read more ↗
                          </Link>
                        </>
                      }
                      size='sm'
                    />
                  </ColumnHeaderWithTooltip>
                )}
                
                <ColumnHeader>
                  <ColumnHeaderText>Bid</ColumnHeaderText>
                </ColumnHeader>
                
                <ColumnHeader>
                  <ColumnHeaderText>Buyer</ColumnHeaderText>
                </ColumnHeader>

                {!isMobile && (
                  <ColumnHeaderWithTooltip>
                    <ColumnHeaderText>Instant Accept</ColumnHeaderText>
                    <QuestionHelper 
                      text={
                        <>
                          {commonStrings.get('INSTANT_ACCEPT_TOOLTIP')}
                          {' '}
                          <Link
                            href={INSTANT_ACCEPT_DOCS_LINK}
                            target='_blank'
                            style={{ color: 'inherit' }}
                          >
                            Read more ↗
                          </Link>
                        </>
                      }
                      size='sm'
                    />
                  </ColumnHeaderWithTooltip>
                )}

                {!isMobile && (
                  <ColumnHeader>
                    <ColumnHeaderText>Placed At</ColumnHeaderText>
                  </ColumnHeader>
                )}

              </TableHeaderRow>

              <Table ref={tableRef}>
                {bidRowsData.map((bidRowData, rowIndex) => (
                  <StyledBidRow key={rowIndex}>
                    <BidRow
                      listingId={bidRowData.listingId}
                      buyerAddress={bidRowData.buyerAddress}
                      bidPrice={bidRowData.bidPrice}
                      createdAt={bidRowData.createdAt}
                      bidState={bidRowData.bidState}
                      instantAccept={bidRowData.instantAccept}
                      rowIndex={rowIndex}
                      isScrolling={isScrolling}
                    />
                  </StyledBidRow>
                ))}
              </Table>
            </TableContainer>
          </>
        )}
      </Content>
    </Container>
  )
};

const Container = styled.div`
  gap: 1rem;
  border-radius: 16px;
`;

const Content = styled.div`
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;
  border-radius: 16px;
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;

  @media (max-width: 600px) {
    min-height: 20vh;
  };
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: 1rem 1.25rem;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 1rem 1.25rem;
  };
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const TableContainer = styled.div`
  width: 100%;
`;

const TableHeaderRow = styled.div<{ isMobile: boolean, isScrollEnabled: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => isMobile ? '1fr 2fr 2fr' : '1fr 2fr 2fr 2.5fr 3fr 3fr'};
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  text-align: left;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
  border-top: 1px solid ${colors.defaultBorderColor};
  padding-right: ${({ isScrollEnabled }) => isScrollEnabled ? 'calc(1.5rem + 8px)' : '1.5rem'};
`;

const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const ColumnHeaderWithTooltip = styled(ColumnHeader)`
  gap: 4px;
`;

const ColumnHeaderText = styled.div`
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const StateQuestionHelper = styled(QuestionHelper)`
  align-items: end;
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;
  max-height: 280px;
  overflow-y: auto;
  scrollbar-width: thin;
  
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.defaultBorderColor};
    border-radius: 4px;
  }

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledBidRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;