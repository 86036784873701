import React, { useEffect, useState, useCallback } from 'react';
import { Inbox, Search, ChevronUp, ChevronDown } from 'react-feather';
import styled, { css } from 'styled-components/macro';

import { RowBetween } from '@components/layouts/Row';
import { ListingRow } from '@components/Listings/ListingRow'; 
import { DetailedListing } from '@helpers/types';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { toEthStringWithDecimals } from '@helpers/units';
import useListings from '@hooks/contexts/useListings';
import useTableScroll from '@hooks/useTableScroll';
import useMediaQuery from '@hooks/useMediaQuery';


export interface ListingRowData {
  listingId: string;
  nameText: string;
  priceText: string;
  numBids: number;
  highestBid: bigint;
};

interface ListingTableProps {
  tldFilter: string | undefined;
};

type SortState = 'up' | 'down' | 'default';

export const ListingTable: React.FC<ListingTableProps> = ({
  tldFilter
}) => {
  const isMobile = useMediaQuery() === 'mobile';
  const { activeListings } = useListings();

  /*
   * State
   */

  const [listingRowData, setListingRowData] = useState<ListingRowData[]>([]);
  const [filteredListingRowData, setFilteredListingRowData] = useState<ListingRowData[]>([]);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  const [activeTldFilter, setActiveTldFilter] = useState<string | null>(null);

  const [bidsSortState, setBidsSortState] = useState<SortState>('default');
  const [priceSortState, setPriceSortState] = useState<SortState>('default');

  /*
   * Hooks
   */

  const { tableRef, isScrolling } = useTableScroll();
  
  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      setIsScrollEnabled(tableElement.scrollHeight > tableElement.clientHeight);
    }
  }, [filteredListingRowData, tableRef]);
  
  const applyFilters = useCallback((data: ListingRowData[]) => {
    let result = [...data];

    if (tldFilter || activeTldFilter) {
      const filterTld = tldFilter || activeTldFilter || '';
      result = result.filter(item => item.nameText.endsWith(filterTld));
    }

    if (searchTerm) {
      // Simple string matching as an alternative to Fuse.js
      result = result.filter(item => 
        item.nameText.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (bidsSortState !== 'default') {
      result.sort((a, b) => 
        bidsSortState === 'up' ? a.numBids - b.numBids : b.numBids - a.numBids
      );
    } else if (priceSortState !== 'default') {
      result.sort((a, b) => {
        const priceA = parseFloat(a.priceText.split(' ')[0]);
        const priceB = parseFloat(b.priceText.split(' ')[0]);
        return priceSortState === 'up' ? priceA - priceB : priceB - priceA;
      });
    }

    return result;
  }, [tldFilter, activeTldFilter, searchTerm, bidsSortState, priceSortState]);
  
  useEffect(() => {
    if (activeListings && activeListings.length > 0) {
      const listingRowsData: ListingRowData[] = activeListings.map((detailedListing: DetailedListing) => {
        const nameText = detailedListing.domain.name;
        
        let numBids = 0;
        let highestBid = BigInt(0);        
        if (detailedListing.bids && detailedListing.bids.length > 0) {
          numBids = detailedListing.bids.length;
          highestBid = detailedListing.bids.reduce((max, bid) => bid.price > max ? bid.price : max, BigInt(0));
        }
        const priceText = `${toEthStringWithDecimals(detailedListing.price, true, 4)} ETH`;

        return {
          listingId: detailedListing.listingId.toString(),
          nameText,
          priceText,
          numBids,
          highestBid,
        };
      });

      setListingRowData(listingRowsData);
    } else {
      setListingRowData([]);
    }
  }, [activeListings]);

  useEffect(() => {
    setFilteredListingRowData(applyFilters(listingRowData));
  }, [listingRowData, applyFilters, tldFilter, activeTldFilter, searchTerm, bidsSortState, priceSortState]);

  const handleTldFilter = (tld: string) => {
    setActiveTldFilter(prevTld => prevTld === tld ? null : tld);
  };

  const handleBidsSort = () => {
    setBidsSortState(prevState => {
      if (prevState === 'default') return 'up';
      if (prevState === 'up') return 'down';
      return 'default';
    });
    setPriceSortState('default');
  };

  const handlePriceSort = () => {
    setPriceSortState(prevState => {
      if (prevState === 'default') return 'up';
      if (prevState === 'up') return 'down';
      return 'default';
    });
    setBidsSortState('default');
  };

  /*
   * Component
   */

  return (
    <Container>
      <TitleRow>
        <ThemedText.HeadlineMedium>
          All Domains
        </ThemedText.HeadlineMedium>
      </TitleRow>

      <SearchAndFilterContainer>
        <SearchContainer>
          <SearchIcon size={16} />

          <SearchInput
            type="text"
            placeholder="Search domains..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchContainer>

        <FilterContainer>
          <FilterButton 
            active={activeTldFilter === '.xyz'} 
            onClick={() => handleTldFilter('.xyz')}
          >
            .xyz
          </FilterButton>

          <FilterButton 
            active={activeTldFilter === '.com'} 
            onClick={() => handleTldFilter('.com')}
          >
            .com
          </FilterButton>

          <FilterButton 
            active={activeTldFilter === '.ai'} 
            onClick={() => handleTldFilter('.ai')}
          >
            .ai
          </FilterButton>

          <SortButton
            active={bidsSortState !== 'default'}
            onClick={handleBidsSort}
          >
            Bids
            {bidsSortState === 'up' && <ChevronUp size={14} />}
            {bidsSortState === 'down' && <ChevronDown size={14} />}
          </SortButton>

          <SortButton
            active={priceSortState !== 'default'}
            onClick={handlePriceSort}
          >
            Price
            {priceSortState === 'up' && <ChevronUp size={14} />}
            {priceSortState === 'down' && <ChevronDown size={14} />}
          </SortButton>
        </FilterContainer>
      </SearchAndFilterContainer>

      <Content>
        {filteredListingRowData.length === 0 ? (
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
              <div>
                No listings found
              </div>
            </ThemedText.DeprecatedBody>
          </ErrorContainer>
        ) : (
          <>
            <TableHeaderRow isMobile={isMobile} isScrollEnabled={isScrollEnabled}>
              <ColumnHeader>#</ColumnHeader>

              <ColumnHeader>Domain</ColumnHeader>

              {!isMobile && <ColumnHeader>Active Bids</ColumnHeader>}

              <ColumnHeader>Ask Price</ColumnHeader>

              {!isMobile && <ColumnHeader>Highest Bid</ColumnHeader>}

              {!isMobile && <ColumnHeader>Actions</ColumnHeader>}
            </TableHeaderRow>

            <Table ref={tableRef}>
              {filteredListingRowData.map((listingRowData, rowIndex) => (
                <StyledListingRow key={rowIndex}>
                  <ListingRow
                    key={rowIndex}
                    listingId={listingRowData.listingId}
                    domainName={listingRowData.nameText}
                    numBids={listingRowData.numBids}
                    priceText={listingRowData.priceText}
                    highestBid={listingRowData.highestBid}
                    rowIndex={rowIndex}
                    isScrolling={isScrolling}
                  />
                </StyledListingRow>
              ))}
            </Table>
          </>
        )}
      </Content>
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: .5rem 1rem 1.75rem 1rem;
  height: 76px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  };
`;

const SearchAndFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.75rem 1rem 0.75rem;

  @media (max-width: 600px) {
    align-items: stretch;
    flex-direction: column;
    gap: 1rem;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
`;

const FilterButton = styled.button<{ active: boolean }>`
  padding: 0.5rem 1rem;
  border-radius: 24px;
  width: 64px;

  border: none;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.01); 
  background-color: ${props => props.active ? colors.buttonDefault : colors.white};
  color: ${props => props.active ? colors.white : colors.buttonDefault};
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.active ? colors.buttonDefault : colors.buttonHover};
    color: ${props => props.active ? colors.white : colors.white};
  }
`;

const SortButton = styled(FilterButton)`
  width: auto;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 1.5rem;
  
  background-color: ${colors.white};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.01); 
  border-radius: 24px;
  flex: 1;
`;

const SearchIcon = styled(Search)`
  color: ${colors.darkText};
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  font-size: 14px;
  color: ${colors.darkText};
  outline: none;
  padding: 0.35rem 0.25rem 0.25rem 0.25rem;

  &::placeholder {
    color: ${colors.darkText}80;
  }
`;

const Content = styled.div`
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;
  border-radius: 16px;

  @media (max-width: 600px) {
    margin: 0 1rem;
  }
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const TableHeaderRow = styled.div<{ isMobile: boolean, isScrollEnabled: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => isMobile ? '1fr 4fr 2fr' : '1fr 4fr 2fr 2fr 2fr 2fr'};
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};

  padding-right: ${({ isScrollEnabled }) => isScrollEnabled ? 'calc(1.5rem + 8px)' : '1.5rem'};
`;

const ColumnHeader = styled.div`
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const Table = styled.div`
  max-height: 60vh;

  @media (max-width: 600px) {
    max-height: 58vh;
  }

  font-size: 16px;
  color: #616161;
  overflow-y: auto;
  scrollbar-width: thin;
  
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.defaultBorderColor};
    border-radius: 4px;
  }

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledListingRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;