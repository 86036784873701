export const IncreaseBidTransactionStatus = {
  DEFAULT: 'default',
  PRICE_NOT_GREATER: 'price_not_greater',
  TRANSACTION_SIGNING: 'transaction_signing',
  TRANSACTION_MINING: 'transaction_mining',
  VALID: 'valid',
  TRANSACTION_SUCCEEDED: 'transaction_succeeded'
};

export type IncreaseBidTransactionStatusType = typeof IncreaseBidTransactionStatus[keyof typeof IncreaseBidTransactionStatus];
