import { useState } from 'react';
import { Groth16Proof } from '@helpers/types/smartContractProof';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import { getEmptyProof } from '@helpers/submitProof';
import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useFinalizeSaleTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapDomainExchangeAddress, swapDomainExchangeAbi } = useSmartContracts();

  /*
   * State
   */

  const [transferProofInput, setTransferProofInput] = useState<Groth16Proof>(getEmptyProof());
  // const [transferPublicSignalsInput, setTransferPublicSignalsInput] = useState<string>('');

  const [shouldConfigureFinalizeSaleWrite, setShouldConfigureFinalizeSaleWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  // * @param _proof         Proof of ticket transfer and acceptance
  const { config: writeFinalizeSaleConfig } = usePrepareContractWrite({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'finalizeSale',
    args: [
      transferProofInput,
    ],
    onError(error: any) {
      console.log('writeFinalizeSaleConfig failed: ', error.message);

      setShouldConfigureFinalizeSaleWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureFinalizeSaleWrite,
  });

  const {
    data: submitFinalizeSaleResult,
    status: signFinalizeSaleTransactionStatus,
    writeAsync: writeFinalizeSaleAsync,
  } = useContractWrite(writeFinalizeSaleConfig);

  const {
    isLoading: isMineFinalizeSaleTransactionMining,
    isSuccess: isMineFinalizeSaleTransactionSuccessful,
    status: mineFinalizeSaleTransactionStatus,
  } = useWaitForTransaction({
    hash: submitFinalizeSaleResult ? submitFinalizeSaleResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeFinalizeSaleAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeFinalizeSaleAsync,
    transferProofInput,
    // transferPublicSignalsInput,
    setTransferProofInput,
    // setTransferPublicSignalsInput,
    setShouldConfigureFinalizeSaleWrite,
    signFinalizeSaleTransactionStatus,
    mineFinalizeSaleTransactionStatus,
    isMineFinalizeSaleTransactionMining,
    isMineFinalizeSaleTransactionSuccessful,
    transactionHash: submitFinalizeSaleResult?.hash,
  };
};
