import React, { useEffect, useState } from 'react';
import { Inbox } from 'react-feather';
import styled, { css } from 'styled-components/macro';
import { Zap } from 'react-feather';
import Link from '@mui/material/Link';

import { RowBetween } from '@components/layouts/Row';
import { CustomConnectButton } from '@components/common/ConnectButton';
import { BidRow } from '@components/Bids/BidRow'; 
import { IncreaseBidModal } from '@components/Bids/IncreaseBidModal';
import QuestionHelper from '@components/common/QuestionHelper';
import { commonStrings } from '@helpers/strings';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { BidState } from '@helpers/types/bid';
import { toEthStringWithDecimals } from '@helpers/units';
import { formatEventDateTimeShort } from '@helpers/dateFormat';
import { MIN_BID_ACTIVE_PERIOD } from '@helpers/constants';
import { DetailedBid } from '@helpers/types/bid';
import useAccount from '@hooks/contexts/useAccount';
import useBuyers from '@hooks/contexts/useBuyers';
import { getBidState } from '@helpers/bidTableHelpers';
import { Button } from '@components/common/Button';
import { BID_STATE_DOCS_LINK } from '@helpers/docUrls';
import useTableScroll from '@hooks/useTableScroll';


export interface BidRowData {
  bidId: bigint;
  listingId: string;
  domainName: string;
  bidPrice: string;
  createdAt: string;
  bidState: BidState;
  canInitiateRefund: boolean;
}

export interface BidTableProps {
  tableTitle: string;
  emptyTableMessage: string;
  statusFilters: BidState[];
  showTopRightButton: boolean;
  handleTopRightButtonPressed: () => void;
}

export const BidTable: React.FC<BidTableProps> = ({
  tableTitle,
  emptyTableMessage,
  statusFilters,
  showTopRightButton,
  handleTopRightButtonPressed
}: BidTableProps) => {
  /*
   * Contexts
   */

  const { isLoggedIn } = useAccount();
  const { buyerDetailedBids } = useBuyers();
  
  /*
  * State
  */
 
 const [bidRowsData, setBidRowsData] = useState<BidRowData[]>([]);
 const [isScrollEnabled, setIsScrollEnabled] = useState(false);
 
 const [selectedBidToEdit, setSelectedBidToEdit] = useState<DetailedBid | null>(null);
 const [showIncreaseBidModal, setShowIncreaseBidModal] = useState(false);
 
 /*
 * Hooks
 */

  const { tableRef, isScrolling } = useTableScroll();

  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      setIsScrollEnabled(tableElement.scrollHeight > tableElement.clientHeight);
    }
  }, [bidRowsData, tableRef]);


  useEffect(() => {
    if (buyerDetailedBids && buyerDetailedBids.length > 0) {
      const bidRowsData: BidRowData[] = [];
      const currentTimestamp = BigInt(Math.floor(Date.now() / 1000));

      for (const bid of buyerDetailedBids) {
        const bidPriceEth = toEthStringWithDecimals(bid.price, true, 4);
        const bidPriceText = `${bidPriceEth} ETH`;

        const bidState = getBidState(bid, bid.listing);

        let canInitiateRefund = false;
        const isBidStateActive = bidState === BidState.Active;
        const isRefundWindowActive = bid.createdAt + MIN_BID_ACTIVE_PERIOD < currentTimestamp;
        if (isBidStateActive && isRefundWindowActive) {
          canInitiateRefund = true;
        }

        if (statusFilters.includes(bidState)) {
          bidRowsData.push({
            bidId: bid.bidId,
            listingId: bid.listing.listingId.toString(),
            domainName: bid.domain.name,
            bidPrice: bidPriceText,
            createdAt: formatEventDateTimeShort(bid.createdAt),
            bidState: bidState,
            canInitiateRefund: canInitiateRefund
          });
        }
      }

      setBidRowsData(bidRowsData);
    } else {
      setBidRowsData([]);
    }
  }, [buyerDetailedBids, statusFilters]);


  
  /*
   * Handlers
   */

  const handleEditBidClicked = (bidRowIndex: number) => {
    console.log('handleEditBidClicked: ', bidRowIndex);
    if (buyerDetailedBids) {
      const selectedBidId = bidRowsData[bidRowIndex].bidId;
      const bid = buyerDetailedBids.find(bid => bid.bidId === selectedBidId);

      if (bid) {
        setSelectedBidToEdit(bid);
    
        setShowIncreaseBidModal(true);
      } else {
        setShowIncreaseBidModal(false);

        setSelectedBidToEdit(null);
      }
    } else {
      setShowIncreaseBidModal(false);

      setSelectedBidToEdit(null);
    }
  };
  
  /*
   * Component
   */

  return (
    <Container>
      {showIncreaseBidModal && (
        (selectedBidToEdit) ? ( 
          <IncreaseBidModal
            bid={selectedBidToEdit}
            onBackClick={() => setShowIncreaseBidModal(false)}
          />
        ) : (
          null
        )
      )}

      <TitleRow>
        <ThemedText.HeadlineMedium>
          {tableTitle}
        </ThemedText.HeadlineMedium>
        {isLoggedIn ? (
          showTopRightButton && (
            <Button onClick={handleTopRightButtonPressed} height={40}>
              <>
                <Zap size={20} />
                Instant Accept
              </>
            </Button>
          )
        ) : null}
      </TitleRow>

      <Content>
        {!isLoggedIn ? (
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
              <div>
                Your bids will appear here.
              </div>
            </ThemedText.DeprecatedBody>
            <CustomConnectButton width={152} />
          </ErrorContainer>
        ) : bidRowsData.length === 0 ? (
            <ErrorContainer>
              <ThemedText.DeprecatedBody textAlign="center">
                <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                <div>
                  {emptyTableMessage}
                </div>
              </ThemedText.DeprecatedBody>
            </ErrorContainer>
        ) : (
          <>
            <TableHeaderRow isScrollEnabled={isScrollEnabled}>
              <ColumnHeader>
                <ColumnHeaderText>#</ColumnHeaderText>
              </ColumnHeader>

              <StateColumnHeader>
                <ColumnHeaderText>State</ColumnHeaderText>
                <QuestionHelper
                  text={
                    <>
                      {commonStrings.get('BID_STATE_TOOLTIP')}
                      {' '}
                      <Link
                        href={BID_STATE_DOCS_LINK}
                        target='_blank'
                        style={{ color: 'inherit' }}
                      >
                        Read more ↗
                      </Link>
                    </>
                  }
                  size='sm'
                />
              </StateColumnHeader>

              <ColumnHeader>
                <ColumnHeaderText>Domain</ColumnHeaderText>
              </ColumnHeader>

              <ColumnHeader>
                <ColumnHeaderText>Bid Price</ColumnHeaderText>
              </ColumnHeader>

              <ColumnHeader>
                <ColumnHeaderText>Placed At</ColumnHeaderText>
              </ColumnHeader>              

              <ColumnHeader>
                <ColumnHeaderText>Actions</ColumnHeaderText>
              </ColumnHeader>
            </TableHeaderRow>

            <Table ref={tableRef}>
              {bidRowsData.map((bidRowData, rowIndex) => (
                <StyledBidRow key={rowIndex}>
                  <BidRow
                    listingId={bidRowData.listingId}
                    bidId={bidRowData.bidId.toString()}
                    domainName={bidRowData.domainName}
                    bidPrice={bidRowData.bidPrice}
                    createdAt={bidRowData.createdAt}
                    bidState={bidRowData.bidState}
                    canInitiateRefund={bidRowData.canInitiateRefund}
                    rowIndex={rowIndex}
                    onEditBid={() => handleEditBidClicked(rowIndex)}
                    isScrolling={isScrolling}
                  />
                </StyledBidRow>
              ))}
            </Table>
          </>
        )}
      </Content>
    </Container>
  )
};

const Container = styled.div`
  gap: 1rem;
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: 1.25rem 1rem 1.75rem 1rem;
  height: 76px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  };
`;

const Content = styled.div`
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    border-radius: 16px;
  }
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const TableHeaderRow = styled.div<{ isScrollEnabled: boolean }>`
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 2fr 3fr 1.5fr;
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  text-align: left;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
  align-items: flex-end;
  padding-right: ${({ isScrollEnabled }) => isScrollEnabled ? 'calc(1.5rem + 8px)' : '1.5rem'};
`;

const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const ColumnHeaderText = styled.div`
  font-size: 14px;
`;

const StateColumnHeader = styled(ColumnHeader)`
  gap: 4px;
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;
  max-height: 28vh;
  overflow-y: auto;
  scrollbar-width: thin;
  
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.defaultBorderColor};
    border-radius: 4px;
  }

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledBidRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;
