import React, { useEffect, forwardRef } from 'react';
import { ArrowDownCircle, ArrowUpCircle, Repeat, LogOut, Key, UserCheck, Zap } from 'react-feather';
import styled from 'styled-components';
import Link from '@mui/material/Link';
import { ENSName } from 'react-ens-name';

import { Overlay } from '@components/modals/Overlay';
import { EthereumAvatar } from '@components/Account/Avatar';
import { CopyButton } from '@components/common/CopyButton';
import useAccount from '@hooks/contexts/useAccount';
import useBalances from '@hooks/contexts/useBalance';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useModal from '@hooks/useModal';
import useQuery from '@hooks/useQuery';
import { formatAddress } from '@helpers/addressFormat';
import { CALLER_ACCOUNT } from '@helpers/constants';
import { toEthStringWithDecimals } from '@helpers/units';
import { colors } from '@theme/colors';
import { LoginStatus } from '@helpers/types';
import { Z_INDEX } from '@theme/zIndex';
import { MODALS } from '@helpers/types';
import { alchemyMainnetEthersProvider } from "index";

import ethSvg from '@assets/svg/ethereum-token-logo.svg'


interface AccountDropdownProps {
  onOptionSelect: () => void;
}

export const AccountDropdown = forwardRef<HTMLDivElement, AccountDropdownProps>(({ onOptionSelect }, ref) => {
  const { navigateWithQuery } = useQuery();

  /*
   * Contexts
   */

  const {
    ethBalance,
    refetchEthBalance,
    shouldFetchEthBalance
  } = useBalances();
  const {
    accountDisplay,
    authenticatedLogout,
    loggedInEthereumAddress,
    isLoggedIn,
    loginStatus,
    exportAuthenticatedWallet
  } = useAccount();
  const { blockscanUrl } = useSmartContracts();
  const { openModal } = useModal();

  /*
   * Handler
   */

  const handleReceiveClick = () => {
    openModal(MODALS.RECEIVE);

    onOptionSelect();
  };

  const handleSendClick = () => {
    navigateWithQuery('/send');

    onOptionSelect();
  };

  const handleLogout = async () => {
    try {
      await authenticatedLogout?.();
    } catch (error) {
      console.error("Failed to logout");
    }

    onOptionSelect();
  };

  const handleExportWallet = () => {
    exportAuthenticatedWallet?.();

    onOptionSelect();
  };

  /*
   * Hooks
   */

  useEffect(() => {
    if(shouldFetchEthBalance) {
      refetchEthBalance?.();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Helpers
   */

  const userEtherscanLink = `${blockscanUrl}/address/${loggedInEthereumAddress}`;

  const ethBalanceDisplay = ethBalance ? `${toEthStringWithDecimals(ethBalance, true, 4)} ETH` : '0 ETH';

  /*
   * Component
   */

  return (
    <Wrapper>
      <Overlay />

      <NavDropdown ref={ref}>
        <AccountAndUserIconContainer>
          <EthereumAvatar address={loggedInEthereumAddress ?? CALLER_ACCOUNT} size={42} />
          <AccountTypeLabel>
            {isLoggedIn && loginStatus === LoginStatus.AUTHENTICATED ? accountDisplay : 'Connected'}
          </AccountTypeLabel>
          <AccountAddressAndENSContainer>
            <AccountAddress>
              <Link href={userEtherscanLink} target="_blank">
                <ENSName
                  provider={alchemyMainnetEthersProvider}
                  address={loggedInEthereumAddress || ''}
                  customDisplay={(address) => formatAddress(address)}
                />
              </Link>
            </AccountAddress>
            <CopyButton textToCopy={loggedInEthereumAddress || ''} size={'sm'}/>
          </AccountAddressAndENSContainer>
        </AccountAndUserIconContainer>

        <BalancesAndLogoContainer>
          <TokenSvg src={ethSvg} />

          <BalanceContainer>
            <BalanceLabel>
              {`ETH`}
            </BalanceLabel>

            <BalanceValue>
              {ethBalance ? ethBalanceDisplay : "0"}
            </BalanceValue>
          </BalanceContainer>
        </BalancesAndLogoContainer>
        
        <NavDropdownItemsContainer>
          <ItemAndIconContainer onClick={handleReceiveClick}>
            <StyledArrowDownCircle />

            <NavDropdownItem>
              Fund Account
            </NavDropdownItem>
          </ItemAndIconContainer>
          
          <ItemAndIconContainer onClick={handleSendClick}>
            <StyledArrowUpCircle />

            <NavDropdownItem>
              Send
            </NavDropdownItem>
          </ItemAndIconContainer>

          {loginStatus === LoginStatus.EOA && (
            <ItemAndIconContainer onClick={() => window.open("https://bridge.base.org/deposit", "_blank")}>
              <StyledRepeat />
              <BridgeLinkAndBalance>
                <BridgeLink>
                  Bridge ↗
                </BridgeLink>

                <EthBalance>
                  {ethBalanceDisplay}
                </EthBalance>
              </BridgeLinkAndBalance>
            </ItemAndIconContainer>
          )}

          {loginStatus === LoginStatus.AUTHENTICATED && (
            <ItemAndIconContainer onClick={handleExportWallet}>
              <StyledKey />

              <NavDropdownItem>
                Export Wallet
              </NavDropdownItem>
            </ItemAndIconContainer>
          )}

          <ItemAndIconContainer onClick={handleLogout}>
            <StyledLogOut />
            <LogoutContainer>
              Logout
            </LogoutContainer>
          </ItemAndIconContainer>
        </NavDropdownItemsContainer>
      </NavDropdown>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
`;

const NavDropdown = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #C0C0C0;
  background: #FAF9F6;
  position: absolute;
  width: 272px;

  top: calc(100% + 20px);
  right: 0;

  z-index: ${Z_INDEX.account_dropdown};
  color: #333;
`;

const AccountAndUserIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  white-space: nowrap;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid ${colors.defaultBorderColor};
`;

const AccountTypeLabel = styled.div`
  font-weight: 700;
`;

const AccountAddressAndENSContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const AccountAddress = styled.div`
`;

const StyledUserCheck = styled(UserCheck)`
  color: ${colors.iconDefault};
  height: 20px;
  width: 20px;
`;

const StyledArrowUpCircle = styled(ArrowUpCircle)`
  color: ${colors.iconDefault};
  height: 20px;
  width: 20px;
`;

const StyledArrowDownCircle = styled(ArrowDownCircle)`
  color: ${colors.iconDefault};
  height: 20px;
  width: 20px;
`;

const StyledZap = styled(Zap)`
  color: ${colors.iconDefault};
  height: 18px;
  width: 18px;
`;

const StyledRepeat = styled(Repeat)`
  color: ${colors.iconDefault};
  height: 18px;
  width: 18px;
`;

const StyledKey = styled(Key)`
  color: ${colors.iconDefault};
  height: 18px;
  width: 18px;
`;

const StyledLogOut = styled(LogOut)`
  color: ${colors.logoutButtonDefaultRed};
  height: 20px;
  width: 20px;
`;

const BalancesAndLogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 24px;
  gap: 20px;

  font-family: 'Graphik';
  border-bottom: 1px solid ${colors.defaultBorderColor};
`;

const TokenSvg = styled.img`
  width: 32px;
  height: 32px;
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  justify-content: flex-start;
`;

const BalanceValue = styled.div`
  color: ${colors.labelValue};
  font-size: 24px;
`;

const BalanceLabel = styled.div`
  color: ${colors.labelName};
  font-size: 16px;
`;

const NavDropdownItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  padding: 8px 0px;
  line-height: 1;
`;

const LogoutContainer = styled.div`
  cursor: pointer;
  text-decoration: none;
  color: ${colors.logoutButtonDefaultRed};
  padding-top: 2px;
`;

const ItemAndIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: flex-start;
  padding: 16px 24px;

  &:hover {
    color: ${colors.dropdownItemHover};
    box-shadow: none;

    ${StyledKey},
    ${StyledUserCheck},
    ${StyledArrowUpCircle},
    ${StyledArrowDownCircle},
    ${StyledRepeat},
    ${StyledZap} {
      color: ${colors.dropdownItemHover};;
    }

    ${StyledLogOut} {
      color: ${colors.dropdownLogoutHover};
    }

    ${LogoutContainer} {
      color: ${colors.dropdownLogoutHover};
    }
  }
`;

const NavDropdownItem = styled.div`
  color: inherit;
  text-decoration: none;
  padding-top: 2px;
`;

const BridgeLinkAndBalance = styled.div`
  display: flex;  
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-grow: 1;
`;

const BridgeLink = styled.a`
  color: inherit;
  text-decoration: none;
  background-color: #FAF9F6;
`;

const EthBalance = styled.a`
  color: ${colors.labelName};
  font-size: 16px;
  font-weight: 500;
  padding-top: 2.5px;
`;
