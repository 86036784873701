import { createContext } from 'react';

import { DetailedBid } from '@helpers/types';


interface BuyersValues {
  buyerDetailedBids: DetailedBid[] | null;
  refetchBuyerBids: (() => void) | null;
  shouldFetchBuyerBids: boolean;

  buyerInstantAcceptEnabled: boolean;
  refetchBuyerInstantAcceptEnabled: (() => void) | null;
  shouldFetchBuyerInstantAcceptEnabled: boolean;
};

const defaultValues: BuyersValues = {
  buyerDetailedBids: null,
  refetchBuyerBids: null,
  shouldFetchBuyerBids: false,

  buyerInstantAcceptEnabled: false,
  refetchBuyerInstantAcceptEnabled: null,
  shouldFetchBuyerInstantAcceptEnabled: false,
};

const BuyersContext = createContext<BuyersValues>(defaultValues);

export default BuyersContext;
