import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useDisableInstantAcceptTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapDomainExchangeAddress, swapDomainExchangeAbi } = useSmartContracts();

  /*
   * State
   */
  const [shouldConfigureDisableInstantAcceptWrite, setShouldConfigureDisableInstantAcceptWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  //function disableInstantAccept()

  const { config: writeDisableInstantAcceptConfig } = usePrepareContractWrite({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'disableInstantAccept',
    onError(error: any) {
      console.log('writeDisableInstantAcceptConfig failed: ', error.message);

      setShouldConfigureDisableInstantAcceptWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureDisableInstantAcceptWrite,
  });

  const {
    data: submitDisableInstantAcceptResult,
    status: signDisableInstantAcceptTxStatus,
    writeAsync: writeDisableInstantAcceptAsync,
  } = useContractWrite(writeDisableInstantAcceptConfig);

  const {
    status: mineDisableInstantAcceptTxStatus,
  } = useWaitForTransaction({
    hash: submitDisableInstantAcceptResult ? submitDisableInstantAcceptResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeDisableInstantAcceptAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeDisableInstantAcceptAsync,
    setShouldConfigureDisableInstantAcceptWrite,
    shouldConfigureDisableInstantAcceptWrite,
    signDisableInstantAcceptTxStatus,
    mineDisableInstantAcceptTxStatus,
    transactionHash: submitDisableInstantAcceptResult?.hash,
  };
};
