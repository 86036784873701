import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ArrowLeft } from 'react-feather';
import Link from '@mui/material/Link';

import { Input } from '@components/Listing/BidModal/Input';
import { Button } from '@components/common/Button';
import { Overlay } from '@components/modals/Overlay';
import { encryptMessage } from '@helpers/messagEncryption';
import { CustomConnectButton } from '@components/common/ConnectButton';
import { BidInstructions } from '@components/Listing/BidModal/BidInstructions';
import {
  DetailedListing,
  CreateBidTransactionStatus,
  CreateBidTransactionStatusType,
  LoginStatus,
  MODALS
} from '@helpers/types';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { commonStrings } from '@helpers/strings';
import { initializePoseidon, calculateNamecheapBuyerIdHash } from '@helpers/poseidonHash';
import { Z_INDEX } from '@theme/zIndex';
import { toBigIntEth, toEthString, toEthStringWithDecimals } from '@helpers/units';
import useAccount from '@hooks/contexts/useAccount';
import useBalances from '@hooks/contexts/useBalance';
import useBuyers from '@hooks/contexts/useBuyers';
import useCreateBidTransaction from '@hooks/transactions/useCreateBid';
import useModal from '@hooks/useModal';
import useQuery from '@hooks/useQuery';
import useListings from '@hooks/contexts/useListings';
import { BUYING_DOMAINS_DOCS_LINK } from '@helpers/docUrls';


interface BuyModalProps {
  listing: DetailedListing
  onBackClick: () => void
}

export const BuyModal: React.FC<BuyModalProps> = ({
  listing,
  onBackClick,
}) => {
  const { navigateWithQuery } = useQuery();
  
  /*
  * Contexts
  */
 
  const { ethBalance, refetchEthBalance } = useBalances();
  const { refetchBuyerBids } = useBuyers();
  const { refetchActiveListingBids } = useListings();
  const { isLoggedIn, loginStatus } = useAccount();
  const { openModal } = useModal();

  /*
   * State
   */

  const [createBidStatus, setCreateBidStatus] = useState<CreateBidTransactionStatusType>(CreateBidTransactionStatus.DEFAULT);

  const [rawUsernameInput, setRawUsernameInput] = useState<string>('');

  /*
   * Hooks
   */

  useEffect(() => {
    refetchEthBalance?.();
    
    initializePoseidon();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBuyListingSuccessCallback = useCallback((data: any) => {
    console.log('createBid Succeeded: ', data);

    refetchEthBalance?.();
    refetchBuyerBids?.();

    // empty the input fields
    setRawUsernameInput('');
    setMaxPriceInput('');

    // refetch active listings
    refetchActiveListingBids?.();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchEthBalance, refetchBuyerBids, refetchActiveListingBids]);

  const {
    writeCreateBidAsync,
    maxPriceInput,
    listingIdInput,
    buyerIdHashInput,
    setListingIdInput,
    setMaxPriceInput,
    setBuyerIdHashInput,
    setEncryptedBuyerIdInput,
    setShouldConfigureCreateBidWrite,
    signCreateBidTransactionStatus,
    mineCreateBidTransactionStatus,
    // transactionHash,
  } = useCreateBidTransaction(onBuyListingSuccessCallback);

  useEffect(() => {
    if (!listing) {
      return;
    };

    setListingIdInput(listing.listingId);
  }, [listing, setListingIdInput, setMaxPriceInput]);

  useEffect(() => {
    const updateNewListingState = async () => {
      const successfulCreateListingTransaction = mineCreateBidTransactionStatus === 'success';

      if (successfulCreateListingTransaction) {
        setCreateBidStatus(CreateBidTransactionStatus.TRANSACTION_SUCCEEDED);
      } else {
        if (isLoggedIn) {
          const ethBalanceLoaded = ethBalance !== null;
          const validPriceInput = maxPriceInput !== '' && maxPriceInput !== '.' && maxPriceInput !== '0.';

          if (validPriceInput && ethBalanceLoaded) {
            const requiredEthBI = toBigIntEth(maxPriceInput);
            const isPriceGreaterThanBalance = requiredEthBI > ethBalance;
            const isPriceLessThanMinBid = requiredEthBI <= listing.minBidPrice;

            if (isPriceGreaterThanBalance) {
              setCreateBidStatus(CreateBidTransactionStatus.INSUFFICIENT_BALANCE);
            } else if (isPriceLessThanMinBid) {
              setCreateBidStatus(CreateBidTransactionStatus.PRICE_LESS_THAN_MIN_BID);
            } else {
              const signingCreateBidTransaction = signCreateBidTransactionStatus === 'loading';
              const miningCreateBidTransaction = mineCreateBidTransactionStatus === 'loading';
    
              if (signingCreateBidTransaction) {
                setCreateBidStatus(CreateBidTransactionStatus.TRANSACTION_SIGNING);
              } else if (miningCreateBidTransaction){
                setCreateBidStatus(CreateBidTransactionStatus.TRANSACTION_MINING);
              } else {
                if (buyerIdHashInput) {
                  setCreateBidStatus(CreateBidTransactionStatus.VALID);
                } else {
                  setCreateBidStatus(CreateBidTransactionStatus.MISSING_RECIPIENT);
                }
              }
            }
          } else {
            setCreateBidStatus(CreateBidTransactionStatus.MISSING_PRICE);
          }
        } else {
          setCreateBidStatus(CreateBidTransactionStatus.NOT_LOGGED_IN);
        }
      }
    }

    updateNewListingState();
  }, [
      isLoggedIn,
      maxPriceInput,
      listingIdInput,
      listing,
      buyerIdHashInput,
      ethBalance,
      signCreateBidTransactionStatus,
      mineCreateBidTransactionStatus,
    ]
  );

  useEffect(() => {
    setShouldConfigureCreateBidWrite(createBidStatus === CreateBidTransactionStatus.VALID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBidStatus]);

  /*
   * Handlers
   */

  const handleOverlayClick = () => {
    onBackClick();
  };

  const ctaOnClick = async () => {
    switch (createBidStatus) {
      case CreateBidTransactionStatus.VALID:
        try {
          await writeCreateBidAsync?.();
        } catch (error) {
          console.log('writeCreateBidAsync failed: ', error);
        }
        break;

      case CreateBidTransactionStatus.TRANSACTION_SUCCEEDED:
        navigateWithQuery('/bids');
        break;

      case CreateBidTransactionStatus.INSUFFICIENT_BALANCE:
        openModal(MODALS.RECEIVE);
        break;

      default:
        break;
    }
  };

  const validateUsername = (username: string): boolean => {
    // Namecheap username validation rules:
    // 1. Must be between 3 and 20 characters long
    // 2. Can only contain letters, numbers, and hyphens
    // 3. Cannot start or end with a hyphen
    const usernameRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,18}[a-zA-Z0-9]$/;
    return usernameRegex.test(username);
  };

  const handleUsernameInputChange = async (rawUsernameInput: string) => {
    setRawUsernameInput(rawUsernameInput);

    const isUsernameValid = validateUsername(rawUsernameInput);

    if (isUsernameValid) {
      const hashedUsername = await calculateNamecheapBuyerIdHash(rawUsernameInput);
      setBuyerIdHashInput(hashedUsername);

      const encryptionKey = listing.encryptionKey
      const encryptedUsername = await encryptMessage(rawUsernameInput, encryptionKey);
      setEncryptedBuyerIdInput(encryptedUsername);
    } else {
      setBuyerIdHashInput('');
      setEncryptedBuyerIdInput('');
    }
  };

  function isValidInput(value: string) {
    const isValid = /^-?\d*(\.\d{0,18})?$/.test(value);
    
    return parseFloat(value) >= 0 && isValid;
  }

  const handleBidAmountChange = (rawBidAmount: string) => {
    if (rawBidAmount === "") {
      setMaxPriceInput('');
    } else if (rawBidAmount === ".") {
      setMaxPriceInput('0.');
    } else if (isValidInput(rawBidAmount)) {
      setMaxPriceInput(rawBidAmount);
    }
  };

  /*
   * Helpers
   */

  const ethBalanceLabel = useMemo(() => {
    if (ethBalance !== null) {
      const formattedEthBalanceText = toEthString(ethBalance, true, 4);
      
      return `Balance: ${formattedEthBalanceText} ETH`
    } else {
      return '';
    }
  }, [ethBalance]);

  const minBidLabel = useMemo(() => {
    if (listing.minBidPrice !== null) {
      const formattedMinBidText = toEthStringWithDecimals(listing.minBidPrice, true, 4);
      
      return `${formattedMinBidText} ETH`
    } else {
      return '';
    }
  }, [listing.minBidPrice]);

  const ctaDisabled = (): boolean => {
    switch (createBidStatus) {
      case CreateBidTransactionStatus.DEFAULT:
      case CreateBidTransactionStatus.MISSING_PRICE:
      case CreateBidTransactionStatus.TRANSACTION_SIGNING:
      case CreateBidTransactionStatus.TRANSACTION_MINING:
      case CreateBidTransactionStatus.PRICE_LESS_THAN_MIN_BID:
      case CreateBidTransactionStatus.MISSING_RECIPIENT:
        return true;

      case CreateBidTransactionStatus.VALID:
      case CreateBidTransactionStatus.INSUFFICIENT_BALANCE:
      case CreateBidTransactionStatus.NOT_LOGGED_IN:
      default:
        return false;
    }
  }

  const ctaLoading = (): boolean => {
    switch (createBidStatus) {
      case CreateBidTransactionStatus.TRANSACTION_SIGNING:
      case CreateBidTransactionStatus.TRANSACTION_MINING:
        return loginStatus === LoginStatus.AUTHENTICATED;

      default:
        return false;
    }
  };

  const ctaText = (): string => {
    switch (createBidStatus) {
      case CreateBidTransactionStatus.MISSING_PRICE:
        return 'Input bid price';

      case CreateBidTransactionStatus.MISSING_RECIPIENT:
        return 'Input valid namecheap username';
      
      case CreateBidTransactionStatus.INSUFFICIENT_BALANCE:
        return `Insufficient balance — Deposit ETH`;

      case CreateBidTransactionStatus.TRANSACTION_SIGNING:
        return 'Signing Transaction';

      case CreateBidTransactionStatus.PRICE_LESS_THAN_MIN_BID:
        return `Bid must be greater than ${minBidLabel}`;

      case CreateBidTransactionStatus.TRANSACTION_MINING:
        return 'Mining Transaction';

      case CreateBidTransactionStatus.VALID:
        return 'Create Bid';

      case CreateBidTransactionStatus.TRANSACTION_SUCCEEDED:
        return 'Go to Bids';

      case CreateBidTransactionStatus.NOT_LOGGED_IN:
        return 'Log In';

      case CreateBidTransactionStatus.DEFAULT:
      default:
        return 'Select domains to buy';
    }
  }

  /*
   * Component
   */

  return (
    <ModalAndOverlayContainer>
      <Overlay onClick={handleOverlayClick}/>

      <ModalContainer>
        <RowBetween>
          <div style={{ flex: 0.25 }}>
            <button
              onClick={handleOverlayClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Place Bid
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.25 }}/>
        </RowBetween>

        <InstructionsContainer>
          { commonStrings.get('BUY_ORDER_INSTRUCTIONS') }
          <Link
            href={BUYING_DOMAINS_DOCS_LINK}
            target="_blank"
          >
            Buying Domains Guide ↗
          </Link>
        </InstructionsContainer>

        <BidInstructions />

        <BodyContainer>
          <Input
            label="Namecheap Username"
            name={`usernameInput`}
            value={rawUsernameInput}
            onChange={(e) => handleUsernameInputChange(e.currentTarget.value)}
            type="string"
            placeholder="vitalik"
            helperText={commonStrings.get('BUY_ORDER_RECIPIENT_USERNAME_TOOLTIP')}
          />

          <Input
            label="Bid Amount"
            name={`bidAmount`}
            value={maxPriceInput}
            onChange={(e) => handleBidAmountChange(e.currentTarget.value)}
            type="number"
            inputLabel="ETH"
            placeholder="0.001"
            accessoryLabel={ethBalanceLabel}
          />

          {createBidStatus === CreateBidTransactionStatus.NOT_LOGGED_IN ? (
            <CustomConnectButton
              fullWidth={true}
              height={48}
            />
          ) : (
            <Button
              disabled={ctaDisabled()}
              loading={ctaLoading()}
              onClick={async () => {
                ctaOnClick();
              }}
              fullWidth={true}
            >
              { ctaText() }
            </Button>
          )}
        </BodyContainer>
      </ModalContainer>
    </ModalAndOverlayContainer>
  );
};

const ModalAndOverlayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.overlay};
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${colors.black};
`;

const ModalContainer = styled.div`
  max-height: 80vh;
  width: 80vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.5rem 1.5rem;
  background-color: ${colors.container};
  color: ${colors.black};
  z-index: ${Z_INDEX.buy_modal};
  gap: 1rem;
  overflow-y: auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

const InstructionsContainer = styled.div`
  padding: 0rem 0.75rem;
  color: ${colors.darkText};
  font-size: 15px;
`;
