import React, { useEffect, ReactNode } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate
} from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import useMediaQuery from '@hooks/useMediaQuery';

import { TopNav } from '@components/layouts/TopNav';
import { BottomNav } from '@components/layouts/BottomNav';
import { EnvironmentBanner } from '@components/layouts/EnvironmentBanner';

import { HowItWorks } from './pages/HowItWorks';
import { ListingsPage } from './pages/Listings';
import { ListingPage } from './pages/Listing';
import { SellerListingsPage } from './pages/SellerListings';
import { Browse } from './pages/Browse';
import { Bids } from './pages/Bids';
import { Send } from './pages/Send';
import { Privacy } from './pages/Privacy';
import { Tos } from './pages/Tos';
import Modals from './pages/Modals';

// Contexts
import AccountProvider from './contexts/Account/AccountProvider';
import BalancesProvider from './contexts/Balances/BalancesProvider';
import SendSettingsProvider from './contexts/SendSettings/SendSettingsProvider';
import SmartContractsProvider from './contexts/SmartContracts/SmartContractsProvider';
import ExtensionNotarizationsProvider from './contexts/ExtensionProxyProofs/ExtensionProxyProofsProvider';
import { ModalSettingsProvider } from 'contexts/ModalSettings';

import DomainsProvider from './contexts/Domains/DomainsProvider';
import ListingsProvider from './contexts/Listings/ListingsProvider';
import SellersProvider from './contexts/Sellers/SellersProvider';
import BuyersProvider from './contexts/Buyers/BuyersProvider';

// Email proving contexts
import { ProofGenSettingsProvider } from './contexts/ProofGenSettings'
import { GoogleAuthProvider } from './contexts/GoogleAuth'

import './App.css';
import './styles.css';


const App = () => {
  /*
   * Context
   */

  const currentDeviceSize = useMediaQuery();
  
  const HomeRoute = () => {
    const currentDeviceSize = useMediaQuery();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (!currentDeviceSize) return;
  
      const isMobileOrTablet = currentDeviceSize === 'mobile' || currentDeviceSize === 'tablet';
  
      if (isMobileOrTablet) {
        navigate('/how-it-works', { replace: true });
      } else {
        navigate('/browse', { replace: true });
      }
    }, [currentDeviceSize, navigate]);
  
    return null;
  };


  /*
   * Component
   */

  return (
    <Router>
      <Providers>
        <div className="app-container">
          <EnvironmentBanner />
          <TopNav />
          <div className="app-content">
            <Routes>
              <Route path="/" element={<HomeRoute />} />
              <Route path="/browse" element={<Browse />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/listing/:listingId" element={<ListingPage />} />
              <Route path="/listings/:tldFilter?" element={<ListingsPage />} />
              <Route path="/bids" element={<Bids />} />
              <Route path="/my-listings" element={<SellerListingsPage />} />
              <Route path="/send" element={<Send />} />
              <Route path="/pp" element={<Privacy />} />
              <Route path="/tos" element={<Tos />} />
              <Route element={<>Not found</>} />
            </Routes>
          </div>
          <Modals />
          {(currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile') && <BottomNav />}
        </div>
      </Providers>
    </Router>
  );
};

type ProvidersType = [React.ElementType, Record<string, unknown>];
type ChildrenType = {
  children: Array<React.ElementType>;
};

export const buildProvidersTree = (
  componentsWithProps: Array<ProvidersType>,
) => {
  const initialComponent = ({children}: ChildrenType) => <>{children}</>;
  return componentsWithProps.reduce(
    (
      AccumulatedComponents: React.ElementType,
      [Provider, props = {}]: ProvidersType,
    ) => {
      return ({children}: ChildrenType) => {
        return (
          <AccumulatedComponents>
            <Provider {...props}>{children}</Provider>
          </AccumulatedComponents>
        );
      };
    },
    initialComponent,
  );
};

const providersWithProps: ProvidersType[] = [
  [AccountProvider, {}],
  [SmartContractsProvider, {}],
  
  [SendSettingsProvider, {}],
  
  [BalancesProvider, {}],
  [DomainsProvider, {}],
  [ListingsProvider, {}],
  [SellersProvider, {}],
  [BuyersProvider, {}],
  
  [ExtensionNotarizationsProvider, {}],
  
  [ProofGenSettingsProvider, {}],
  [ModalSettingsProvider, {}],
  [GoogleOAuthProvider, { clientId: process.env.GOOGLE_CLIENT_ID || "" }],
  [GoogleAuthProvider, {}],
];

const ProviderTree = buildProvidersTree(providersWithProps);

interface ProvidersProps {
  children: ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return <ProviderTree>{children}</ProviderTree>;
}

export default App;