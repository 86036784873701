import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { Check, CreditCard, DollarSign, List, Package, Rewind, Send, ShoppingBag } from 'react-feather';

import { SVGIconThemed } from '@components/common/SVGIconThemed';
import { Z_INDEX } from '@theme/zIndex';
import useMediaQuery from '@hooks/useMediaQuery';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { CHROME_EXTENSION_URL } from '@helpers/constants';


export const HowItWorks: React.FC = () => {
  /*
   * Contexts
   */
  
  const currentDeviceSize = useMediaQuery();

  const [sectionRefs, setSectionRefs] = useState<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  useEffect(() => {
    const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {
      'Funding Account': React.createRef<HTMLDivElement>(),
      'Buying Domains': React.createRef<HTMLDivElement>(),
      'Accepting Domains': React.createRef<HTMLDivElement>(),
      'Initiating Refund': React.createRef<HTMLDivElement>(),
      'Verifying Domains': React.createRef<HTMLDivElement>(),
      'Creating Listing': React.createRef<HTMLDivElement>(),
      'Transferring Domains': React.createRef<HTMLDivElement>(),
      'Claim Payment': React.createRef<HTMLDivElement>(),
    };
    setSectionRefs(refs); 
  }, []);

  const scrollToSection = (sectionTitle: string) => {
    sectionRefs[sectionTitle]?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  /*
   * Handlers
   */

  const jumpToMedia = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <PageWrapper $isMobile={currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile'}>
      <Container>
        <HeroContainer style={{ padding: currentDeviceSize === 'mobile' ? '0 1.6rem' : '0', width: '100%' }}>
          <HeroTextContainer>
            <ThemedText.Hero>
              <div style={{ display: 'flex', justifyContent: 'center', fontSize: currentDeviceSize === 'mobile' ? 44 : 60, fontWeight: 600 }}>
                <span>How ZKP2P <span style={{ color: colors.buttonDefault, fontWeight: 'bold' }}>Domains</span> Works</span>
              </div>
            </ThemedText.Hero>
          </HeroTextContainer>

          <SubHeaderContainer>
            <ThemedText.SubHeaderLarge style={{ textAlign: 'center', lineHeight: '1.3', fontSize: currentDeviceSize === 'mobile' ? 20 : 24 }}>
              Buy and sell aftermarket domains with lower fees, guaranteed authenticity, and fast settlement.
            </ThemedText.SubHeaderLarge>
          </SubHeaderContainer>

          <BoxesContainer>
            <Box>
              <BoxHeader style={{ textAlign: 'center' }}>For Buyers</BoxHeader>
              <UnorderedList>
                <ListItem onClick={() => scrollToSection('Funding Account')}><CreditCard />Funding Account</ListItem>
                <ListItem onClick={() => scrollToSection('Buying Domains')}><ShoppingBag />Buying Domains</ListItem>
                <ListItem onClick={() => scrollToSection('Accepting Domains')}><Package />Accepting Domains</ListItem>
                <ListItem onClick={() => scrollToSection('Initiating Refund')}><Rewind />Initiating Refund</ListItem>
              </UnorderedList>
            </Box>
            <Box>
              <BoxHeader style={{ textAlign: 'center' }}>For Sellers</BoxHeader>
              <UnorderedList>
                <ListItem onClick={() => scrollToSection('Verifying Domains')}><Check />Verifying Domains</ListItem>
                <ListItem onClick={() => scrollToSection('Creating Listing')}><List />Creating Listing</ListItem>
                <ListItem onClick={() => scrollToSection('Transferring Domains')}><Send />Transferring Domains</ListItem>
                <ListItem onClick={() => scrollToSection('Claim Payment')}><DollarSign />Claim Payment</ListItem>
              </UnorderedList>
            </Box>
          </BoxesContainer>
        </HeroContainer>

        <LearnMoreContainer
            onClick={() => scrollToSection('Funding Account')}
          >
            Learn more
            <Icon icon="arrow-down" />
        </LearnMoreContainer>


        <HowToSectionsContainer>
          <HowToSection ref={sectionRefs['Funding Account']}>
            <DialogBox $isLeft={true}>
              <IconWrapper>
                <CreditCard size={32} />
              </IconWrapper>

              <BoxHeader>Funding Account</BoxHeader>

              <HowToText>In order to purchase domains, you must fund your account. Our domain exchange uses ETH on Base. We provide three ways to fund your account</HowToText>
              <HowToText>1. On-ramp via ZKP2P using your Venmo or Revolut account</HowToText>
              <HowToText>2. Transfer ETH from your Coinbase account</HowToText>
              <HowToText>3. Transfer ETH from your MetaMask wallet</HowToText>
              <HowToText>If you do not currently have ETH or in your Coinbase account, we recomment using the ZKP2P service to fund your account. Like our domain exchange it is the fastest and cheapest way to get ETH onchain.</HowToText>
              
              {/* To Do: Link to on-ramp using integration flow*/} 
              <SectionCTA to="" onClick={() => jumpToMedia("https://ramp.zkp2p.xyz")}>Fund Account with ZKP2P Ramp</SectionCTA>
            </DialogBox>
            <ImageWrapper $isLeft={false}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/funding_account.png`}
                alt="Funding Account"
              />
            </ImageWrapper>
          </HowToSection>

          <HowToSection ref={sectionRefs['Buying Domains']}>
            <ImageWrapper $isLeft={true}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/place_bid.png`}
                alt="Buying Domains"
              />
            </ImageWrapper>
            <DialogBox $isLeft={false}>
              <IconWrapper>
                <ShoppingBag size={32} />
              </IconWrapper>
              <BoxHeader>Buying Domains</BoxHeader>
              <HowToText>To buy a domain, you first must have funded your account with ETH, see the <SectionEmbeddedLink to="" onClick={() => scrollToSection('Funding Account')}>Funding Account section</SectionEmbeddedLink>. When you identify a domain you want to buy, you create a bid by selecting the listing and specifying a Namecheap username for the seller to send the domain to. This means you must first create or have an existing account on <SectionEmbeddedLink to="" onClick={() => jumpToMedia("https://www.namecheap.com")}>Namecheap</SectionEmbeddedLink>. For every listing, your username is encrypted to preserve privacy and only the seller of the domain can see your username.</HowToText>
              <HowToText>When you place your bid, your ETH will be transferred to escrow. This escrow can only be successfully released if the seller provides proof of domain transfer, see the <SectionEmbeddedLink to="" onClick={() => scrollToSection('Claim Payment')}>Claim Payment section</SectionEmbeddedLink> for details, or you release the funds to the seller.</HowToText>
              <HowToText>If the seller does not select your bid, you can initiate a refund which upon successful completion will return all ETH to your account, see <SectionEmbeddedLink to="" onClick={() => scrollToSection('Initiating Refund')}>Initiating Refund section</SectionEmbeddedLink>.</HowToText>
              <SectionCTA to="/browse">Starting Browsing Domains Now</SectionCTA>
            </DialogBox>
          </HowToSection>

          <HowToSection ref={sectionRefs['Accepting Domains']}>
            <DialogBox $isLeft={true}>
              <IconWrapper>
                <Package size={32} />
              </IconWrapper>
              <BoxHeader>Accepting Domains</BoxHeader>
              <HowToText>To finalize the transaction, you must accept the domain transfer from the seller. After the seller selects your bid and initiates the transfer, Namecheap will send you an email to accept it. Monitor your inbox for this email. Until you accept, the domain is not yours, and the seller can revoke the transfer and cancel the order.</HowToText>
              <HowToText>It is <strong>recommended</strong> you enable the Instant Accept feature which allows you to automatically receive domains when your bid wins. Sellers prefer buyers with Instant Accept, increasing your chances of winning domain auctions. Enable Instant Accept by going to the <SectionEmbeddedLink to="/bids">Bids page</SectionEmbeddedLink> and following the instructions</HowToText>
            </DialogBox>
            <ImageWrapper $isLeft={false}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/instant_accept.png`}
                alt="Accepting Domains"
              />
            </ImageWrapper>
          </HowToSection>

          <HowToSection ref={sectionRefs['Initiating Refund']}>
            <ImageWrapper $isLeft={true}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/initiate_refund.png`}
                alt="Initiating Refund"
              />
            </ImageWrapper>
            <DialogBox $isLeft={false}>
              <IconWrapper>
                <Rewind size={32} />
              </IconWrapper>
              <BoxHeader>Initiating Refund</BoxHeader>
              <HowToText>The refund process exists in case you want to cancel your bid. A refund can be initiated 24 hours after a bid is placed on a listing.</HowToText>
              <HowToText>In order to start a refund, navigate to the <SectionEmbeddedLink to="/bids">My Bids</SectionEmbeddedLink> page and view your Bids. In the Active Bids table, you should see the option to start a refund by submitting a transaction to the contract.</HowToText>
              <HowToText>Once a refund is initiated, a 24-hour period must elapse to give the seller time to revoke their transfer in case it is still outstanding or submit proof of acceptance for the transfer. After this period has elapsed, the buyer can return to their My Bids page and withdraw their bid, which will return ETH to their account.</HowToText>
            </DialogBox>
          </HowToSection>

          <HowToSection ref={sectionRefs['Verifying Domains']}>
            <DialogBox $isLeft={true}>
              <IconWrapper>
                <Check size={32} />
              </IconWrapper>
              <BoxHeader>Verifying Domains</BoxHeader>
              <HowToText>We use a new cryptographic web attestation protocol that ensures domains are authentic and verified. The protocol allows a notary to extract selected data from encrypted traffic between Namecheaps's website and its servers. Since this traffic can contain sensitive data cryptography is used to ensure only the data necessary for verifying domains is exposed so that no sensitive data is leaked.</HowToText>
              <HowToText>In order to generate these attestations, you will need to download our companion browser extension, Peer. The extension only reads data on Namecheap's website locally and does not forward any traffic to servers.</HowToText>
              <HowToText>Once you have created proofs of ownership of your domains you must submit the proofs to our contracts to enable them for listing. If you sign in using email or Google, your transaction costs are covered by us, however any other wallet will require holding ETH.</HowToText>
              <SectionCTA to="" onClick={() => jumpToMedia(CHROME_EXTENSION_URL)}>Download Companion Browser Extension Now</SectionCTA>
            </DialogBox>
            <ImageWrapper $isLeft={false}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/verify_domain.png`}
                alt="Verifying Domains"
              />
            </ImageWrapper>
          </HowToSection>

          <HowToSection ref={sectionRefs['Creating Listing']}>
            <ImageWrapper $isLeft={true}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/create_listing.png`}
                alt="Creating Listing"
              />
            </ImageWrapper>
            <DialogBox $isLeft={false}>
              <IconWrapper>
                <List size={32} />
              </IconWrapper>
              <BoxHeader>Creating Listing</BoxHeader>
              <HowToText>Before creating a listing, you must have a verified domain in your inventory, see the <SectionEmbeddedLink to="" onClick={() => scrollToSection('Verifying Domains')}>Verifying Domains section</SectionEmbeddedLink>. Once you have a verified domain, you can create a listing on the <SectionEmbeddedLink to="/my-listings">My Listings page</SectionEmbeddedLink> by selecting the domain you wish the sell and specifying the amount you want the buyer to pay.</HowToText>
              <HowToText>A note on fees: A 2.5% fee is charged upon successful sale of a domain. There are no additional fees for the buyer. Given that other domain marketplaces generally charge a total spread of 10%+, you could raise your list price vs those other platforms and the buyer will still save money!</HowToText>
              <SectionCTA to="/my-listings">Create A Listing Now</SectionCTA>
            </DialogBox>
          </HowToSection>

          <HowToSection ref={sectionRefs['Transferring Domains']}>
            <DialogBox $isLeft={true}>
              <IconWrapper>
                <Send size={32} />
              </IconWrapper>
              <BoxHeader>Transferring Domains</BoxHeader>
              <HowToText>You can finalize the sale by going to the corresponding domain listing page, selecting a bid of your choice and click Finalize Sale. Follow the instructions that will navigate you to Namecheap's website. On Namecheap's website, you will navigate to the domain you are fulfilling and then transfer the domain to the buyer's account. If the buyer has Instant Accept enabled, then the domain will immediately be transferred to them. See the <SectionEmbeddedLink to="" onClick={() => scrollToSection('Accepting Domains')}>Accepting Domains section</SectionEmbeddedLink> for details.</HowToText>
              <HowToText>Once the buyer accepts your transfer, you will get an email from Namecheap which is required to generate a proof of transfer to <SectionEmbeddedLink to="" onClick={() => scrollToSection('Claim Payment')}>claim payment</SectionEmbeddedLink>.</HowToText>
            </DialogBox>
            <ImageWrapper $isLeft={false}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/transfer_domain.png`}
                alt="Transferring Domains"
              />
            </ImageWrapper>
          </HowToSection>

          <HowToSection ref={sectionRefs['Claim Payment']}>
            <ImageWrapper $isLeft={true}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/verify_transfer.png`}
                alt="Claim Payment"
              />
            </ImageWrapper>
            <DialogBox $isLeft={false}>
              <IconWrapper>
                <DollarSign size={32} />
              </IconWrapper>
              <BoxHeader>Claim Payment</BoxHeader>
              <HowToText>To claim your payment, you'll need the email that Namecheap sent you upon transferring the domain. This email is used to create the proof of transfer, which is used to unlock the buyer's ETH from escrow. For more details on the transfer process, see the <SectionEmbeddedLink to="" onClick={() => scrollToSection('Transferring Domains')}>Transferring Domains section</SectionEmbeddedLink>.</HowToText>
              <HowToText>Start claiming payment by going to the corresponding domain listing page and follow the instructions to submit a proof of transfer using the Namecheap email. We recommend you set your Namecheap account email to a Gmail provider prior to transferring the domain. Once the proof is generated, you can submit it on-chain to unlock your payment.</HowToText>
              <HowToText><b>Some notes on dispute resolution:</b> If the buyer does not have Instant Accept enabled and does not accept your transfer in a timely manner or initiates a refund with an outstanding (non-accepted) transfer, we recommend that you revoke the transfer on Namecheap. If the user has initiated a refund and accepted the transfer, you can simply provide proof of acceptance to unlock your payment. To learn more about how refunds work, see the <SectionEmbeddedLink to="" onClick={() => scrollToSection('Initiating Refund')}>Initiating Refund section</SectionEmbeddedLink>.</HowToText>
            </DialogBox>
          </HowToSection>
        </HowToSectionsContainer>

        <AboutContainer>
          <IconRow>
            <SocialIcon
              icon={'twitter'}
              onClick={() => jumpToMedia('https://twitter.com/zkp2p')}
            />

            <SocialIcon
              icon={'github'}
              onClick={() => jumpToMedia('https://github.com/zkp2p')}
            />

            <SocialIcon
              icon={'telegram'}
              onClick={() => jumpToMedia('https://t.me/+XDj9FNnW-xs5ODNl')}
            />
          </IconRow>

          <SignatureContainer>
            Made with<HeartIcon>♡</HeartIcon>
          </SignatureContainer>
          by the ZKP2P Team
        </AboutContainer>
      </Container>
    </PageWrapper>
  )
};

const PageWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;

  @media (min-width: 600px) {
    padding: 12px 8px;
    padding-bottom: 0rem;
  }

  @media (max-width: 600px) {
    padding: 12px 12px;
    max-width: 100vw;
    overflow-x: hidden;
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 535px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 40px;
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  min-height: 535px;
  max-height: 1000px;
`;

const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 6rem;
  gap: 0.5rem;
  width: 100%;

  @media (min-width: 600px) {
    z-index: ${Z_INDEX.landing_hero};
  }
`;

const LearnMoreContainer = styled.div`
  display: flex;
  align-items: center;
  color: rgb(94, 94, 94);
  cursor: pointer;
  font-size: 20px;
  font-weight: 535;
  margin: 18px 0px 36px;
  padding-left: 12px;
  pointer-events: auto;
  gap: 8px;

  &:hover {
    opacity: 0.6;
  }
`;

const HeartIcon = styled.span`
  color: ${colors.heartRed};
  margin-top: -2px;
`;

const Icon = styled(SVGIconThemed)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`;

const SocialIcon = styled(SVGIconThemed)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`;

const SubHeaderContainer = styled.div`
  display: grid;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;
  gap: 1rem;
`;

const AboutContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
`;

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  margin-top: 0.75rem;
`;

const SignatureContainer = styled.div`
  padding-top: 1rem;
  gap: 0.25rem;
  display: flex;
`;

const BoxesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  margin-top: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Box = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: #FFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const BoxHeader = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #333;
`;

const UnorderedList = styled.ol`
  padding-left: 1.5rem;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  font-size: 1.1rem;
  color: #444;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    color: #FF5E14;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const HowToSectionsContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
`;

const HowToSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-right: 12px;
    gap: 2rem;
  }
`;

const HowToText = styled.p`
  line-height: 1.5;
`;

const DialogBox = styled.div<{ $isLeft: boolean }>`
  width: 45%;
  padding: 2rem;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  order: ${props => props.$isLeft ? 0 : 1};

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
    text-align: center;
  }

  p {
    font-size: 1rem;
    color: #666;
  }

  @media (max-width: 768px) {
    width: 100%;
    order: 1;
  }
`;

const SectionCTA = styled(Link)`
  color: ${colors.cardTitleText};
  font-weight: 535;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-top: auto;
  transition: opacity 250ms ease 0s;
  &:hover {
    opacity: 0.6;
  }
`;

const SectionEmbeddedLink = styled(Link)`
  color: ${colors.cardTitleText};
  font-weight: 535;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-top: auto;
  transition: opacity 250ms ease 0s;
  &:hover {
    opacity: 0.6;
  }
`;

const ImageWrapper = styled.div<{ $isLeft: boolean }>`
  width: 45%;
  aspect-ratio: 1 / 1;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  order: ${props => props.$isLeft ? 0 : 1};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
    order: 0;
  }
`;

const SectionImage = styled.img`
  height: 100%;
  object-position: top center;
  border-radius: 8px;
  object-fit: cover;
  display: block;
  mask-image: linear-gradient(to bottom, 
    black 80%, 
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(to bottom, 
    black 80%, 
    transparent 100%
  );
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  color: ${colors.cardTitleText};
`;
