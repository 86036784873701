import { createContext } from 'react';

import { Domain, Bid, DetailedListing } from '@helpers/types';


interface SellersValues {
  sellerDetailedListings: DetailedListing[] | null;
  refetchSellerDetailedListings: (() => void) | null;
  shouldFetchSellerListings: boolean;

  sellerListingsBids: Bid[] | null;
  refetchSellerListingsBids: (() => void) | null;
  shouldFetchSellerListingsBids: boolean;

  sellerDomains: Domain[] | null;
  refetchSellerDomains: (() => void) | null;
  shouldFetchSellerDomains: boolean;
};

const defaultValues: SellersValues = {
  sellerDetailedListings: null,
  refetchSellerDetailedListings: null,
  shouldFetchSellerListings: false,

  sellerListingsBids: null,
  refetchSellerListingsBids: null,
  shouldFetchSellerListingsBids: false,

  sellerDomains: null,
  shouldFetchSellerDomains: false,
  refetchSellerDomains: null,
};

const SellersContext = createContext<SellersValues>(defaultValues);

export default SellersContext;
