import React, { useEffect, useState, useRef } from 'react';
import { Inbox, ChevronLeft, ChevronRight } from 'react-feather';
import styled, { css } from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';

import { AccessoryButton } from '@components/common/AccessoryButton'; 
import { RowBetween } from '@components/layouts/Row';
import { ListingCard } from '@components/Browse/ListingCard'; 
import { DetailedListing } from '@helpers/types';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';


const CARD_WIDTH = 264; // 240 + 24
const VISIBLE_CARDS = 4;

export interface ListingRowData {
  nameText: string;
  price: bigint;
  highestBid: bigint;
  numBids: number;
};

interface ListingCarouselProps {
  tableTitle: string;
  subTitle: string;
  listings: DetailedListing[];
  handleSeeAllButtonClick: () => void;
};

interface ArrowContainerProps {
  isActive: boolean;
};

export const ListingCarousel: React.FC<ListingCarouselProps> = ({
  tableTitle,
  subTitle,
  listings,
  handleSeeAllButtonClick
}) => {
  const navigate = useNavigate();
  const carouselRef = useRef<HTMLDivElement>(null);

  /*
   * State
   */

  const [listingRowData, setListingRowData] = useState<ListingRowData[]>([]);
  
  const [scrollPosition, setScrollPosition] = useState(0);

  const [leftArrowActive, setLeftArrowActive] = useState(false);
  const [rightArrowActive, setRightArrowActive] = useState(true);

  /*
   * Handlers
   */


  const handleListingCardClick = (rowIndex: number) => {
    if (!listings) {
      return;
    };

    const listingId = listings[rowIndex].listingId;
    navigate(`/listing/${listingId}`);
  }

  const handleScrollLeft = () => {
    setScrollPosition((prev) => Math.max(prev - VISIBLE_CARDS, 0));
    if (scrollPosition === 0) {
      setLeftArrowActive(false);
    } else {
      setLeftArrowActive(true);
    }
  };

  const handleScrollRight = () => {
    setScrollPosition((prev) => Math.min(prev + VISIBLE_CARDS, Math.max(0, listingRowData.length - VISIBLE_CARDS)));
    if (scrollPosition === Math.max(0, listingRowData.length - VISIBLE_CARDS)) {
      setRightArrowActive(false);
    } else {
      setRightArrowActive(true);
    }
  };

  /*
   * Hooks
   */

  useEffect(() => {
    if (listings && listings.length > 0) {
      const listingRowsData: ListingRowData[] = listings.map((detailedListing: DetailedListing) => {
        const nameText = detailedListing.domain.name;
        const price = detailedListing.price;
        
        let highestBid = BigInt(0);        
        if (detailedListing.bids && detailedListing.bids.length > 0) {
          highestBid = detailedListing.bids.reduce((max, bid) => bid.price > max ? bid.price : max, BigInt(0));
        }

        const numBids = detailedListing.bids ? detailedListing.bids.length : 0;

        return {
          nameText,
          price,
          highestBid,
          numBids,
        };
      });

      setListingRowData(listingRowsData);
    } else {
      setListingRowData([]);
    }
  }, [listings]);

  useEffect(() => {
    setLeftArrowActive(scrollPosition > 0);

    setRightArrowActive(scrollPosition < listingRowData.length - VISIBLE_CARDS);
  }, [scrollPosition, listingRowData]);

  /*
   * Component
   */

  return (
    <Container>
      <TitleRow>
        <TitleContainer>
          <ThemedText.HeadlineMedium>
            {tableTitle}
          </ThemedText.HeadlineMedium>

          <ThemedText.BodySecondary>
            {subTitle}
          </ThemedText.BodySecondary>
        </TitleContainer>

        <ButtonAndChevronContainer>
          <AccessoryButton
            onClick={handleSeeAllButtonClick}
            height={36}
            title={'See All'}
          />

          <ScrollArrowsContainer>
            <ArrowContainer isActive={leftArrowActive}>
              <ChevronLeft 
                onClick={handleScrollLeft} 
              />
            </ArrowContainer>
            
            <ArrowContainer isActive={rightArrowActive}>
              <ChevronRight 
                onClick={handleScrollRight} 
              />
            </ArrowContainer>
          </ScrollArrowsContainer>
        </ButtonAndChevronContainer>
      </TitleRow>

      <CarouselContainer ref={carouselRef}>
        {listingRowData.length === 0 ? (
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
              <div>
                No active listings.
              </div>
            </ThemedText.DeprecatedBody>
          </ErrorContainer>
        ) : (
          <CardCarousel style={{ transform: `translateX(-${scrollPosition * CARD_WIDTH}px)` }}>
            {listingRowData.map((listingRowData, rowIndex) => (
              <ListingCard
                key={rowIndex}
                nameText={listingRowData.nameText}
                price={listingRowData.price}
                highestBid={listingRowData.highestBid}
                numBids={listingRowData.numBids}
                rowIndex={rowIndex}
                onCardClick={() => handleListingCardClick(rowIndex)}
              />
            ))}
          </CardCarousel>
        )}
      </CarouselContainer>
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  overflow: hidden;
`;

const TitleRow = styled(RowBetween)`
  height: 76px;
  align-items: center;
  padding: .5rem 1.25rem 1rem 1.25rem;
  
  color: ${colors.darkText};

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  };
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ButtonAndChevronContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ScrollArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const CarouselContainer = styled.div`
  overflow: hidden;
`;

const CardCarousel = styled.div`
  display: flex;
  gap: 1.5rem;
  transition: transform 0.3s ease;
`;

const ArrowContainer = styled.div<ArrowContainerProps>`
  cursor: ${({ isActive }) => isActive ? 'pointer' : 'default'};
  opacity: ${({ isActive }) => isActive ? 1 : 0.5};
`;
