import { createContext } from 'react';

import { Abi } from '@helpers/types';


interface SmartContractsValues {
  blockscanUrl: string | null;

  verifiedDomainRegistryAbi: Abi | null;
  swapDomainExchangeAbi: Abi | null;

  verifiedDomainRegistryAddress: string | null;
  swapDomainExchangeAddress: string | null;

  // Socket
  socketBridgeAddress: string | null;

  // Lifi
  lifiBridgeAddress: string | null;
}

const defaultValues: SmartContractsValues = {
  blockscanUrl: null,

  verifiedDomainRegistryAbi: null,
  swapDomainExchangeAbi: null,

  verifiedDomainRegistryAddress: null,
  swapDomainExchangeAddress: null,

  // Socket
  socketBridgeAddress: null,

  // Lifi
  lifiBridgeAddress: null,
};

const SmartContractsContext = createContext<SmartContractsValues>(defaultValues)

export default SmartContractsContext
