export type ExtensionEventMessage = {
  origin: string;
  data: {
    type: string;
    status: string;
    requestHistory: {
      notaryRequests: ExtensionNotaryProofRequest[];
    };
  }
};

export type ExtensionEventVersionMessage = {
  origin: string;
  data: {
    type: string;
    status: string;
    version: string;
  }
};

export type ExtensionNotaryProofRequest = {
  body: string,
  headers: string,
  id: string,
  maxTranscriptSize: number,
  method: string,
  notaryUrl: string,
  proof: any,                 // returned as an object
  secretHeaders: string[],
  secretResps: string[],
  status: string,
  url: string,
  verification: any,          // returned as an object
  metadata: any,              // different depending on the notarization
  websocketProxyUrl: string,
};

export const ExtensionPostMessage = {
  FETCH_EXTENSION_VERSION: "fetch_extension_version",
  FETCH_VERIFIED_DOMAIN_PROOFS: "fetch_verified_namecheap_proofs",
  OPEN_SIDEBAR_VERIFY_DOMAINS: "open_sidebar_verify_namecheap",
  ARCHIVE_NOTARIZATION: "archive_notarization_request"
};

export const ExtensionReceiveMessage = {
  EXTENSION_VERSION_RESPONSE: "extension_version_response",
  VERIFIED_DOMAIN_PROOFS_RESPONSE: "verified_namecheap_proofs_response"
};
