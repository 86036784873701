
export const getEmptyProof = () => {
  return {
    a: [
      '0x0', '0x0'
    ], b: [
      ['0x0', '0x0'], ['0x0', '0x0']
    ], c: [
      '0x0', '0x0'
    ], signals: [
      '0x0', '0x0', '0x0', '0x0',
      '0x0', '0x0', '0x0', '0x0',
      '0x0', '0x0'
    ]
  };
}

export const reformatProofForChain = (proof: string, publicSignals: string) => {
  if (!proof) return { a: [], b: [], c: [], signals: [] };

  const parsedProof: { pi_a: string[], pi_b: string[][], pi_c: string[] } = JSON.parse(proof);
  const parsedPublicSignals: string[] = JSON.parse(publicSignals);

  const pi_a: [string, string] = parsedProof.pi_a.slice(0, 2) as [string, string];
  const pi_b: [[string, string], [string, string]] = parsedProof.pi_b.slice(0, 2).map((g2point: string[]) => g2point.reverse()) as [[string, string], [string, string]];
  const pi_c: [string, string] = parsedProof.pi_c.slice(0, 2) as [string, string];

  // console.log("pi_a", pi_a);
  // console.log("pi_b", pi_b);
  // console.log("pi_c", pi_c);

  return {
    a: pi_a,
    b: pi_b,
    c: pi_c,
    signals: parsedPublicSignals,
  };
};

export const reformatMultiProofAndSignalsForChain = (
  proof: string,
  signals: string,
  bodyHashProof: string,
  bodyHashPublicSignals: string,
) => {
  if (!(proof && bodyHashProof)) return [{ a: [], b: [], c: [], signals: [] }, { a: [], b: [], c: [], signals: [] }];

  const parsedProof = JSON.parse(proof);
  const parsedBodyHashProof = JSON.parse(bodyHashProof);

  const proofAndSignals = {
    a: parsedProof["pi_a"].slice(0, 2),
    b: parsedProof["pi_b"].slice(0, 2).map((g2point: any[]) => g2point.reverse()),
    c: parsedProof["pi_c"].slice(0, 2),
    signals: JSON.parse(signals),
  }
  const bodyHashProofAndSignals = {
    a: parsedBodyHashProof["pi_a"].slice(0, 2),
    b: parsedBodyHashProof["pi_b"].slice(0, 2).map((g2point: any[]) => g2point.reverse()),
    c: parsedBodyHashProof["pi_c"].slice(0, 2),
    signals: JSON.parse(bodyHashPublicSignals),
  }
  return [proofAndSignals, bodyHashProofAndSignals];
}
