import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useEnableInstantAcceptTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapDomainExchangeAddress, swapDomainExchangeAbi } = useSmartContracts();

  /*
   * State
   */
  const [shouldConfigureEnableInstantAcceptWrite, setShouldConfigureEnableInstantAcceptWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  //function enableInstantAccept()

  const { config: writeEnableInstantAcceptConfig } = usePrepareContractWrite({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'enableInstantAccept',
    onError(error: any) {
      console.log('writeEnableInstantAcceptConfig failed: ', error.message);

      setShouldConfigureEnableInstantAcceptWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureEnableInstantAcceptWrite,
  });

  const {
    data: submitEnableInstantAcceptResult,
    status: signEnableInstantAcceptTxStatus,
    writeAsync: writeEnableInstantAcceptAsync,
  } = useContractWrite(writeEnableInstantAcceptConfig);

  const {
    status: mineEnableInstantAcceptTxStatus,
  } = useWaitForTransaction({
    hash: submitEnableInstantAcceptResult ? submitEnableInstantAcceptResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeEnableInstantAcceptAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeEnableInstantAcceptAsync,
    setShouldConfigureEnableInstantAcceptWrite,
    shouldConfigureEnableInstantAcceptWrite,
    signEnableInstantAcceptTxStatus,
    mineEnableInstantAcceptTxStatus,
    transactionHash: submitEnableInstantAcceptResult?.hash,
  };
};
