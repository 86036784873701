import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import { Proof } from '@helpers/types';
import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useVerifyDomainsTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { verifiedDomainRegistryAddress, verifiedDomainRegistryAbi } = useSmartContracts();

  /*
   * State
   */

  const [domainProofsInput, setDomainProofsInput] = useState<Proof[]>([]);

  const [shouldConfigureVerifyDomainsWrite, setShouldConfigureVerifyDomainsWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  const { config: writeVerifyDomainsConfig } = usePrepareContractWrite({
    address: verifiedDomainRegistryAddress,
    abi: verifiedDomainRegistryAbi,
    functionName: 'verifyDomains',
    args: [
      domainProofsInput,
    ],
    onError(error: any) {
      console.log('writeVerifyDomainsConfig failed: ', error.message);

      setShouldConfigureVerifyDomainsWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureVerifyDomainsWrite,
  });

  const {
    data: submitVerifyDomainsResult,
    status: signVerifyDomainsTransactionStatus,
    writeAsync: writeVerifyDomainsAsync,
  } = useContractWrite(writeVerifyDomainsConfig);

  const {
    status: mineVerifyDomainsTransactionStatus,
  } = useWaitForTransaction({
    hash: submitVerifyDomainsResult ? submitVerifyDomainsResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeVerifyDomainsAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeVerifyDomainsAsync,
    domainProofsInput,
    setDomainProofsInput,
    setShouldConfigureVerifyDomainsWrite,
    signVerifyDomainsTransactionStatus,
    mineVerifyDomainsTransactionStatus,
    transactionHash: submitVerifyDomainsResult?.hash,
  };
};
