import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { AutoColumn } from '@components/layouts/Column';
import { STATE_REFETCH_INTERVAL, BID_REFETCH_INTERVAL } from '@helpers/constants';
import useListings from '@hooks/contexts/useListings';
import { ExpandListing } from './ExpandListing';
import { FinalizeSaleForm, FinalizeSaleBidDetails } from '@components/Listing/FinalizeSale';

export default function Listing() {
  /*
   * Contexts
   */

  const {
    shouldFetchActiveListings,
    refetchActiveListings,
    shouldFetchListingCounter,
    refetchListingCounter
  } = useListings();

  /*
   * State
   */

  const [selectedBidDetails, setSelectedBidDetails] = useState<FinalizeSaleBidDetails | null>(null);
  const [isFinalizeBid, setIsFinalizeBid] = useState<boolean>(false);


  /*
   * Hooks
   */

  const { listingId } = useParams<{ listingId: string }>();

  useEffect(() => {
    if (shouldFetchListingCounter) {
      const intervalId = setInterval(() => {
        refetchListingCounter?.();
      }, STATE_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchListingCounter]);

  useEffect(() => {
    if (shouldFetchActiveListings) {
      const intervalId = setInterval(() => {
        refetchActiveListings?.();
      }, BID_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchActiveListings]);

  /*
   * Handlers
   */

  const handleFinalizeBidClicked = (details: FinalizeSaleBidDetails) => {
    setSelectedBidDetails(details);

    setIsFinalizeBid(true);
  }

  const handleFinalizeBidBackClicked = () => {
    setIsFinalizeBid(false);

    setSelectedBidDetails(null);
  }


  /*
   * Component
   */

  function renderContent() {
    if (isFinalizeBid && selectedBidDetails) {
      return (
        <FinalizeSaleForm
          handleBackClick={handleFinalizeBidBackClicked}
          bidDetails={selectedBidDetails}
        />
      );
    } else {
      return (
        <ExpandListing 
          listingId={listingId} 
          handleFinalizeSaleClicked={handleFinalizeBidClicked}
        />
      )
    }
  }

  return (
    <Wrapper>
      {renderContent()}
    </Wrapper>
  );
};

const Wrapper = styled(AutoColumn)`
  width: 920px;
  margin: auto;
  padding: 0 2rem 0rem 2rem;

  @media (max-width: 600px) {
    width: 100%;
    padding: 0;
  }
`;