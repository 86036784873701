import React, { useEffect, useState, ReactNode } from 'react';

import {
  ExtensionEventMessage,
  ExtensionNotaryProofRequest,
  ExtensionEventVersionMessage,
  ExtensionPostMessage,
  ExtensionReceiveMessage,
} from '@hooks/useBrowserExtension';

import ExtensionProxyProofsContext from './ExtensionProxyProofsContext';


interface ProvidersProps {
  children: ReactNode;
};

const ExtensionNotarizationsProvider = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  // no-op

  /*
   * State
   */

  const [isSidebarInstalled, setIsSidebarInstalled] = useState<boolean>(false);
  const [sideBarVersion, setSideBarVersion] = useState<string | null>(null);

  const [verifiedDomainProofs, setVerifiedDomainProofs] = useState<ExtensionNotaryProofRequest[] | null>(null);

  /*
   * Extension Storage Reads
   */

  const refetchExtensionVersion = () => {
    window.postMessage({ type: ExtensionPostMessage.FETCH_EXTENSION_VERSION }, '*');

    // console.log('Posted Message: ', ExtensionPostMessage.FETCH_EXTENSION_VERSION);
  };

  const refetchVerifiedDomainProofs = () => {
    window.postMessage({ type: ExtensionPostMessage.FETCH_VERIFIED_DOMAIN_PROOFS }, '*');

    // console.log('Posted Message: ', ExtensionPostMessage.FETCH_VERIFIED_DOMAIN_PROOFS);
  };

  const openSidebarVerifyDomains = () => {
    window.postMessage({ type: ExtensionPostMessage.OPEN_SIDEBAR_VERIFY_DOMAINS }, '*');

    // console.log('Posted Message: ', ExtensionPostMessage.OPEN_SIDEBAR_VERIFY_DOMAINS);
  };

  const archiveNotarizationInSidebar = (notarizationId: string) => {
    window.postMessage({ type: ExtensionPostMessage.ARCHIVE_NOTARIZATION, notarizationId }, '*');

    // console.log('Posted Message: ', ExtensionPostMessage.ARCHIVE_NOTARIZATION);
  };

  /*
   * Handlers
   */

  const handleExtensionMessage = function(event: any) {
    if (event.origin !== window.location.origin) {
      return;
    };

    if (event.data.type && event.data.type === ExtensionReceiveMessage.EXTENSION_VERSION_RESPONSE) {
      handleExtensionVersionMessageReceived(event);
    };

    //
    // Namecheap
    //

    if (event.data.type && event.data.type === ExtensionReceiveMessage.VERIFIED_DOMAIN_PROOFS_RESPONSE) {
      handleExtensionProfileHistoryMessageReceived(event);
    };
  };

  const handleExtensionVersionMessageReceived = function(event: ExtensionEventVersionMessage) {
    // console.log('Client received EXTENSION_VERSION_RESPONSE message');

    const version = event.data.version;

    setSideBarVersion(version);
    setIsSidebarInstalled(true);
  };

  const handleExtensionProfileHistoryMessageReceived = function(event: ExtensionEventMessage) {
    // console.log('Client received REQUEST_PROFILE_HISTORY_RESPONSE message');

    if (event.data.requestHistory && event.data.requestHistory.notaryRequests) {
      const requestHistory = event.data.requestHistory.notaryRequests;

      setVerifiedDomainProofs(requestHistory);
    } else {
      // console.log('REQUEST_HISTORY_RESPONSE was blank');

      setVerifiedDomainProofs(null);
    }
  };

  /*
   * Hooks
   */

  useEffect(() => {
    refetchExtensionVersion();
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleExtensionMessage);
  
    return () => {
      window.removeEventListener("message", handleExtensionMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExtensionProxyProofsContext.Provider
      value={{
        isSidebarInstalled,
        sideBarVersion,
        refetchExtensionVersion,

        openSidebarVerifyDomains,
        archiveNotarizationInSidebar,
        refetchVerifiedDomainProofs,
        verifiedDomainProofs,
      }}
    >
      {children}
    </ExtensionProxyProofsContext.Provider>
  );
};

export default ExtensionNotarizationsProvider;
