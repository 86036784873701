import {
  Globe,
  Briefcase,
  Info,
  Code,
  Server,
  ShoppingBag,
  Heart,
  Zap,
  Tv,
  Music,
  Camera,
  Smartphone,
  Coffee,
  Feather
} from 'react-feather';

export interface TldInfo {
  name: string;
  logo: typeof Feather;
}

export const tldLogo: TldInfo[] = [
  { name: 'com', logo: Globe },
  { name: 'org', logo: Briefcase },
  { name: 'net', logo: Server },
  { name: 'io', logo: Code },
  { name: 'ai', logo: Zap },
  { name: 'app', logo: Smartphone },
  { name: 'dev', logo: Code },
  { name: 'info', logo: Info },
  { name: 'edu', logo: Info },
  { name: 'gov', logo: Briefcase },
  { name: 'co', logo: Globe },
  { name: 'biz', logo: Briefcase },
  { name: 'me', logo: Heart },
  { name: 'tv', logo: Tv },
  { name: 'fm', logo: Music },
  { name: 'tech', logo: Code },
  { name: 'store', logo: ShoppingBag },
  { name: 'blog', logo: Feather },
  { name: 'photo', logo: Camera },
  { name: 'cafe', logo: Coffee },
  { name: 'xyz', logo: Zap },
];

export const getLogoForTld = (tld: string): typeof Feather => {
  const tldInfo = tldLogo.find(t => t.name === tld.toLowerCase());
  
  return tldInfo ? tldInfo.logo : Globe;
};
