import React, { useEffect, useState } from 'react';
import { Inbox } from 'react-feather';
import styled, { css } from 'styled-components/macro';
import Link from '@mui/material/Link';

import { RowBetween } from '@components/layouts/Row';
import { CustomConnectButton } from '@components/common/ConnectButton';
import { SellerBidRow } from '@components/Listing/Seller/SellerBidRow'; 
import { Bid, BidState } from '@helpers/types/bid';
import { FinalizeSaleBidDetails } from '@components/Listing/FinalizeSale';
import { decryptMessageWithAccount } from '@helpers/messagEncryption';
import { toEthStringWithDecimals } from '@helpers/units';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useAccount from '@hooks/contexts/useAccount';
import { DetailedListing } from '@helpers/types';
import { getBidState } from '@helpers/bidTableHelpers';
import QuestionHelper from '@components/common/QuestionHelper';
import { commonStrings } from '@helpers/strings';
import { BID_STATE_DOCS_LINK, INSTANT_ACCEPT_DOCS_LINK } from '@helpers/docUrls';
import useTableScroll from '@hooks/useTableScroll';
import useMediaQuery from '@hooks/useMediaQuery';


export interface BidRowData {
  bidId: bigint;
  listingId: string;
  domainName: string;
  buyerId: string;
  buyerAddress: string;
  bidPrice: string;
  createdAt: bigint;
  instantAccept: boolean;
  bidState: BidState;
}

interface SellerBidTableProps {
  handleFinalizeBidClicked: (selectedBidDetails: FinalizeSaleBidDetails) => void;
  listing: DetailedListing | null;
}

export const SellerBidTable: React.FC<SellerBidTableProps> = ({
  listing,
  handleFinalizeBidClicked
}) => {
  const isMobile = useMediaQuery() === 'mobile';

  /*
   * Contexts
   */

  const { accountListingHash, isLoggedIn } = useAccount();

  /*
   * State
   */

  const [bidRowsData, setBidRowsData] = useState<BidRowData[]>([]);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);

  /*
   * Hooks
   */

  const { tableRef, isScrolling } = useTableScroll();

  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      setIsScrollEnabled(tableElement.scrollHeight > tableElement.clientHeight);
    }
  }, [bidRowsData, tableRef]);

  useEffect(() => {
    const processSellerListingsForBids = async () => {
      if (accountListingHash && listing && listing.bids && listing.bids.length > 0) {
       
        const listingBids: Bid[] = listing.bids;
        const domainName = listing.domain.name;

        // Sort bids by price
        listingBids.sort((a, b) => Number(b.price - a.price));

        const bidRowsData: BidRowData[] = [];
        
        // Extract Bid Info
        for (const bid of listingBids) {
          const bidPrice = `${toEthStringWithDecimals(bid.price, false, 4)} ETH`;
          const decodedBuyerId = await decryptMessageWithAccount(bid.encryptedBuyerId, accountListingHash);
          const bidState = getBidState(bid, listing);
          if (bidState === BidState.Expired) {
            continue;
          }
          
          bidRowsData.push({
            bidId: bid.bidId,
            listingId: listing.listingId.toString(),
            domainName: domainName,
            buyerId: decodedBuyerId,
            buyerAddress: bid.buyer,
            bidPrice: bidPrice,
            createdAt: bid.createdAt,
            instantAccept: bid.instantAccept,
            bidState: bidState
          });
        }

        setBidRowsData(bidRowsData);
      } else {
        setBidRowsData([]);
      }
    };
  
    processSellerListingsForBids();
  }, [accountListingHash, listing]);


  /*
   * Handlers
   */

  const handleCompleteBidRowPressed = (rowIndex: number) => {
    const selectedBidDetails: FinalizeSaleBidDetails = {
      bidId: bidRowsData[rowIndex].bidId.toString(),
      domainName: bidRowsData[rowIndex].domainName,
      decryptedBuyerId: bidRowsData[rowIndex].buyerId
    };

    handleFinalizeBidClicked(selectedBidDetails);
  };

  /*
   * Component
   */

  return (
    <Container>
      <Content>

        {!isLoggedIn ? (
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
              <div>
                Your pending bids will appear here.
              </div>
            </ThemedText.DeprecatedBody>
            <CustomConnectButton width={152} />
          </ErrorContainer>
        ) : bidRowsData.length === 0 ? (
            <ErrorContainer>
              <ThemedText.DeprecatedBody textAlign="center">
                <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                <div>
                  You have no pending bids.
                </div>
              </ThemedText.DeprecatedBody>
            </ErrorContainer>
        ) : (
          <>
            <TitleRow>
              <ThemedText.LabelSmall>
                Pending Bids
              </ThemedText.LabelSmall>
            </TitleRow>

            <TableHeaderRow isMobile={isMobile} isScrollEnabled={isScrollEnabled}>
              <ColumnHeader>
                <ColumnHeaderText>#</ColumnHeaderText>
              </ColumnHeader>

              {!isMobile && (
                <ColumnHeaderWithTooltip>
                  <ColumnHeaderText>State</ColumnHeaderText>
                  <QuestionHelper 
                    text={
                      <>
                        {commonStrings.get('BID_STATE_TOOLTIP')}
                        {' '}
                        <Link
                          href={BID_STATE_DOCS_LINK}
                          target='_blank'
                          style={{ color: 'inherit' }}
                        >
                          Read more ↗
                        </Link>
                      </>
                    }
                    size='sm'
                  />
                </ColumnHeaderWithTooltip>
              )}

              <ColumnHeader>
                <ColumnHeaderText>Bid</ColumnHeaderText>
              </ColumnHeader>

              <ColumnHeader>
                <ColumnHeaderText>Buyer</ColumnHeaderText>
              </ColumnHeader>

              {!isMobile && (
                <ColumnHeaderWithTooltip>
                  <ColumnHeaderText>Instant Accept</ColumnHeaderText>
                  <QuestionHelper 
                    text={
                      <>
                        {commonStrings.get('INSTANT_ACCEPT_TOOLTIP')}
                        {' '}
                        <Link
                          href={INSTANT_ACCEPT_DOCS_LINK}
                          target='_blank'
                          style={{ color: 'inherit' }}
                        >
                          Read more ↗
                        </Link>
                      </>
                    }
                    size='sm'
                  />
                </ColumnHeaderWithTooltip>
              )}

              {!isMobile && (
                <ColumnHeader>
                  <ColumnHeaderText>Placed At</ColumnHeaderText>
                </ColumnHeader>
              )}

              <ColumnHeader>
                <ColumnHeaderText>Actions</ColumnHeaderText>
              </ColumnHeader>
            </TableHeaderRow>

            <Table ref={tableRef}>
              {bidRowsData.map((bidRowData, rowIndex) => (
                <StyledBidRow key={rowIndex}>
                  <SellerBidRow
                    listingId={bidRowData.listingId}
                    buyerId={bidRowData.buyerId}
                    bidPrice={bidRowData.bidPrice}
                    createdAt={bidRowData.createdAt}
                    buyerAddress={bidRowData.buyerAddress}
                    bidState={bidRowData.bidState}
                    instantAccept={bidRowData.instantAccept}
                    rowIndex={rowIndex}
                    handleFinalizeSalePressed={() => handleCompleteBidRowPressed(rowIndex)}
                    isScrolling={isScrolling}
                  />
                </StyledBidRow>
              ))}
            </Table>
          </>
        )}
      </Content>
    </Container>
  )
};

const Container = styled.div`
  gap: 1rem;
  border-radius: 16px;
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  padding: 1.25rem 1.5rem 1rem 1.5rem;

  color: ${colors.darkText};

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0.5 1.25rem;
  };
`;

const Content = styled.div`
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;
  border-radius: 16px;
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;

  @media (max-width: 600px) {
    min-height: 18vh;
  };
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const TableHeaderRow = styled.div<{ isMobile: boolean, isScrollEnabled: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => isMobile ? '0.5fr 2fr 2fr 1fr' : '1fr 2fr 2fr 2.5fr 3fr 3fr 1fr'};
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  text-align: left;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
  border-top: 1px solid ${colors.defaultBorderColor};
  padding-right: ${({ isScrollEnabled }) => isScrollEnabled ? 'calc(1.5rem + 8px)' : '1.5rem'};
`;

const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColumnHeaderText = styled.div`
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const ColumnHeaderWithTooltip = styled(ColumnHeader)`
  gap: 4px;
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;
  max-height: 28vh;
  overflow-y: auto;
  scrollbar-width: thin;
  
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.defaultBorderColor};
    border-radius: 4px;
  }

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledBidRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;