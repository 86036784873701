export function formatEventDateTime(unixTimestamp: bigint): string {
  const date = new Date(Number(unixTimestamp) * 1000);

  // const dayAbbreviation = date.toLocaleString('en-US', { weekday: 'short' });
  const month = date.toLocaleString('en-US', { month: 'short' });
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  const formattedMinutes = minutes.toString().padStart(2, '0');

  const getOrdinalSuffix = (day: number): string => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  const ordinalDay = `${day}${getOrdinalSuffix(day)}`;

  return `${month} ${ordinalDay}, ${year}, ${formattedHours}:${formattedMinutes} ${period}`;
};

export function formatEventDateTimeShort(unixTimestamp: bigint): string {
  const date = new Date(Number(unixTimestamp) * 1000);

  const month = date.toLocaleString('en-US', { month: 'short' });
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  const formattedMinutes = minutes.toString().padStart(2, '0');

  const getOrdinalSuffix = (day: number): string => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  const ordinalDay = `${day}${getOrdinalSuffix(day)}`;

  return `${month} ${ordinalDay}, ${formattedHours}:${formattedMinutes} ${period}`;
};
