import React from 'react';
import styled, { css } from 'styled-components';
import { Zap, ZapOff } from 'react-feather';
import { ENSName } from 'react-ens-name';
import Link from '@mui/material/Link';

import useMediaQuery from '@hooks/useMediaQuery';
import { colors } from '@theme/colors';
import { BidState } from '@helpers/types/bid';
import QuestionHelper from '@components/common/QuestionHelper';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import { commonStrings } from '@helpers/strings';
import { formatEventDateTimeShort } from '@helpers/dateFormat';
import { alchemyMainnetEthersProvider } from 'index';
import { 
  getBidStateColor, 
  getBidStateString, 
  getInstantAcceptColor, 
  getInstantAcceptString 
} from '@helpers/bidTableHelpers';
import { TransactionIconButton } from '@components/common/TransactionIconButton';


interface SellerBidRowProps {
  listingId: string;
  buyerId: string;
  bidPrice: string;
  buyerAddress: string;
  rowIndex: number;
  createdAt: bigint;
  bidState: BidState;
  instantAccept: boolean;
  handleFinalizeSalePressed: () => void;
  isScrolling: boolean;
}

export const SellerBidRow: React.FC<SellerBidRowProps> = ({
  listingId,
  buyerId,
  bidPrice,
  buyerAddress,
  rowIndex,
  createdAt,
  bidState,
  instantAccept,
  handleFinalizeSalePressed,
  isScrolling
}: SellerBidRowProps) => {
  SellerBidRow.displayName = "SellerBidRow";

  const isMobile = useMediaQuery() === 'mobile';

  const { blockscanUrl } = useSmartContracts();

  const buyerEtherscanLink = `${blockscanUrl}/address/${buyerAddress}`;

  return (
    <Container isScrolling={isScrolling}>
      <BidDetailsContainer isMobile={isMobile}>
        <DetailsContainer>
          <SummaryLabelValue>
            {rowIndex + 1}
          </SummaryLabelValue>
        </DetailsContainer>

        {!isMobile && (
          <DetailsContainer>
            <SummaryLabelValue style={{ color: getBidStateColor(bidState) }}>
              {bidState === BidState.Active ? (
              getBidStateString(bidState)
            ) : bidState === BidState.RefundInitiated ? (
              <>
                {getBidStateString(bidState) + ' '}
                <QuestionHelper 
                  text={commonStrings.get('REFUND_INITIATED_WARNING')}
                />
              </>
            ) : (
              <>
                {getBidStateString(bidState) + ' '}
                <QuestionHelper 
                  text={commonStrings.get('EXPIRED_BID_SELLER_WARNING')}
                />
              </>
            )}
            </SummaryLabelValue>
          </DetailsContainer>
        )}

        <DetailsContainer>
          <PriceLabel>
            {bidPrice}
          </PriceLabel>
        </DetailsContainer>

        <DetailsContainer>
          <SummaryLabelValue>
            <Link href={buyerEtherscanLink} target="_blank">
              <ENSName
                provider={alchemyMainnetEthersProvider}
                address={buyerAddress}
                customDisplay={(address) => {
                  if (address) {
                    const first4 = address.slice(0, 6);
                    const last4 = address.slice(-4);
                    return `${first4}...${last4}`;
                  }
                  return '';
                }}
              />
            </Link>
          </SummaryLabelValue>
        </DetailsContainer>

        {!isMobile && (
          <DetailsContainer>
            <InstantAcceptContainer>
              <SummaryLabelValue style={{ color: getInstantAcceptColor(instantAccept) }}>
              {getInstantAcceptString(instantAccept)}
            </SummaryLabelValue>
            {instantAccept ? (
              <StyledZap size={16} />
            ) : (
              <StyledZapOff size={16} />
            )}
            </InstantAcceptContainer>
          </DetailsContainer>
        )}

        {!isMobile && (
          <DetailsContainer>
            <SummaryLabelValue>
              {formatEventDateTimeShort(createdAt)}
            </SummaryLabelValue>
          </DetailsContainer>
        )}
        
        <IconContainer>
          <TransactionIconButton
            icon="checkCircle"
            text="Select Bid"
            onClick={handleFinalizeSalePressed}
          />
        </IconContainer>
      </BidDetailsContainer>
    </Container>
  );
};

const Container = styled.div<{isScrolling: boolean}>`
  display: flex;
  
  ${({ isScrolling }) => isScrolling && css`
    padding-right: 0.5rem;
    transition: padding-right 0.3s ease;
  `}

  ${({ isScrolling }) => !isScrolling && css`
    padding-right: 0rem;
    transition: padding-right 0.3s ease;
  `}
`;

const BidDetailsContainer = styled.div<{isMobile?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ isMobile }) => isMobile ? '0.5fr 2fr 2fr 1fr' : '1fr 2fr 2fr 2.5fr 3fr 3fr 1fr'};
  align-items: flex-start;
  padding: 0rem 1.5rem;
  line-height: 24px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0rem;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0rem;
`;

const SummaryLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.darkText};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const PriceLabel = styled.span`
  font-size: 15px;
  color: ${colors.darkText};

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const InstantAcceptContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledZap = styled(Zap)`
  color: ${colors.accessoryButtonIconDefault};
`;

const StyledZapOff = styled(ZapOff)`
  color: ${colors.accessoryButtonIconDefault};
`;