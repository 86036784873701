import React, { useEffect } from 'react';
import styled from 'styled-components';

import Listings from '@components/Listings';
import useMediaQuery from '@hooks/useMediaQuery';
import useListings from '@hooks/contexts/useListings';


export const ListingsPage: React.FC = () => {
  const currentDeviceSize = useMediaQuery();

  /*
   * Contexts
   */

  const {
    shouldFetchActiveListings,
    refetchActiveListings,
    refetchListingCounter
  } = useListings();

  /*
   * Hooks
   */

  useEffect(() => {
    refetchListingCounter?.();

    if (shouldFetchActiveListings) {
      refetchActiveListings?.();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /*
   * Component
   */

  return (
    <PageWrapper $isMobile={currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile'}>
      <Listings />
    </PageWrapper>
  );
};

const PageWrapper = styled.div<{ $isMobile: boolean }>`
  padding-bottom: 7rem;
  
  @media (min-width: 600px) {
    padding: 12px 8px;
    padding-bottom: 3rem;
  }
`;
