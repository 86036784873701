type Contracts = {
  [network: string]: {
    [contract: string]: string;
  };
};

export const contractAddresses: Contracts = {
  'base_production': {
    'swapDomainExchange': '0x054be4cFB6e951A7dE921A179FA323c5ea3fCf47',
    'verifiedDomainRegistry': '0x6206EB4c794c7fe58315f76ab088Ee3C17E0d9f5',
    'nullifierRegistry': '0xcE4d0DE6589bF2b439d08dc9addD56428D76eC25',
    'ticketNft': '',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE'
  },
  'base_staging': {
    'swapDomainExchange': '0xe4148B108Fe4D7421853FE8cFfd35bDc2c0d95Ec',
    'verifiedDomainRegistry': '0xbCbE5B3F85ffAFa46960272a581A2662c257EF57',
    'nullifierRegistry': '0x2b68bFd2b44a6a9E8dC7b414550457B091ECB5fB',
    'ticketNft': '',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE'
  },
  'sepolia_staging': {
    'swapDomainExchange': '0xC8cd114C6274Ef1066840337E7678BC9731BEa68',
    'verifiedDomainRegistry': '0xb941e69B6C1A23A88cf9DA7D243bAE1D2Cb8eb6b',
    'nullifierRegistry': '0xCE6454f272127ba69e8C8128B92F2388Ca343257',
    'ticketNft': '',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE',
  },
  'localhardhat': {
    'swapDomainExchange': '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
    'verifiedDomainRegistry': '0x0165878A594ca255338adfa4d48449f69242Eb8F',
    'nullifierRegistry': '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
    'ticketNft': '',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE',
  },
};

export const blockExplorerUrls: { [network: string]: string } = {
  'hardhat': 'https://etherscan.io',
  'sepolia': 'https://sepolia.etherscan.io/',
  'base': 'https://basescan.org'
};
