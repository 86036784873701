
/*
 * Doc Links
 */

export const DOWNLOAD_AND_UPLOAD_EMAIL_INSTRUCTIONS_DOCS_LINK = 'https://docs.zkp2p.xyz/domain-market-guides/selling-domains/manual-email-input#download-and-upload-email';
export const COPY_AND_PASTE_EMAIL_INSTRUCTIONS_DOCS_LINK = 'https://docs.zkp2p.xyz/domain-market-guides/selling-domains/manual-email-input#copy-and-paste-email';
export const EXTENSION_DOCS_URL = 'https://docs.zkp2p.xyz/zkp2p/user-guides/zkp2p-extension';
export const FIND_NAMECHEAP_ID_DOCS_LINK = 'https://docs.zkp2p.xyz/domain-market-guides/peer-extension';

export const REVOLUT_DOWNLOAD_LINK = 'https://www.revolut.com/en-IN/qr-code-link/';
export const BID_STATE_DOCS_LINK = 'https://docs.zkp2p.xyz/domain-market-guides/buying-domains#how-to-cancel-bids-and-initiate-withdraws';
export const INSTANT_ACCEPT_DOCS_LINK = 'https://docs.zkp2p.xyz/domain-market-guides/buying-domains#step-3-enable-instant-accept';
export const DOMAIN_RISKS_DOCS_LINK = 'https://docs.zkp2p.xyz/domain-market-guides/risks';
export const PRIVACY_AND_SAFETY_DOCS_LINK = 'https://docs.zkp2p.xyz/domain-market-guides/selling-domains/privacy-and-safety';
export const BUYING_DOMAINS_DOCS_LINK = 'https://docs.zkp2p.xyz/domain-market-guides/buying-domains';
export const SELLING_DOMAINS_DOCS_LINK = 'https://docs.zkp2p.xyz/domain-market-guides/selling-domains';
export const VERIFY_DOMAINS_DOCS_LINK = 'https://docs.zkp2p.xyz/domain-market-guides/selling-domains#step-2-verify-your-domains-on-namecheap';

/*
 * TG Links
 */

export const ZKP2P_TG_LINK = 'https://t.me/zk_p2p';
export const ZKP2P_TG_SUPPORT_CHAT_LINK = 'https://t.me/zk_p2p/588';
export const ZKP2P_TG_INDIA_CHAT_LINK = 'https://t.me/zk_p2p/754';
export const ZKP2P_TG_TURKEY_CHAT_LINK = 'https://t.me/zk_p2p/1707';


