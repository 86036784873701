import { createContext } from 'react';

import { Domain } from '@helpers/types';


interface DomainsValues {
  fetchDomainsBatch: ((domainIds: string[]) => Promise<Domain[]>) | null;
};

const defaultValues: DomainsValues = {
  fetchDomainsBatch: null,
};

const DomainsContext = createContext<DomainsValues>(defaultValues);

export default DomainsContext;
