import { useEffect, useState, ReactNode } from 'react';
import { useContractRead } from 'wagmi';
import { readContract } from '@wagmi/core';

import { esl, CALLER_ACCOUNT } from '@helpers/constants';
import { Abi, DetailedBid, Listing, Bid, Domain } from '@helpers/types';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useAccount from '@hooks/contexts/useAccount';
import useDomains from '@hooks/contexts/useDomains';

import BuyersContext from './BuyersContext';


interface ProvidersProps {
  children: ReactNode;
}

const Buyers = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { isLoggedIn, loggedInEthereumAddress } = useAccount();
  const { swapDomainExchangeAddress, swapDomainExchangeAbi } = useSmartContracts();
  const { fetchDomainsBatch } = useDomains();

  /*
   * State
   */

  const [buyerBids, setBuyerBids] = useState<Bid[] | null>(null);

  const [buyerBidListingIds, setBuyerBidListingIds] = useState<bigint[] | null>(null);
  const [buyerBidListings, setBuyerBidListings] = useState<Listing[] | null>(null);

  const [buyerBidDomains, setBuyerBidDomains] = useState<Domain[] | null>(null);

  const [buyerDetailedBids, setBuyerDetailedBids] = useState<DetailedBid[] | null>(null);

  const [shouldFetchBuyerBids, setShouldFetchBuyerBids] = useState<boolean>(false);

  const [buyerInstantAcceptEnabled, setBuyerInstantAcceptEnabled] = useState<boolean>(false);
  const [shouldFetchBuyerInstantAcceptEnabled, setShouldFetchBuyerInstantAcceptEnabled] = useState<boolean>(false);

  /*
   * Contract Reads
   */

  // function getUserBids(address _user) external view returns (BidWithId[] memory bids) { 
  const {
    data: buyerBidsRaw,
    refetch: refetchBuyerBids,
  } = useContractRead({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'getUserBids',
    args: [
      loggedInEthereumAddress
    ],
    enabled: shouldFetchBuyerBids
  });

  const {
    data: buyerInstantAcceptEnabledRaw,
    refetch: refetchBuyerInstantAcceptEnabled,
  } = useContractRead({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'instantAcceptEnabled',
    args: [
      loggedInEthereumAddress
    ],
    enabled: shouldFetchBuyerInstantAcceptEnabled
  });

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('shouldFetchBuyBids_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
    esl && console.log('checking loggedInEthereumAddress: ', loggedInEthereumAddress);
    esl && console.log('checking swapDomainExchangeAddress: ', swapDomainExchangeAddress);

    if (isLoggedIn && loggedInEthereumAddress && swapDomainExchangeAddress) {
      esl && console.log('shouldFetchBuyBids_2');

      setShouldFetchBuyerBids(true);

      setShouldFetchBuyerInstantAcceptEnabled(true);
    } else {
      esl && console.log('shouldFetchBuyBids_3');

      setShouldFetchBuyerBids(false);

      setBuyerBids(null);

      setShouldFetchBuyerInstantAcceptEnabled(false);
    }
  }, [isLoggedIn, loggedInEthereumAddress, swapDomainExchangeAddress]);
  
  useEffect(() => {
    esl && console.log('buyerBids_1');
    esl && console.log('checking buyerBids: ', buyerBidsRaw);
  
    if (buyerBidsRaw && buyerBidsRaw.length > 0) {
      esl && console.log('buyerBids_2');

      const sanitizedBids = sanitizeRawBids(buyerBidsRaw);
  
      setBuyerBids(sanitizedBids.sanitizedBids);

      setBuyerBidListingIds(sanitizedBids.bidListingIds);
    } else {
      esl && console.log('buyerBids_3');
  
      setBuyerBids(null);

      setBuyerBidListingIds(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerBidsRaw]);

  useEffect(() => {
    esl && console.log('buyerInstantAcceptEnabled_1');
    esl && console.log('checking buyerInstantAcceptEnabled: ', buyerInstantAcceptEnabledRaw);
    
    if (buyerInstantAcceptEnabledRaw) {
      setBuyerInstantAcceptEnabled(buyerInstantAcceptEnabledRaw);
    } else {
      setBuyerInstantAcceptEnabled(false);
    }
  }, [buyerInstantAcceptEnabledRaw]);

  useEffect(() => {
    esl && console.log('buyerBidListings_1');
    esl && console.log('checking buyerBidListingIds: ', buyerBidListingIds);
  
    if (buyerBidListingIds && buyerBidListingIds.length > 0 && fetchDomainsBatch) {
      esl && console.log('buyerBidListings_2');

      const fetchListings = async () => {
        try {
          const sanitizedListings = await fetchingListingsBatch(buyerBidListingIds);
          
          esl && console.log('sanitizedListings: ', sanitizedListings);

          const buyerBidDomainIds = sanitizedListings.map(listing => listing.domainId);
          
          const sanitizedDomains = await fetchDomainsBatch(buyerBidDomainIds);

          setBuyerBidListings(sanitizedListings);

          setBuyerBidDomains(sanitizedDomains);
        } catch (error) {
          esl && console.log('buyerBidListings_3');
          
          setBuyerBidListings(null);

          setBuyerBidDomains(null);
        }
      };
  
      fetchListings();
    } else {
      esl && console.log('buyerBidListings_4');
  
      setBuyerBidListings(null);

      setBuyerBidDomains(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerBidListingIds, fetchDomainsBatch]);

  useEffect(() => {
    esl && console.log('buyerDetailedBids_1');
    esl && console.log('checking buyerBids: ', buyerBids);
    esl && console.log('checking buyerBidDomains: ', buyerBidDomains); 
  
    if (
      buyerBids && 
      buyerBidDomains && 
      buyerBidDomains.length > 0 &&
      buyerBidListings && 
      buyerBidListings.length > 0
    ) {
      esl && console.log('buyerDetailedBids_2');

      const detailedBuyerBids: DetailedBid[] = buyerBids
        .filter((bid: Bid) => {
          const listing = buyerBidListings.find(listing => listing.listingId === bid.listingId);
          const domain = listing ? buyerBidDomains.find(domain => domain.domainId === listing.domainId) : undefined;
          if (!listing || !domain) {
            return false;
          }

          const bidIdInListingBids = listing.bidIds.includes(bid.bidId);

          return bidIdInListingBids;
        })
        .map((bid: Bid) => {
          const listing = buyerBidListings.find(listing => listing.listingId === bid.listingId)!;
          const domain = buyerBidDomains.find(domain => domain.domainId === listing.domainId)!;
          
          return {
            ...bid,
            domain,
            listing
          };
        });

      esl && console.log('buyerDetailedBids_3');
      esl && console.log('detailedBuyerBids: ', detailedBuyerBids);

      setBuyerDetailedBids(detailedBuyerBids);
    } else {
      esl && console.log('buyerDetailedBids_3');
  
      setBuyerDetailedBids(null);
    }
  }, [buyerBids, buyerBidDomains, buyerBidListings]);

  /*
   * Helpers
   */

  interface SanitizedBidResult {
    sanitizedBids: Bid[];
    bidListingIds: bigint[];
  }

  const sanitizeRawBids = (rawBidsData: any[]): SanitizedBidResult => {
    const sanitizedBids: Bid[] = [];
    const bidListingIds: bigint[] = [];
  
    for (let i = rawBidsData.length - 1; i >= 0; i--) {
      const bidWithIdData = rawBidsData[i];
      const bidData = bidWithIdData.bid;
      
      const bid: Bid = {
        bidId: bidWithIdData.bidId,
        buyer: bidData.buyer,
        encryptedBuyerId: bidData.encryptedBuyerId,
        buyerIdHash: bidData.buyerIdHash,
        listingId: bidData.listingId,
        price: bidData.price,
        createdAt: bidData.createdAt,
        refundInitiated: bidData.refundInitiated,
        expiryTimestamp: bidData.expiryTimestamp,
        instantAccept: bidWithIdData.buyerInstantAcceptEnabled
      };
  
      sanitizedBids.push(bid);

      bidListingIds.push(bid.listingId);
    }

    const uniqueListingIds = [...new Set(bidListingIds)];

    return {
      sanitizedBids,
      bidListingIds: uniqueListingIds
    };
  };

  const fetchingListingsBatch = async (listingIdBatch: bigint[]) => {
    try {
      // function getListings(uint256[] memory _listingIds) external view returns (Listing[] memory listingInfo)
      const rawListingsData = await readContract({
        address: swapDomainExchangeAddress as `0x${string}`,
        abi: swapDomainExchangeAbi as Abi,
        functionName: 'getListings',
        args: [listingIdBatch],
        account: CALLER_ACCOUNT,
      });

      esl && console.error('rawListingsData:', rawListingsData);

      const sanitizedListings = sanitizeRawListings(rawListingsData as any[]);
      return sanitizedListings;
    } catch (error) {
      console.error('Error fetching listings batch:', error);
      
      return [];
    }
  };
  
  const sanitizeRawListings = (rawListingsData: any[]) => {
    const sanitizedListings: Listing[] = [];
  
    for (let i = rawListingsData.length - 1; i >= 0; i--) {
      const listingWithIdData = rawListingsData[i];
      const listingData = listingWithIdData.listing;
      
      const listing: Listing = {
        listingId: listingWithIdData.listingId,
        domainId: listingData.domainId,
        seller: listingData.seller.toString(),
        price: listingData.askPrice,
        createdAt: listingData.createdAt,
        isActive: listingData.isActive,
        bidIds: listingData.bids,
        minBidPrice: listingData.minBidPrice,
        saleEthRecipientAddress: listingData.saleEthRecipient.toString(),
        dkimKeyHash: listingData.dkimKeyHash.toString(),
        encryptionKey: listingData.encryptionKey.substring(2),
      };
  
      sanitizedListings.push(listing);
    }
  
    return sanitizedListings;
  };

  return (
    <BuyersContext.Provider
      value={{
        buyerDetailedBids,
        refetchBuyerBids,
        shouldFetchBuyerBids,

        buyerInstantAcceptEnabled,
        refetchBuyerInstantAcceptEnabled,
        shouldFetchBuyerInstantAcceptEnabled
      }}
    >
      {children}
    </BuyersContext.Provider>
  );
};

export default Buyers;
