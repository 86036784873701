import { Listing, Domain } from '@helpers/types';

// On the contract side, Orders are returned zipped with their IDs
//
// 
// struct Bid {
//   address buyer;
//   uint256 listingId;
//   string encryptedBuyerId;
//   bytes32 buyerIdHash;
//   uint256 createdAt;
//   uint256 expiryTimestamp;
//   uint256 price;
//   bool refundInitiated;
// }

// struct BidDetailsWithId {
//   uint256 bidId;
//   Bid bid;
//   bool buyerInstantAcceptEnabled;
// }

export interface Bid {
  bidId: bigint;
  buyer: string;
  encryptedBuyerId: string;
  buyerIdHash: string;
  listingId: bigint;
  expiryTimestamp: bigint;
  price: bigint;
  refundInitiated: boolean;
  createdAt: bigint;
  instantAccept: boolean;
};

//
// Bid State
//
// 0: Active
// 1: RefundInitiated
// 2: Expired
//

export enum BidState {
  Active = 0,
  RefundInitiated = 1,
  Expired = 2,
  ListingExpired = 3,
}

//
// Hydrated with Domain, Listing
//

export interface DetailedBid extends Bid {
  domain: Domain;

  instantAccept: boolean;

  listing: Listing;
};
