import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import { toBigIntEth } from '@helpers/units';
import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useIncreaseBidPriceTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapDomainExchangeAddress, swapDomainExchangeAbi } = useSmartContracts();

  /*
   * State
   */

  const [bidIdInput, setBidIdInput] = useState<bigint | null>(null);
  const [newPriceInput, setNewPriceInput] = useState<string>('');
  const [ethAmountInput, setEthAmountInput] = useState<string>('');

  const [shouldConfigureIncreaseBidPriceWrite, setShouldConfigureIncreaseBidPriceWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  const { config: writeIncreaseBidPriceConfig } = usePrepareContractWrite({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'increaseBidPrice',
    args: [
      bidIdInput,
      toBigIntEth(newPriceInput)
    ],
    value: ethAmountInput,
    onError(error: any) {
      console.log('writeIncreaseBidPriceConfig failed: ', error.message);

      setShouldConfigureIncreaseBidPriceWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureIncreaseBidPriceWrite,
  });

  const {
    data: submitIncreaseBidPriceResult,
    status: signIncreaseBidPriceTransactionStatus,
    writeAsync: writeIncreaseBidPriceAsync,
  } = useContractWrite(writeIncreaseBidPriceConfig);

  const {
    status: mineIncreaseBidPriceTransactionStatus,
  } = useWaitForTransaction({
    hash: submitIncreaseBidPriceResult ? submitIncreaseBidPriceResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeIncreaseBidPriceAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeIncreaseBidPriceAsync,
    bidIdInput,
    newPriceInput,
    ethAmountInput,
    setBidIdInput,
    setNewPriceInput,
    setEthAmountInput,
    setShouldConfigureIncreaseBidPriceWrite,
    shouldConfigureIncreaseBidPriceWrite,
    signIncreaseBidPriceTransactionStatus,
    mineIncreaseBidPriceTransactionStatus,
    transactionHash: submitIncreaseBidPriceResult?.hash,
  };
};
