import { Bid, BidState } from '@helpers/types/bid';
import { Listing } from '@helpers/types/listing';


export const getBidState = (bid: Bid, bidListing: Listing) => {
  const currentTimestamp = BigInt(Math.floor(Date.now() / 1000));
  let bidState = BidState.Active;
  if (!bidListing.isActive) {
    bidState = BidState.ListingExpired;
  } else {
    if (bid.refundInitiated) {
      bidState = currentTimestamp < bid.expiryTimestamp ? BidState.RefundInitiated : BidState.Expired;
    }
  }
  return bidState;
}

export const getBidStateString = (bidState: BidState) => {
  switch (bidState) {
    case BidState.Active:
      return 'Active';
    case BidState.RefundInitiated:
      return 'Refund Initiated';
    case BidState.Expired:
      return 'Bid Expired';
    case BidState.ListingExpired:
      return 'Listing Expired';
  }
}

export const getBidStateColor = (bidState: BidState) => {
  switch (bidState) {
    case BidState.Active:
      return 'green';

    case BidState.RefundInitiated:
      return 'orange';

    case BidState.Expired:
      return 'red';

    case BidState.ListingExpired:
      return 'red';
  }
}

export const getInstantAcceptString = (instantAccept: boolean) => {
  return instantAccept ? 'Enabled' : 'Disabled';
}

export const getInstantAcceptColor = (instantAccept: boolean) => {
  return instantAccept ? 'green' : 'red';
}
