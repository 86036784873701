/**
 * Add opacity information to a hex color
 * @param amount opacity value from 0 to 100
 * @param hexColor
 */
export function opacify(amount: number, hexColor: string): string {
  if (!hexColor.startsWith('#')) {
    return hexColor;
  };

  if (hexColor.length !== 7) {
    throw new Error(`opacify: provided color ${hexColor} was not in hexadecimal format (e.g. #000000)`);
  };

  if (amount < 0 || amount > 100) {
    throw new Error('opacify: provided amount should be between 0 and 100');
  };

  const opacityHex = Math.round((amount / 100) * 255).toString(16);
  const opacifySuffix = opacityHex.length < 2 ? `0${opacityHex}` : opacityHex;

  return `${hexColor.slice(0, 7)}${opacifySuffix}`;
};

export const colors = {
  linkBlue: '#0066CC',
  heartRed: '#DF2E2D',
  warningRed: '#DF2E2D',
  
  themeGray: '#1C0F0E',

  darkText: '#333333',
  grayText: '#525252',
  lightGrayText: '#F8F9FA',

  white: '#FFFFFF',
  black: '#000000',
  offWhite: '#9CA3AA',

  buttonDefault: '#FF5E14',
  buttonHover: '#FF7F50',  // A darker shade of the buttonDefault color
  buttonDisabled: '#FFB399', // A lighter shade of the buttonDefault color

  container: '#FFFFFF',

  environmentBannerColor: '#FF5E14',

  defaultBorderColor: '#C0C0C0',
  focusBorderColor: '#808080',
  readOnlyBorderColor: '#98A1C03D',

  inputDefaultColor: '#FAF9F6',
  inputReadOnlyValueColor: '#505458',
  inputPlaceholderColor: '#999999',

  selectorColor: '#FAF9F6',   // Light grayish color
  selectorHover: '#F2F1ED',   // Light grayish color (slightly darker)
  selectorHoverBorder: 'rgba(255, 255, 255, 0.1)',

  rowSelectedColor: '#FFE6CC',
  rowHoverColor: '#FFF5E6',

  orderSummaryGreen: '#4BB543',

  proofFoundGreen: '#4BB543',
  proofMissingRed: '#DF2E2D',

  defaultSVGIconBackgroundColor: '#FAF9F6',

  paginationDefault: 'rgba(0, 0, 0, 0.5)',
  paginationHover: 'rgba(0, 0, 0, 0.8)',
  paginationDisabled: 'rgba(0, 0, 0, 0.2)',

  iconButtonDefault: '#F8F9FA',
  iconButtonHover: '#FF7F50', // Light orange
  iconButtonActive: '#FF5E14',

  background: '#FAF9F6',
  // global color: #333333
  // box border: #C0C0C0
  // primary color: #0066CC
  // darker primary color: 
  // accent color: #32CD32
  // muted accent color: #D2EDD2

  //
  // Properly labeled colors
  //

  iconDefault: '#333333',
  accessoryButtonIconDefault: '#FF5E14',

  chevronDown: '#CED4DA',

  labelValue: '#333333',
  labelName: '#333333',

  logoutButtonDefaultRed: '#E96069',

  dropdownItemHover: '#6C757D',
  dropdownLogoutHover: '#CA2221',

  cardTitleText: '#FF5E14',
  cardCTAText: '#1F7A1F',

  selectedRowColor: '#F0F5FA',
};
