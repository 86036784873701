import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useBidWithdraw(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapDomainExchangeAddress, swapDomainExchangeAbi } = useSmartContracts();

  /*
   * State
   */

  const [bidIdInput, setBidIdInput] = useState<string | null>(null);

  const [shouldConfigureBidWithdraw, setShouldConfigureBidWithdraw] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  const { config: writeBidWithdrawConfig } = usePrepareContractWrite({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'withdrawBid',
    args: [
      bidIdInput,
    ],
    onError(error: any) {
      console.log('writeBidWithdrawConfig failed: ', error.message);

      setShouldConfigureBidWithdraw(false);

      onError?.(error);
    },
    enabled: shouldConfigureBidWithdraw,
  });

  const {
    data: submitBidWithdrawResult,
    status: signBidWithdrawTransactionStatus,
    writeAsync: writeBidWithdrawAsync,
  } = useContractWrite(writeBidWithdrawConfig);

  const {
    status: mineBidWithdrawTransactionStatus,
  } = useWaitForTransaction({
    hash: submitBidWithdrawResult ? submitBidWithdrawResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeBidWithdrawAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeBidWithdrawAsync,
    bidIdInput,
    shouldConfigureBidWithdraw,
    setBidIdInput,
    setShouldConfigureBidWithdraw,
    signBidWithdrawTransactionStatus,
    mineBidWithdrawTransactionStatus,
    transactionHash: submitBidWithdrawResult?.hash,
  };
};
