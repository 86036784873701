import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import styled from 'styled-components';
import Link from '@mui/material/Link';

import { esl, MIN_BID_PRICE_DEFAULT } from '@helpers/constants';
import { DomainsTable } from '@components/SellerListings/NewListing/DomainsTable';
import { Summary } from '@components/SellerListings/NewListing/Summary';
import { Button } from '@components/common/Button';
import { RowBetween } from '@components/layouts/Row';
import { ThemedText } from '@theme/text';
import { Input } from '@components/SellerListings/NewListing/Input';
import { LoginStatus, NewListingTransactionStatus } from '@helpers/types';
import { toBigIntEth, toEthString } from '@helpers/units';
import { commonStrings } from '@helpers/strings';
import { getPublicKeyFromAccount } from '@helpers/messagEncryption';
import { colors } from '@theme/colors';
import useAccount from '@hooks/contexts/useAccount';
import useBalances from '@hooks/contexts/useBalance';
import useSellers from '@hooks/contexts/useSellers';
import useCreateListingTransaction from '@hooks/transactions/useCreateListing';
import { SELLING_DOMAINS_DOCS_LINK } from '@helpers/docUrls';
import { subscribeToBidNotifications } from '@helpers/notificationService';


interface NewListingProps {
  handleVerifyDomainsPressed: () => void;
  handleBackClick: () => void;
}
 
export const NewListing: React.FC<NewListingProps> = ({
  handleVerifyDomainsPressed,
  handleBackClick
}) => {
  /*
   * Contexts
   */

  const { ethBalance } = useBalances();
  const { accountListingHash, createAccountListingHash, isLoggedIn, loggedInEthereumAddress, loginStatus } = useAccount();
  const { sellerDetailedListings, refetchSellerDetailedListings } = useSellers();

  /*
   * State
   */

  const [sellerExistingDomainIds, setSellerExistingDomainIds] = useState<string[]>([]);

  const [newListingState, setNewListingState] = useState(NewListingTransactionStatus.DEFAULT);

  const [notificationEmailInput, setNotificationEmailInput] = useState('');

  /*
   * Hooks
   */

  useEffect(() => {
    const storedEmail = localStorage.getItem('zkp2p_notification_email');
    if (storedEmail) {
      setNotificationEmailInput(storedEmail);
    }
  }, []);

  const onSuccessCallback = useCallback(async (data: any) => {
    console.log('createListing Succeeded: ', data);

    // Call the notification service
    if (notificationEmailInput && data) {
      await subscribeToBidNotifications(data, notificationEmailInput);
    }

    // reset input values
    setPriceInput('');
    setNotificationEmailInput('');

    // Has many dependencies, but we only need to refetch sellerDetailedListings
    refetchSellerDetailedListings?.();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchSellerDetailedListings, notificationEmailInput]);

  const {
    writeCreateListingAsync,
    domainIdInput,
    priceInput,
    setDomainIdInput,
    setPriceInput,
    setMinBidPriceInput,
    setSaleEthRecipientInput,
    setEncryptEmailKeyInput,
    setShouldConfigureCreateListingWrite,
    signCreateListingTransactionStatus,
    mineCreateListingTransactionStatus,
    // transactionHash,
  } = useCreateListingTransaction(onSuccessCallback);
  
  useEffect(() => {
    setMinBidPriceInput(MIN_BID_PRICE_DEFAULT);
  }, [setMinBidPriceInput]);

  useEffect(() => {
    if (isLoggedIn && loggedInEthereumAddress) {
      setSaleEthRecipientInput(loggedInEthereumAddress);
    }
  }, [isLoggedIn, loggedInEthereumAddress, setSaleEthRecipientInput]);

  useEffect(() => {
    if (!accountListingHash && isLoggedIn && createAccountListingHash) {
      createAccountListingHash();
    }

    if (accountListingHash && setEncryptEmailKeyInput) {
      const publicKey = getPublicKeyFromAccount(accountListingHash);
      
      setEncryptEmailKeyInput(publicKey);
    } else {
      setEncryptEmailKeyInput('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountListingHash, isLoggedIn]);

  useEffect(() => {
    esl && console.log('sellerDetailedListings: ', sellerDetailedListings);

    if (sellerDetailedListings) {
      esl && console.log('sellerDetailedListings_2');

      const domainIds = sellerDetailedListings.map(listing => listing.domainId);

      setSellerExistingDomainIds(domainIds);
    } else {
      setSellerExistingDomainIds([]);
    }
  }, [sellerDetailedListings]);

  useEffect(() => {
    const updateNewListingState = async () => {
      const successfulCreateListingTransaction = mineCreateListingTransactionStatus === 'success';

      if (successfulCreateListingTransaction) {
        setNewListingState(NewListingTransactionStatus.TRANSACTION_SUCCEEDED);
      } else {
        const domainsSelected = domainIdInput !== '';
        console.log('domainsSelected');
        if (domainsSelected) {
          const ethBalanceLoaded = ethBalance !== null;
          const validPriceInput = priceInput !== '' && priceInput !== '.' && priceInput !== '0.';
          console.log('priceInput: ', priceInput);
          console.log('validPriceInput: ', validPriceInput);

          if (validPriceInput && ethBalanceLoaded) {
            const isPriceGreaterThanPlatformMinBid = toBigIntEth(priceInput) > toBigIntEth(MIN_BID_PRICE_DEFAULT);
            console.log('isPriceGreaterThanPlatformMinBid: ', isPriceGreaterThanPlatformMinBid);

            if (isPriceGreaterThanPlatformMinBid) {
              const signingCreateListingTransaction = signCreateListingTransactionStatus === 'loading';
              const miningCreateListingTransaction = mineCreateListingTransactionStatus === 'loading';

              if (signingCreateListingTransaction) {
                setNewListingState(NewListingTransactionStatus.TRANSACTION_SIGNING);
              } else if (miningCreateListingTransaction){
                setNewListingState(NewListingTransactionStatus.TRANSACTION_MINING);
              } else {
                setNewListingState(NewListingTransactionStatus.VALID);
              }
            } else {
              setNewListingState(NewListingTransactionStatus.PRICE_LESS_THAN_MIN_BID);
            }
          } else {
            setNewListingState(NewListingTransactionStatus.MISSING_PRICE);
          }
        } else {
          setNewListingState(NewListingTransactionStatus.DEFAULT);
        }
      }
    }

    updateNewListingState();
  }, [
      priceInput,
      ethBalance,
      sellerExistingDomainIds,
      domainIdInput,
      signCreateListingTransactionStatus,
      mineCreateListingTransactionStatus
    ]
  );

  useEffect(() => {
    console.log('newListingState: ', newListingState);

    setShouldConfigureCreateListingWrite(newListingState === NewListingTransactionStatus.VALID);
  }, [newListingState, setShouldConfigureCreateListingWrite]);

  /*
   * Helpers
   */

  function isValidInput(value: string) {
    const isValid = /^-?\d*(\.\d{0,18})?$/.test(value);
    
    return parseFloat(value) >= 0 && isValid;
  }

  const ctaDisabled = (): boolean => {
    switch (newListingState) {
      case NewListingTransactionStatus.DEFAULT:
      case NewListingTransactionStatus.MISSING_PRICE:
      case NewListingTransactionStatus.TRANSACTION_SIGNING:
      case NewListingTransactionStatus.TRANSACTION_MINING:
      case NewListingTransactionStatus.PRICE_LESS_THAN_MIN_BID:
        return true;

      case NewListingTransactionStatus.VALID:
      default:
        return false;
    }
  }

  const ctaLoading = (): boolean => {
    switch (newListingState) {
      case NewListingTransactionStatus.TRANSACTION_SIGNING:
      case NewListingTransactionStatus.TRANSACTION_MINING:
        return loginStatus === LoginStatus.AUTHENTICATED;

      default:
        return false;
    }
  };

  const ctaText = (): string => {
    switch (newListingState) {
      case NewListingTransactionStatus.MISSING_PRICE:
        return 'Input ask price';

      case NewListingTransactionStatus.PRICE_LESS_THAN_MIN_BID:
        return `Ask price must be grater than ${MIN_BID_PRICE_DEFAULT} ETH`;

      case NewListingTransactionStatus.TRANSACTION_SIGNING:
        return 'Signing Transaction';

      case NewListingTransactionStatus.TRANSACTION_MINING:
        return 'Mining Transaction';

      case NewListingTransactionStatus.VALID:
        return 'Create Listing';

      case NewListingTransactionStatus.TRANSACTION_SUCCEEDED:
        return 'Go to Listings';

      case NewListingTransactionStatus.DEFAULT:
      default:
        return 'Select domain to list';
    }
  }

  const ctaOnClick = async () => {
    switch (newListingState) {
      case NewListingTransactionStatus.VALID:
        try {
          await writeCreateListingAsync?.();
        } catch (error) {
          console.log('writeCreateListingAsync failed: ', error);
        }
        break;

      case NewListingTransactionStatus.TRANSACTION_SUCCEEDED:
        handleBackClick();
        break;

      default:
        break;
    }
  }

  const ethBalanceLabel = useMemo(() => {
    if (isLoggedIn && ethBalance !== null) {
      const formattedEthBalanceText = toEthString(ethBalance, true, 4);
      
      return `Balance: ${formattedEthBalanceText} ETH`
    } else {
      return '';
    }
  }, [ethBalance, isLoggedIn]);

  /*
   * Handlers
   */

  const handleListingPriceChange = (rawListingPrice: string) => {
    if (rawListingPrice === "") {
      setPriceInput('');
    } else if (rawListingPrice === ".") {
      setPriceInput('0.');
    } else if (isValidInput(rawListingPrice)) {
      setPriceInput(rawListingPrice);
    }
  };

  const handleNotificationEmailChange = (email: string) => {
    setNotificationEmailInput(email);
    localStorage.setItem('zkp2p_notification_email', email);
  };

  return (
    <Container>
      <TitleContainer>
        <div style={{ flex: 0.5 }}>
          <button
            onClick={handleBackClick}
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            <StyledArrowLeft/>
          </button>
        </div>

        <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
          New Listing
        </ThemedText.HeadlineSmall>

        <div style={{ flex: 0.5 }}/>
      </TitleContainer>

      <Content>
        <InstructionContainer>
          { commonStrings.get('NEW_LISTING_INSTRUCTIONS') }
          <Link href={SELLING_DOMAINS_DOCS_LINK} target="_blank">
            Learn More ↗
          </Link>
        </InstructionContainer>

        <InputsContainer>
          <DomainsTable
            listedDomainIds={sellerExistingDomainIds}
            handleVerifyDomainsPressed={handleVerifyDomainsPressed}
            setSelectedDomainId={setDomainIdInput}
          />

          <Input
            label="Ask Price"
            name={`priceInput`}
            value={priceInput}
            onChange={(e) => handleListingPriceChange(e.currentTarget.value)}
            type="number"
            inputLabel="ETH"
            placeholder="1.00"
            accessoryLabel={ethBalanceLabel}
            helperText={commonStrings.get('NEW_LISTING_PRICE_TOOLTIP')}
          />

          <Input
            label="Notification Email (Optional)"
            name={`notificationEmailInput`}
            value={notificationEmailInput}
            onChange={(e) => handleNotificationEmailChange(e.currentTarget.value)}
            type="email"
            placeholder="your-email@example.com"
            helperText={commonStrings.get('NEW_LISTING_NOTIFICATION_EMAIL_TOOLTIP')}
          />

          <Summary
            isLoading={priceInput === '' || domainIdInput.length === 0}
            askingPrice={priceInput}
            listingFee={0.025} // 2.5%
          />

          <ButtonContainer>
            <Button
              fullWidth={true}
              disabled={ctaDisabled()}
              loading={ctaLoading()}
              onClick={async () => {
                ctaOnClick();
              }}
            >
              { ctaText() }
            </Button>
          </ButtonContainer>
        </InputsContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  background-color: ${colors.container};
  padding: 1.5rem;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);

  @media (min-width: 600px) {
    max-width: 552px;
    border-radius: 16px;
  }
`;

const TitleContainer = styled(RowBetween)`
  padding: 0.25rem 0rem 0.25rem 0rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InstructionContainer = styled.span`
  display: block;
  padding: 1rem 0.5rem 0rem 0.5rem; 
  color: #333;
  line-height: 1.4;
  font-size: 15px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ButtonContainer = styled.div`
  padding-top: 0.5rem;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: #333;
`;
