import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import { toBigIntEth } from '@helpers/units';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import { ethers } from 'ethers';


export default function useCreateListingTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapDomainExchangeAddress, swapDomainExchangeAbi } = useSmartContracts();

  /*
   * State
   */

  const [domainIdInput, setDomainIdInput] = useState<string>('');
  const [priceInput, setPriceInput] = useState<string>('');
  const [minBidPriceInput, setMinBidPriceInput] = useState<string>('');
  const [encryptEmailKeyInput, setEncryptEmailKeyInput] = useState<string>('');
  const [saleEthRecipientInput, setSaleEthRecipientInput] = useState<string>('');
  const [dkimKeyHashInput, setDkimKeyHashInput] = useState<string>(ethers.constants.HashZero.toString());

  const [shouldConfigureCreateListingWrite, setShouldConfigureCreateListingWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  const { config: writeCreateListingConfig } = usePrepareContractWrite({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'createListing',
    args: [
      domainIdInput,
      toBigIntEth(priceInput),
      toBigIntEth(minBidPriceInput),
      saleEthRecipientInput,
      '0x' + encryptEmailKeyInput,
      dkimKeyHashInput,
    ],
    onError(error: any) {
      console.log('writeCreateListingConfig failed: ', error.message);

      setShouldConfigureCreateListingWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureCreateListingWrite,
  });

  const {
    data: submitCreateListingResult,
    status: signCreateListingTransactionStatus,
    writeAsync: writeCreateListingAsync,
  } = useContractWrite(writeCreateListingConfig);

  const {
    status: mineCreateListingTransactionStatus,
  } = useWaitForTransaction({
    hash: submitCreateListingResult ? submitCreateListingResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeCreateListingAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeCreateListingAsync,
    domainIdInput,
    priceInput,
    minBidPriceInput,
    saleEthRecipientInput,
    encryptEmailKeyInput,
    dkimKeyHashInput,
    setDomainIdInput,
    setPriceInput,
    setMinBidPriceInput,
    setSaleEthRecipientInput,
    setEncryptEmailKeyInput,
    setDkimKeyHashInput,
    setShouldConfigureCreateListingWrite,
    shouldConfigureCreateListingWrite,
    signCreateListingTransactionStatus,
    mineCreateListingTransactionStatus,
    transactionHash: submitCreateListingResult?.hash,
  };
};
