import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import useMediaQuery from '@hooks/useMediaQuery';
import { colors } from '@theme/colors';
import { BidState } from '@helpers/types/bid';
import useBuyers from '@hooks/contexts/useBuyers';
import useBidInitiateRefund from '@hooks/transactions/useBidInitiateRefund';
import useBidWithdraw from '@hooks/transactions/useBidWithdraw';
import { MIN_BID_ACTIVE_PERIOD, MIN_BID_EXPIRY_PERIOD } from '@helpers/constants';
import { getBidStateColor, getBidStateString } from '@helpers/bidTableHelpers';
import { TransactionIconButton } from '@components/common/TransactionIconButton';


interface BidRowProps {
  listingId: string;
  bidId: string;
  domainName: string;
  bidPrice: string;
  createdAt: string;
  bidState: BidState;
  rowIndex: number;
  canInitiateRefund: boolean;
  onEditBid: () => void;
  isScrolling: boolean;
}

export const BidRow: React.FC<BidRowProps> = ({
  listingId,
  bidId,
  domainName,
  bidPrice,
  createdAt,
  bidState,
  rowIndex,
  canInitiateRefund,
  onEditBid,
  isScrolling
}) => {
  BidRow.displayName = "BidRow";

  const navigate = useNavigate();
  const isMobile = useMediaQuery() === 'mobile';

  /*
   * Contexts
   */

  const { refetchBuyerBids } = useBuyers();

  /*
   * Hooks
   */

  const onBidInitiateRefundSuccessCallback = useCallback((data: any) => {
    console.log('buyerBidsSucceeded: ', data);

    refetchBuyerBids?.();
  }, [refetchBuyerBids]);

  const {
    writeBidInitiateRefundAsync,
    shouldConfigureBidInitiateRefund,
    setBidIdInput: setBidInitiateRefundIdInput,
    setShouldConfigureBidInitiateRefund,
    signBidInitiateRefundTransactionStatus,
    mineBidInitiateRefundTransactionStatus,
  } = useBidInitiateRefund(onBidInitiateRefundSuccessCallback);

  const onBidWithdrawRefundSuccessCallback = useCallback((data: any) => {
    console.log('buyerBidsSucceeded: ', data);

    refetchBuyerBids?.();
  }, [refetchBuyerBids]);

  const {
    writeBidWithdrawAsync,
    shouldConfigureBidWithdraw,
    setBidIdInput: setBidWithdrawIdInput,
    setShouldConfigureBidWithdraw,
    signBidWithdrawTransactionStatus,
    mineBidWithdrawTransactionStatus,
  } = useBidWithdraw(onBidWithdrawRefundSuccessCallback);

  useEffect(() => {
    const submitBidInitiateRefund = async () => {
      if (shouldConfigureBidInitiateRefund) {
        try {
          await writeBidInitiateRefundAsync?.();
        } catch (error) {
          console.log('writeBidInitiateRefundAsync failed: ', error);

          setShouldConfigureBidInitiateRefund(false);

          setBidInitiateRefundIdInput(null);
        }
      };
    };

    submitBidInitiateRefund();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldConfigureBidInitiateRefund, writeBidInitiateRefundAsync]);

  useEffect(() => {
    const submitBidWithdraw = async () => {
      if (shouldConfigureBidWithdraw) {
        try {
          await writeBidWithdrawAsync?.();
        } catch (error) {
          console.log('writeBidWithdrawAsync failed: ', error);

          setShouldConfigureBidWithdraw(false);

          setBidWithdrawIdInput(null);
        }
      };
    };

    submitBidWithdraw();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldConfigureBidWithdraw, writeBidWithdrawAsync]);


  /*
   * Handlers
   */

  const handleDomainClick = () => {
    navigate(`/listing/${listingId}`);
  };

  const handleInitiateRefundPressed = () => {
    setBidInitiateRefundIdInput(bidId);

    setShouldConfigureBidInitiateRefund(true);
  };

  const handleWithdrawBidPressed = () => {
    setBidWithdrawIdInput(bidId);

    setShouldConfigureBidWithdraw(true);
  };

  /*
   * Helpers
   */

  const toHours = (seconds: bigint) => {
    return seconds / BigInt(3600);
  }

  const isBidInitiateRefundLoading = (): boolean => {
    const isSigningTransctionMining = signBidInitiateRefundTransactionStatus === 'loading';
    const isMiningTransaction = mineBidInitiateRefundTransactionStatus === 'loading';

    return isSigningTransctionMining || isMiningTransaction;
  };

  const isBidWithdrawLoading = (): boolean => {
    const isSigningTransctionMining = signBidWithdrawTransactionStatus === 'loading';
    const isMiningTransaction = mineBidWithdrawTransactionStatus === 'loading';

    return isSigningTransctionMining || isMiningTransaction;
  };

  return (
    <Container isScrolling={isScrolling}>
      <BidDetailsContainer isMobile={isMobile}>
        <SummaryLabel>
          <SummaryLabelTitle>{rowIndex + 1}</SummaryLabelTitle>
        </SummaryLabel>

        <DetailsContainer>
          <SummaryLabelValue style={{ color: getBidStateColor(bidState) }}>
            {getBidStateString(bidState)}
          </SummaryLabelValue>
        </DetailsContainer>

        <DetailsContainer>
          <DomainLabelValue onClick={handleDomainClick}>{domainName}</DomainLabelValue>
        </DetailsContainer>

        <DetailsContainer>
          <SummaryLabelValue>{bidPrice}</SummaryLabelValue>
        </DetailsContainer>

        <DetailsContainer>
          <SummaryLabelValue>{createdAt}</SummaryLabelValue>
        </DetailsContainer>

        <ActionsContainer>
          {bidState === BidState.Active && (
            <>
              <TransactionIconButton
                icon="edit"
                onClick={onEditBid}
                disabled={false}
                loading={false}
                text="Edit Bid"
              />

              <TransactionIconButton
                icon="ccw"
                onClick={() => {
                  if (canInitiateRefund) {
                    handleInitiateRefundPressed();
                  }
                }}
                disabled={!canInitiateRefund}
                loading={isBidInitiateRefundLoading()}
                text={
                  canInitiateRefund 
                  ? "Initiate Refund" 
                  : `Initiate Refund after ${toHours(MIN_BID_ACTIVE_PERIOD)} hours of placing bid`
                }
              />
            </>
          )}

          {bidState === BidState.RefundInitiated && (
            <>
              <TransactionIconButton
                icon="edit"
                onClick={() => {}}
                disabled={true}
                loading={false}
                text="Edit Bid"
              />

              <TransactionIconButton
                icon="ccw"
                onClick={() => {}}
                disabled={true}
                loading={isBidWithdrawLoading()}
                text={ 
                  `Withdraw Bid after ${toHours(MIN_BID_EXPIRY_PERIOD)} hours of initiating refund`
                }
              />
            </>
          )}

          {(bidState === BidState.Expired || bidState === BidState.ListingExpired) && (
            <TransactionIconButton
              icon="dollarSign"
              onClick={handleWithdrawBidPressed}
              disabled={false}
              loading={isBidWithdrawLoading()}
              text="Withdraw Bid"
            />
          )}
        </ActionsContainer>
      </BidDetailsContainer>
    </Container>
  );
};

const Container = styled.div<{isScrolling: boolean}>`
  display: flex;

  ${({ isScrolling }) => isScrolling && css`
    padding-right: 0.5rem;
    transition: padding-right 0.3s ease;
  `}

  ${({ isScrolling }) => !isScrolling && css`
    padding-right: 0rem;
    transition: padding-right 0.3s ease;
  `}
`;

const BidDetailsContainer = styled.div<{isMobile?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 2fr 3fr 1.5fr;
  padding: 0rem 1.5rem;
  line-height: 24px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0rem;
`;

const SummaryLabel = styled.label`
  font-size: 15px;
  color: #FFFFFF;
  padding: 1rem 0rem;
`;

const SummaryLabelTitle = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

const DomainLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.linkBlue};
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SummaryLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.75rem 0rem;
`;