import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';

import { DetailsItem } from '@components/Listing/DetailsItem';
import { colors } from '@theme/colors';


interface DetailsProps {
  isLoading: boolean;
  expiresAtText: string;
  createdAtText: string;
  askPriceText: string | undefined;
};

export const Details: React.FC<DetailsProps> = ({
  isLoading,
  expiresAtText,
  createdAtText,
  askPriceText,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  /*
   * State
   */

  const [isOpen, setIsOpen] = useState(true);

  /*
   * Hooks
   */

  useEffect(() => {
    if (isLoading) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [isLoading]);

  /*
   * Component
   */

  return (
    <Wrapper ref={ref}>
      <TitleLabelAndDropdownIconContainer>
        <TitleLabel>
          {"Listing Details"}
        </TitleLabel>

        <TitleValueAndChevronContainer>
          <TitleValue>
            
          </TitleValue>  

          <StyledChevronDown
            onClick={() => setIsOpen(!isOpen)}
            $isOpen={isOpen}
          />
        </TitleValueAndChevronContainer>
      </TitleLabelAndDropdownIconContainer>

      <DetailsDropdown $isOpen={isOpen}>
        <HorizontalDivider/>

        <RequirementListContainer>
          {askPriceText && (
            <DetailsItem 
              label={"Ask price"}
              value={askPriceText}
            />
          )}

          <DetailsItem 
            label={"Listing created on"}
            value={createdAtText}
          />

          <DetailsItem
            label={"Domain expires on"}
            value={expiresAtText}
          />
        </RequirementListContainer>
      </DetailsDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  border-radius: 16px;
  background: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`;

const TitleLabelAndDropdownIconContainer = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px 10px;
`;

const TitleLabel = styled.div`
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

const TitleValueAndChevronContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  gap: 8px;
`;

const TitleValue = styled.div`
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

interface StyledChevronDownProps {
  $isOpen?: boolean;
};

const StyledChevronDown = styled(ChevronDown)<StyledChevronDownProps>`
  width: 20px;
  height: 20px;
  color: ${colors.chevronDown};

  transition: transform 0.4s;
  transform: ${({ $isOpen }) => $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const HorizontalDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.defaultBorderColor};
`;

interface DetailsDropdownProps {
  $isOpen?: boolean;
};

const DetailsDropdown = styled.div<DetailsDropdownProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.container};
  color: ${colors.darkText};
  align-items: center;
  gap: 16px;
  overflow: hidden;

  max-height: ${({ $isOpen }) => $isOpen ? '500px' : '0px'};
  transition: max-height 0.4s ease-out;
`;

const RequirementListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
`;
