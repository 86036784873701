import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useBidInitiateRefund(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapDomainExchangeAddress, swapDomainExchangeAbi } = useSmartContracts();

  /*
   * State
   */

  const [bidIdInput, setBidIdInput] = useState<string | null>(null);

  const [shouldConfigureBidInitiateRefund, setShouldConfigureBidInitiateRefund] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  const { config: writeBidInitiateRefundConfig } = usePrepareContractWrite({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'initiateRefund',
    args: [
      bidIdInput,
    ],
    onError(error: any) {
      console.log('writeBidInitiateRefundConfig failed: ', error.message);

      setShouldConfigureBidInitiateRefund(false);

      onError?.(error);
    },
    enabled: shouldConfigureBidInitiateRefund,
  });

  const {
    data: submitBidInitiateRefundResult,
    status: signBidInitiateRefundTransactionStatus,
    writeAsync: writeBidInitiateRefundAsync,
  } = useContractWrite(writeBidInitiateRefundConfig);

  const {
    status: mineBidInitiateRefundTransactionStatus,
  } = useWaitForTransaction({
    hash: submitBidInitiateRefundResult ? submitBidInitiateRefundResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeBidInitiateRefundAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeBidInitiateRefundAsync,
    bidIdInput,
    shouldConfigureBidInitiateRefund,
    setBidIdInput,
    setShouldConfigureBidInitiateRefund,
    signBidInitiateRefundTransactionStatus,
    mineBidInitiateRefundTransactionStatus,
    transactionHash: submitBidInitiateRefundResult?.hash,
  };
};
