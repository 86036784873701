import { createContext } from 'react';

import { ExtensionNotaryProofRequest } from '@hooks/useBrowserExtension';


interface ExtensionProxyProofsValues {
  isSidebarInstalled: boolean;
  sideBarVersion: string | null;
  refetchExtensionVersion: () => void;

  openSidebarVerifyDomains: () => void;
  archiveNotarizationInSidebar: (notarizationId: string) => void;
  refetchVerifiedDomainProofs: () => void;
  verifiedDomainProofs: ExtensionNotaryProofRequest[] | null;
};

const defaultValues: ExtensionProxyProofsValues = {
  isSidebarInstalled: false,
  sideBarVersion: null,
  refetchExtensionVersion: () => { },

  openSidebarVerifyDomains: () => { },
  archiveNotarizationInSidebar: (_notarizationId: string) => { },
  refetchVerifiedDomainProofs: () => { },
  verifiedDomainProofs: null,
};

const ExtensionNotarizationsContext = createContext<ExtensionProxyProofsValues>(defaultValues);

export default ExtensionNotarizationsContext;
