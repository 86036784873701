import React, { Suspense } from 'react';
import styled from 'styled-components/macro';
import QRCode from 'react-qr-code';
import { X } from 'react-feather';
import { ENSName, AddressDisplayEnum } from 'react-ens-name';

import { Overlay } from '@components/modals/Overlay';
import { Button } from '@components/common/Button';
import { CopyButton } from '@components/common/CopyButton';
import { ThemedText } from '@theme/text'
import { colors } from '@theme/colors';
import useAccount from '@hooks/contexts/useAccount';
import useModal from '@hooks/useModal';
import { commonStrings } from '@helpers/strings';
import { alchemyMainnetEthersProvider } from 'index';
import { Z_INDEX } from '@theme/zIndex';
import useMediaQuery from '@hooks/useMediaQuery';

import baseSvg from '@assets/images/base.svg';
import sepoliaSvg from '@assets/images/sepolia.svg';


export default function ReceiveModal() {
  /*
   * Contexts
   */

  const { closeModal } = useModal();
  const { loggedInEthereumAddress, network } = useAccount();
  const isMobile = useMediaQuery() === 'mobile';

  /*
   * Handlers
   */

  const handleCloseModal = () => {
    closeModal();
  };

  const handleGoToZkp2p = () => {
    if (!loggedInEthereumAddress) {
      return;
    }

    const appId = 'ZKP2P Domains';
    const platform= 'venmo';
    const network = 'base';
    const currencyIndex = 0;
    const toToken = 'ETH';
    const linkToOpen = `https://ramp.zkp2p.xyz/swap?appId=${appId}&recipientAddress=${loggedInEthereumAddress}&platform=${platform}&currencyIndex=${currencyIndex}&network=${network}&toToken=${toToken}`;

    window.open(linkToOpen, '_blank');
  };

  /*
   * Helpers
   */


  const networkName = (): string => {
    if (network === 'sepolia') {
      return 'Sepolia';
    } else {
      return 'Base';
    }
  };

  const networkSvg = (): string => {
    if (network === 'sepolia') {
      return sepoliaSvg;
    } else {
      return baseSvg;
    }
  };

  /*
   * Component
   */

  return (
    <ModalAndOverlayContainer>
      <Overlay onClick={handleCloseModal} />

      <Suspense>
        <ModalContainer>
          <TitleCenteredRow>
            <div style={{ flex: 0.25 }}>
              <button
                onClick={handleCloseModal}
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                >

                <StyledX/>
              </button>
            </div>

            <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
              {'Fund Account'}
            </ThemedText.HeadlineSmall>

            <div style={{ flex: 0.25 }}/>
          </TitleCenteredRow>

          {!isMobile && (
            <QRContainer>
              <QRCode
                value={`ethereum:${loggedInEthereumAddress}`}
                size={192}
              />
            </QRContainer>
          )}

          <AccountAddressContainer>
            <AddressAndEnsNameContainer>
              <ThemedText.LabelSmall>
                {networkName()} Address (Ethereum)
              </ThemedText.LabelSmall>

              <AddressLabel>
                <ENSName
                  provider={alchemyMainnetEthersProvider}
                  address={loggedInEthereumAddress || ''}
                  displayType={AddressDisplayEnum.FULL}
                />
              </AddressLabel>
            </AddressAndEnsNameContainer>

            <CopyButton
              textToCopy={loggedInEthereumAddress || ''}
            />
          </AccountAddressContainer>

          <InstructionsAndRampContainer>
            <InstructionsContainer>
              <NetworkSvg src={networkSvg()} />

              <InstructionsTextContainer>
                <InstructionsTitle>
                  {'Warning'}
                </InstructionsTitle>

                <InstructionsLabel>
                  { commonStrings.get('RECEIVE_FUNDS_INSTRUCTIONS_1') }
                  <>
                    Native ETH
                  </> from {networkName()}.
                  { commonStrings.get('RECEIVE_FUNDS_INSTRUCTIONS_2') }
                </InstructionsLabel>
              </InstructionsTextContainer>
            </InstructionsContainer>
            
            <RampInstructionsAndButtonContainer>
              <RampInstructionsContainer>
                <NetworkSvg src={`${process.env.PUBLIC_URL}/logo512.png`} alt="logo" />

                <InstructionsTextContainer>
                  <RampTitle>
                    {'Get Funds'}
                  </RampTitle>

                  <InstructionsLabel>
                    { commonStrings.get('RECEIVE_FUNDS_ONRAMP_INSTRUCTIONS') }
                  </InstructionsLabel>
                </InstructionsTextContainer>

              </RampInstructionsContainer>

              <Button
                onClick={handleGoToZkp2p}
                width={164}
                height={44}
                fontSize={isMobile ? 13 : 15}
              >
                Open Ramp ↗
              </Button>
            </RampInstructionsAndButtonContainer>
          </InstructionsAndRampContainer>
        </ModalContainer>
      </Suspense>
    </ModalAndOverlayContainer>
  );
}

const ModalAndOverlayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.overlay};
`;

const ModalContainer = styled.div`
  width: 80vw;
  max-width: 440px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.25rem;
  background-color: ${colors.container};
  justify-content: space-between;
  align-items: center;
  z-index: ${Z_INDEX.receive_modal};
  gap: 1.5rem;
  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TitleCenteredRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  color: ${colors.darkText};
`;

const StyledX = styled(X)`
  color: ${colors.iconDefault};
`;

const QRContainer = styled.div`
  padding: 1.25rem 1.25rem 1rem 1.25rem;
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 16px;
  background: ${colors.container};
`;

const AccountAddressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 1.5rem;
  text-align: left;
  color: ${colors.darkText};
`;

const AddressAndEnsNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1rem;
  color: ${colors.grayText};
`;

const AddressLabel = styled.div`
  max-width: calc(100% - 32px);
  word-break: break-all;
  line-height: 1.4;
`;

const InstructionsAndRampContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const NetworkSvg = styled.img`
  width: 32px;
  height: 32px;
`;

const InstructionsContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 16px;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 1.5rem;
`;

const RampInstructionsAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 16px;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
`;

const RampInstructionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const InstructionsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: ${colors.darkText};
`;

const InstructionsTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.warningRed};

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const InstructionsLabel = styled.span`
  font-size: 15px;
  line-height: 1.5;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 13px;
  }

`;

const RampTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.darkText};

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
