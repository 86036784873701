import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import { toBigIntEth } from '@helpers/units';
import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useCreateBidTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapDomainExchangeAddress, swapDomainExchangeAbi } = useSmartContracts();

  /*
   * State
   */

  const [listingIdInput, setListingIdInput] = useState<bigint | null>(null);
  const [maxPriceInput, setMaxPriceInput] = useState<string>('');
  const [buyerIdHashInput, setBuyerIdHashInput] = useState<string>('');
  const [encryptedBuyerIdInput, setEncryptedBuyerIdInput] = useState<string>('');

  const [shouldConfigureCreateBidWrite, setShouldConfigureCreateBidWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  // function createBid(uint256 _listingId, bytes32 _buyerIdHash, string memory _encryptedBuyerId) 

  const { config: writeCreateBidConfig } = usePrepareContractWrite({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'createBid',
    args: [
      listingIdInput,
      buyerIdHashInput,
      encryptedBuyerIdInput
    ],
    value: toBigIntEth(maxPriceInput),
    onError(error: any) {
      console.log('writeCreateBidConfig failed: ', error.message);

      setShouldConfigureCreateBidWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureCreateBidWrite,
  });

  const {
    data: submitCreateBidResult,
    status: signCreateBidTransactionStatus,
    writeAsync: writeCreateBidAsync,
  } = useContractWrite(writeCreateBidConfig);

  const {
    status: mineCreateBidTransactionStatus,
  } = useWaitForTransaction({
    hash: submitCreateBidResult ? submitCreateBidResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeCreateBidAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeCreateBidAsync,
    listingIdInput,
    maxPriceInput,
    buyerIdHashInput,
    encryptedBuyerIdInput,
    setListingIdInput,
    setMaxPriceInput,
    setBuyerIdHashInput,
    setEncryptedBuyerIdInput,
    setShouldConfigureCreateBidWrite,
    shouldConfigureCreateBidWrite,
    signCreateBidTransactionStatus,
    mineCreateBidTransactionStatus,
    transactionHash: submitCreateBidResult?.hash,
  };
};
