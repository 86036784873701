import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Zap, ZapOff } from 'react-feather';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { Button } from '@components/common/Button';
import { LoginStatus } from '@helpers/types/loginStatus';
import { InstantAcceptTxStatus } from '@helpers/types/instantAcceptStatus';
import useAccount from '@hooks/contexts/useAccount';
import useBuyers from '@hooks/contexts/useBuyers';
import useEnableInstantAcceptTransaction from '@hooks/transactions/useEnableInstantAccept';
import useDisableInstantAcceptTransaction from '@hooks/transactions/useDisableInstantAccept';


interface InstantAcceptProps {
  onBackClick: () => void;
}

export const InstantAccept: React.FC<InstantAcceptProps> = ({
  onBackClick,
}) => {

  /*
   * Contexts
   */

  const { isLoggedIn, loginStatus } = useAccount();
  const { buyerInstantAcceptEnabled, refetchBuyerInstantAcceptEnabled } = useBuyers();

  /*
   * State
   */

  const [txStatus, setTxStatus] = useState<InstantAcceptTxStatus>(InstantAcceptTxStatus.DEFAULT);

  /*
   * Hooks
   */

  const onInstantAcceptSuccessCallback = useCallback((data: any) => {
    console.log('instantAcceptSucceeded: ', data);

    refetchBuyerInstantAcceptEnabled?.();
  }, [refetchBuyerInstantAcceptEnabled]);


  const {
    writeEnableInstantAcceptAsync,
    setShouldConfigureEnableInstantAcceptWrite,
    signEnableInstantAcceptTxStatus,
    mineEnableInstantAcceptTxStatus,
  } = useEnableInstantAcceptTransaction(onInstantAcceptSuccessCallback);

  const {
    writeDisableInstantAcceptAsync,
    setShouldConfigureDisableInstantAcceptWrite,
    signDisableInstantAcceptTxStatus,
    mineDisableInstantAcceptTxStatus,
  } = useDisableInstantAcceptTransaction(onInstantAcceptSuccessCallback);

  useEffect(() => {
    const successfulCreateListingTx = mineEnableInstantAcceptTxStatus === 'success' || mineDisableInstantAcceptTxStatus === 'success';

    if (successfulCreateListingTx) {
      setTxStatus(InstantAcceptTxStatus.TRANSACTION_SUCCEEDED);
    } else {
      if (
        signEnableInstantAcceptTxStatus === 'loading' ||
        signDisableInstantAcceptTxStatus === 'loading'
      ) {
        setTxStatus(InstantAcceptTxStatus.TRANSACTION_SIGNING);
      } else if (
        mineEnableInstantAcceptTxStatus === 'loading' ||
        mineDisableInstantAcceptTxStatus === 'loading'
      ) {
        setTxStatus(InstantAcceptTxStatus.TRANSACTION_MINING);
      } else {
        setTxStatus(InstantAcceptTxStatus.VALID);
      }
    }
  }, [
    isLoggedIn,
    signEnableInstantAcceptTxStatus,
    mineEnableInstantAcceptTxStatus,
    signDisableInstantAcceptTxStatus,
    mineDisableInstantAcceptTxStatus,
  ]);
  
  useEffect(() => {
    if (buyerInstantAcceptEnabled) {
      setShouldConfigureDisableInstantAcceptWrite(txStatus === InstantAcceptTxStatus.VALID);
    } else {
      setShouldConfigureEnableInstantAcceptWrite(txStatus === InstantAcceptTxStatus.VALID);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txStatus, buyerInstantAcceptEnabled]);

  /*
   * Handlers
   */

  const ctaOnClick = async () => {
    switch (txStatus) {
      case InstantAcceptTxStatus.VALID:
        try {
          if (buyerInstantAcceptEnabled) {
            await writeDisableInstantAcceptAsync?.();
          } else {
            await writeEnableInstantAcceptAsync?.();
          }
        } catch (error) {
          console.log('writeEnableOrDisableInstantAcceptAsync failed: ', error);
        }
        break;

      case InstantAcceptTxStatus.TRANSACTION_SIGNING:
      case InstantAcceptTxStatus.TRANSACTION_MINING:
        return;

      case InstantAcceptTxStatus.TRANSACTION_SUCCEEDED:
        onBackClick();
        break;

      default:
        return;
    }
  }

  /*
   * Helpers
   */

  const ctaDisabled = (): boolean => {
    switch (txStatus) {
      case InstantAcceptTxStatus.TRANSACTION_SIGNING:
      case InstantAcceptTxStatus.TRANSACTION_MINING:
        return true;
          
      case InstantAcceptTxStatus.DEFAULT:
      case InstantAcceptTxStatus.VALID:
      default:
        return false;
    }
  }
  
  const ctaLoading = (): boolean => {
    switch (txStatus) {
      case InstantAcceptTxStatus.TRANSACTION_SIGNING:
      case InstantAcceptTxStatus.TRANSACTION_MINING:
        return loginStatus === LoginStatus.AUTHENTICATED;

      default:
        return false;
    }
  }

  const ctaText = (): string => {
    switch (txStatus) {
      case InstantAcceptTxStatus.TRANSACTION_SIGNING:
        return 'Signing Transaction';

      case InstantAcceptTxStatus.TRANSACTION_MINING:
        return 'Mining Transaction';

      case InstantAcceptTxStatus.TRANSACTION_SUCCEEDED:
        return 'Go back';
      
      case InstantAcceptTxStatus.VALID:
      case InstantAcceptTxStatus.DEFAULT:
      default:
        if (buyerInstantAcceptEnabled) {
          return 'Disable Instant Accept';
        } else {
          return 'Enable Instant Accept';
        }
    }
  }
  /*
   * Component
   */

  return (
    <Container>
      <Content>
        <TitleAndStatusContainer>
          <ThemedText.HeadlineSmall>
            Status
          </ThemedText.HeadlineSmall>        
          
          <StatusContainer>
            {buyerInstantAcceptEnabled ? (
              <>
                <StyledZap />

                <StatusLabel>
                  Enabled (Recommended)
                </StatusLabel>
              </>
            ) : (
              <>
                <StyledZapOff />

                <StatusLabel>
                  Disabled (Not Recommended)
                </StatusLabel>
              </>
            )}
          </StatusContainer>
        </TitleAndStatusContainer>

        {isLoggedIn && (
          <ButtonContainer>
            <Button 
              onClick={ctaOnClick}
              disabled={ctaDisabled()}
              loading={ctaLoading()}
              fullWidth={true}
            > 
              {ctaText()}
            </Button>
          </ButtonContainer>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  gap: 2rem;
`;

const Content = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  padding: 2rem;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 600px) {
    border-radius: 16px;
  }
`;

const TitleAndStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  border: 1px solid;
  border-color: #C0C0C0;
  background-color: #FFF;
  border-radius: 8px;
  padding: 16px;
  align-items: center;
  background-color: ${colors.inputDefaultColor};
`;

const StyledZap = styled(Zap)`
  color: ${colors.buttonDefault};
  size: 20px;
`;

const StyledZapOff = styled(ZapOff)`
  color: ${colors.buttonDefault};
  size: 20px;
`;

const StatusLabel = styled.span`
  padding-top: 2px;
  font-size: 16px;
  color: ${colors.darkText};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
