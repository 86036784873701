import React, { useEffect, useState } from 'react';
import { Inbox } from 'react-feather';
import styled, { css } from 'styled-components/macro';

import { Button } from '@components/common/Button';
import { RowBetween } from '@components/layouts/Row';
import { CustomConnectButton } from '@components/common/ConnectButton';
import { UpdateListingModal } from '@components/SellerListings/Seller/UpdateListingModal';
import { ListingsRow } from '@components/SellerListings/Seller/ListingsRow'; 
import { DetailedListing } from '@helpers/types';
import { toEthStringWithDecimals } from '@helpers/units';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useAccount from '@hooks/contexts/useAccount';
import useSellers from '@hooks/contexts/useSellers';
import useTableScroll from '@hooks/useTableScroll';


export interface ListingRowData {
  listingId: string;
  domainName: string;
  numBids: string;
  priceText: string;
  highestBid: string; // Added highestBid prop
}

interface ListingsTableProps {
  handleCreateListingPressed: () => void;
}

export const ListingsTable: React.FC<ListingsTableProps> = ({
  handleCreateListingPressed,
}) => {
  /*
   * Contexts
   */

  const { isLoggedIn } = useAccount();
  const { sellerDetailedListings } = useSellers();

  /*
   * State
   */

  const [listingRowData, setListingRowData] = useState<ListingRowData[]>([]);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);

  const [selectedListingToEdit, setSelectedListingToEdit] = useState<DetailedListing | null>(null);
  const [showUpdateListingModal, setShowUpdateListingModal] = useState<boolean>(false);

  /*
   * Hooks
   */

  const { tableRef, isScrolling } = useTableScroll();
  
  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      setIsScrollEnabled(tableElement.scrollHeight > tableElement.clientHeight);
    }
  }, [listingRowData, tableRef]);
  
  
  useEffect(() => {
    if (sellerDetailedListings && sellerDetailedListings.length > 0) {
      var listingRowsData: ListingRowData[] = [];
      listingRowsData = sellerDetailedListings.map((detailedListing: DetailedListing, index: number) => {
        
        const domainName = detailedListing.domain.name;
        let numBids = 0;
        let highestBid: bigint = BigInt(0);

        if (detailedListing.bids && detailedListing.bids.length > 0) {
          const currentTime = Math.floor(Date.now() / 1000);
          const validBids = detailedListing.bids.filter(bid => bid.expiryTimestamp > currentTime);
          numBids = validBids.length;
          for (const bid of validBids) {
            if (bid.price > highestBid) {
              highestBid = bid.price;
            }
          }
        }
        const priceText = `${toEthStringWithDecimals(detailedListing.price, true, 4)} ETH`;

        return {
          listingId: detailedListing.listingId.toString(),
          domainName,
          numBids: numBids.toString(),
          priceText,
          highestBid: `${toEthStringWithDecimals(highestBid, true, 4)} ETH`,
        };
      });

      setListingRowData(listingRowsData);
    } else {
      setListingRowData([]);
    }
  }, [sellerDetailedListings]);


  /*
   * Handlers
   */

  const handleEditListingClicked = (listingRowIndex: number) => {
    if (sellerDetailedListings) {
      const selectedListingId = listingRowData[listingRowIndex].listingId;
      const listing = sellerDetailedListings.find(listing => listing.listingId.toString() === selectedListingId);

      if (listing) {
        setSelectedListingToEdit(listing);
    
        setShowUpdateListingModal(true);
      } else {
        setShowUpdateListingModal(false);

        setSelectedListingToEdit(null);
      }
    } else {
      setShowUpdateListingModal(false);

      setSelectedListingToEdit(null);
    }
  };

  /*
   * Component
   */

  return (
    <Container>
      {showUpdateListingModal && (
        (selectedListingToEdit) ? ( 
          <UpdateListingModal
            listing={selectedListingToEdit}
            onBackClick={() => setShowUpdateListingModal(false)}
          />
        ) : (
          null
        )
      )}

      <div>
        <TitleRow>
          <ThemedText.HeadlineMedium>
            My Listings
          </ThemedText.HeadlineMedium>
          {isLoggedIn ? (
            <Button onClick={handleCreateListingPressed} height={40}>
                + Create Listing
            </Button>
          ) : null}
        </TitleRow>

        <Content>
          {!isLoggedIn ? (
            <ErrorContainer>
              <ThemedText.DeprecatedBody textAlign="center">
                <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                <div>
                  Your active listings will appear here.
                </div>
              </ThemedText.DeprecatedBody>
              <CustomConnectButton width={152} />
            </ErrorContainer>
          ) : listingRowData.length === 0 ? (
              <ErrorContainer>
                <ThemedText.DeprecatedBody textAlign="center">
                  <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                  <div>
                    You have no active listings.
                  </div>
                </ThemedText.DeprecatedBody>
              </ErrorContainer>
          ) : (
            <>
              <TableHeaderRow isScrollEnabled={isScrollEnabled}>
                <ColumnHeader>#</ColumnHeader>

                <ColumnHeader>Domain</ColumnHeader>

                <ColumnHeader>Active Bids</ColumnHeader>

                <ColumnHeader>Ask Price</ColumnHeader>

                <ColumnHeader>Highest Bid</ColumnHeader>

                <ColumnHeader>Actions</ColumnHeader>
              </TableHeaderRow>

              <Table ref={tableRef}>
                {listingRowData.map((listingRowData, rowIndex) => (
                  <StyledListingRow key={rowIndex}>
                    <ListingsRow
                      listingId={listingRowData.listingId}
                      domainName={listingRowData.domainName}
                      numBids={listingRowData.numBids}
                      priceText={listingRowData.priceText}
                      rowIndex={rowIndex}
                      highestBid={listingRowData.highestBid}
                      handleEditListingPressed={() => handleEditListingClicked(rowIndex)}
                      isScrolling={isScrolling}
                    />
                  </StyledListingRow>
                ))}
              </Table>
            </>
          )}
        </Content>
      </div>
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: .5rem 1rem 1.75rem 1rem;
  height: 76px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  };
`;

const Content = styled.div`
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    border-radius: 16px;
  }
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const TableHeaderRow = styled.div<{ isScrollEnabled: boolean }>`
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 1fr;
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  text-align: left;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
  padding-right: ${({ isScrollEnabled }) => isScrollEnabled ? 'calc(1.5rem + 8px)' : '1.5rem'};
`;

const ColumnHeader = styled.div`
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;
  max-height: 670px;
  overflow-y: auto;
  scrollbar-width: thin;
  
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.defaultBorderColor};
    border-radius: 4px;
  }


  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledListingRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;