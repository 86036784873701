import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import { toBigIntEth } from '@helpers/units';
import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useUpdateListingTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapDomainExchangeAddress, swapDomainExchangeAbi } = useSmartContracts();

  /*
   * State
   */

  const [listingIdInput, setListingIdInput] = useState<bigint | null>(null);
  const [priceInput, setPriceInput] = useState<string>('');
  const [saleEthRecipientAddressInput, setSaleEthRecipientAddressInput] = useState<string>('');

  const [shouldConfigureUpdateListingWrite, setShouldConfigureUpdateListingWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  const { config: writeUpdateListingConfig } = usePrepareContractWrite({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'updateListing',
    args: [
      listingIdInput,
      toBigIntEth(priceInput),
      saleEthRecipientAddressInput
    ],
    onError(error: any) {
      console.log('writeUpdateListingConfig failed: ', error.message);

      setShouldConfigureUpdateListingWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureUpdateListingWrite,
  });

  const {
    data: submitUpdateListingResult,
    status: signUpdateListingTransactionStatus,
    writeAsync: writeUpdateListingAsync,
  } = useContractWrite(writeUpdateListingConfig);

  const {
    status: mineUpdateListingTransactionStatus,
  } = useWaitForTransaction({
    hash: submitUpdateListingResult ? submitUpdateListingResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeUpdateListingAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeUpdateListingAsync,
    listingIdInput,
    priceInput,
    saleEthRecipientAddressInput,
    setListingIdInput,
    setPriceInput,
    setSaleEthRecipientAddressInput,
    setShouldConfigureUpdateListingWrite,
    shouldConfigureUpdateListingWrite,
    signUpdateListingTransactionStatus,
    mineUpdateListingTransactionStatus,
    transactionHash: submitUpdateListingResult?.hash,
  };
};
