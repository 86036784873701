import { createContext } from 'react'

interface BalancesValues {
  ethBalance: bigint | null
  refetchEthBalance: (() => void) | null
  shouldFetchEthBalance: boolean | null
}

const defaultValues: BalancesValues = {
  ethBalance: null,
  refetchEthBalance: null,
  shouldFetchEthBalance: null,
};

const BalancesContext = createContext<BalancesValues>(defaultValues)

export default BalancesContext
