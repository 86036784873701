import React, { useCallback, useState, useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';

import Link from '@mui/material/Link';
import { colors } from '@theme/colors';
import { FinalizeSaleBidDetails } from '@components/Listing/FinalizeSale';
import { ReviewRequirementInstructionRow } from '@components/common/ReviewRequirementInstructionRow';


interface TransferInstructionsProps {
  bidDetails: FinalizeSaleBidDetails
}

export const TransferInstructions: React.FC<TransferInstructionsProps> = ({
  bidDetails
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  /*
   * State
   */

  const [isOpen, setIsOpen] = useState(true);

  const [centerPosition, setCenterPosition] = useState(0);

  const [canAnimate, setCanAnimate] = useState(false);

  /*
   * Hooks
   */

    const updateCenterPosition = useCallback(() => {
      if (containerRef.current && titleRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const titleWidth = titleRef.current.offsetWidth;
        const newCenterPosition = (containerWidth - titleWidth) / 2;
        setCenterPosition(newCenterPosition);
      }
    }, []);
  
    useLayoutEffect(() => {
      updateCenterPosition();
    }, [updateCenterPosition]);
  
    useEffect(() => {
      const handleResize = () => {
        requestAnimationFrame(updateCenterPosition);
      };
  
      window.addEventListener('resize', handleResize);
      
      const timer = setTimeout(() => setCanAnimate(true), 0);
  
      return () => {
        window.removeEventListener('resize', handleResize);
        clearTimeout(timer);
      };
    }, [updateCenterPosition]);
  
    const toggleOpen = useCallback(() => setIsOpen(prev => !prev), []);
  
    const titleStyle = useMemo(() => ({
      transform: `translateX(${isOpen ? centerPosition : 0}px)`
    }), [isOpen, centerPosition]);

  /*
   * Component
   */

  return (
    <Wrapper ref={wrapperRef}>
        <TitleLabelAndDropdownIconContainer ref={containerRef} $isOpen={isOpen}>
          <TitleLabelWrapper>
            <TitleLabel  ref={titleRef}  $canAnimate={canAnimate} style={titleStyle}>
              Review Instructions
            </TitleLabel>
          </TitleLabelWrapper>
          
          <StyledChevronDown
            onClick={toggleOpen}
            $isOpen={isOpen}
          />
        </TitleLabelAndDropdownIconContainer>

        <InstructionsDropdown $isOpen={isOpen}>
          <ReviewRequirementInstructionRow step={1}>
            { "Open the "} <strong>{bidDetails.domainName}</strong> {" "}
            <Link
            href={`https://ap.www.namecheap.com/domains/dcp/share/${bidDetails.domainName}/rights`}
            target="_blank"
            rel="noopener noreferrer">
                <strong>management page ↗</strong>
            </Link>
              {" "} on Namecheap
          </ReviewRequirementInstructionRow>

          <ReviewRequirementInstructionRow step={2}>
            Input <strong>{bidDetails.decryptedBuyerId}</strong> as the new owner and authorize the transfer
          </ReviewRequirementInstructionRow>

          <RequirementsContainer>
            <WarningLabel>
              A valid Gmail account linked to your Namecheap account is required to finalize. If not, please&nbsp;
              <Link
                href={`https://ap.www.namecheap.com/settings/personal-info`}
                target="_blank"
                rel="noopener noreferrer">
                  <strong>update your email ↗</strong>
              </Link>
              &nbsp;on Namecheap
            </WarningLabel>
          </RequirementsContainer>

          <ReviewRequirementInstructionRow step={3}>
            Once the domain transfer is accepted, you will get a <strong>confirmation email</strong> from Namecheap.
            Navigate back to this page to sign in with Google and generate the proof of transfer
          </ReviewRequirementInstructionRow>

          <RequirementsContainer>
            <WarningLabel>
              Cancel the transfer request if it is not accepted within 24 hours. Otherwise, you may lose the domain.
            </WarningLabel>
          </RequirementsContainer>
        </InstructionsDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 20px 12px 20px;
  border-radius: 8px;
  border: 1px solid ${colors.defaultBorderColor};
`;

const TitleLabelAndDropdownIconContainer = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${({ $isOpen }) => $isOpen ? '1.25rem' : '0rem'};
  transition: padding-bottom 0.4s ease-in-out;
  align-items: center;
`;

const TitleLabelWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
`;

const TitleLabel = styled.div<{ $canAnimate: boolean }>`
  display: flex;
  align-items: center;
  font-size: 15px;
  transition: ${({ $canAnimate }) => $canAnimate ? 'transform 0.4s ease-out' : 'none'};
`;

const StyledChevronDown = styled(ChevronDown)<{ $isOpen: boolean }>`
  width: 20px;
  height: 20px;
  color: #CED4DA;

  transition: transform 0.4s;
  transform: ${({ $isOpen }) => $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const InstructionsDropdown = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #FFF;
  gap: 16px;
  overflow: hidden;

  max-height: ${({ $isOpen }) => $isOpen ? '500px' : '0rem'};
  transition: max-height 0.4s ease-out;
`;

const RequirementsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  border: 1px solid;
  border-color: #C0C0C0;
  background-color: #FFF;
  border-radius: 8px;
  padding: 16px;
  align-items: center;
`;

const WarningLabel = styled.span`
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  line-height: 1.25;
  color: #FF0000;
`;
