import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { LogOut, RefreshCw } from 'react-feather';

import Spinner from '@components/common/Spinner';
import { colors } from '@theme/colors';


type iconType = 
  "logout" |
  "refresh";

interface AccessoryButtonProps {
  fullWidth?: boolean;
  title?: string;
  width?: number | string;
  height?: number;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  icon?: iconType;
  borderColor?: string;
  backgroundColor?: string;
  backgroundHoverColor?: string;
  activeBackgroundColor?: string;
  hoverColor?: string;
  borderHoverColor?: string;
  spinnerColor?: string;
  padding?: string;
  isHovered?: boolean;
  fontSize?: number;
}

const primaryColors = {
  backgroundColor: 'transparent',
  borderColor: colors.buttonDefault,
  borderHoverColor: colors.buttonDefault,
  backgroundHoverColor: '#FF5E14',
  activeBackgroundColor: 'background: rgba(206, 212, 218, 0.1);',
  hoverColor: '#FFF',
  textColor: '#FF5E14',
  spinnerColor: '#FF5E14'
}

export const AccessoryButton: React.FC<AccessoryButtonProps> = ({
  fullWidth = false,
  title = '',
  width,
  height = 48,
  disabled = false,
  loading = false,
  onClick,
  children,
  icon,
  padding = '2px 14px 0px 14px',
  isHovered = false,
  fontSize = 13
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  /*
   * State
   */

  const [buttonWidth, setButtonWidth] = useState<number | null>(null);

  /*
   * Hooks
   */

  useEffect(() => {
    if (buttonRef.current && !loading) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [loading, children, title]);

  const containerStyle: React.CSSProperties = {};
  if (loading && buttonWidth) {
    containerStyle.width = `${buttonWidth}px`;
  }
  if (width) {
    containerStyle.width = typeof width === 'number' ? `${width}px` : width;
  }

  /*
   * Helpers
   */

  const getIcon = (iconName: iconType) => {
    switch (iconName) {
      case "logout":
        return <StyledLogOut />;

      case "refresh":
        return <StyledRefresh />;

      default:
        return null;
    }
  };

  /*
   * Component
   */

  return (
    <Container
      ref={buttonRef}
      style={containerStyle}
      fullWidth={fullWidth}
      height={height}
      disabled={disabled || loading}
      $loading={loading}
      backgroundColor={primaryColors.backgroundColor}
      borderColor={primaryColors.borderColor}
      hoverColor={primaryColors.hoverColor}
      borderHoverColor={primaryColors.borderHoverColor}
      backgroundHoverColor={primaryColors.backgroundHoverColor}
      activeBackgroundColor={primaryColors.activeBackgroundColor}
      spinnerColor={primaryColors.spinnerColor}
      onClick={onClick}
      padding={padding}
      $isHovered={isHovered}
    >
      <ButtonAndLabelContainer
        color={primaryColors.textColor}
        $loading={loading}
        $isHovered={isHovered}
        hoverColor={primaryColors.hoverColor}
        fontSize={fontSize}
      >
        {loading ? (
          <Spinner color={primaryColors.spinnerColor}/>
        ) : (
          <>
            {title && <span>{title}</span>}
            {icon && getIcon(icon)}
            {children}
          </>
        )}
      </ButtonAndLabelContainer>
    </Container>
  );
};

const Container = styled.button<AccessoryButtonProps & { $loading: boolean, $isHovered: boolean }>`
  width: ${({ fullWidth, width }) => (fullWidth ? '100%' : width ? (typeof width === 'number' ? `${width}px` : width) : 'auto')};
  height: ${({ height }) => height ? `${height}px` : 'auto'};
  border-radius: ${({ height }) => height ? `${height / 2}px` : '18px'};
  background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border: 1px solid ${({ borderColor }) => borderColor};
  padding: ${({ padding }) => padding};
  color: white;
  cursor: pointer;
  transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover:not([disabled]) {
    border: 1px solid ${({ borderHoverColor }) => borderHoverColor};
    color: ${({ hoverColor }) => hoverColor};
    background: ${({ backgroundHoverColor }) => backgroundHoverColor};
    box-shadow: inset -3px -6px 4px rgba(0, 0, 0, 0.16);

    * {
      color: ${({ hoverColor }) => hoverColor};
    }
  }

  ${({ $isHovered, hoverColor, backgroundHoverColor, borderHoverColor }) =>
    $isHovered &&
    css`
      border: 1px solid ${borderHoverColor};
      color: ${hoverColor};
      background: ${backgroundHoverColor};
      box-shadow: inset -3px -6px 4px rgba(0, 0, 0, 0.16);

      * {
        color: ${hoverColor};
      }
    `}

  &:active:not([disabled]) {
    background: ${({ activeBackgroundColor }) => activeBackgroundColor};
    box-shadow: inset 0px -4px 0px rgba(0, 0, 0, 0.16);
  }

  ${({ $loading }) =>
    $loading && css`
      cursor: wait;
    `
  }
`;

const ButtonAndLabelContainer = styled.div<{ color: string, $loading: boolean, $isHovered: boolean, hoverColor: string, fontSize: number }>`
  width: 100%;  
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }) => fontSize}px;
  font-family: 'Graphik';
  font-weight: 600;
  text-align: center;
  color: ${({ color, $isHovered, hoverColor }) => $isHovered ? hoverColor : color};
  gap: 8px;
`;

const StyledLogOut = styled(LogOut)`
  width: 15px;
  height: 15px;
  color: ${colors.accessoryButtonIconDefault};
`;

const StyledRefresh = styled(RefreshCw)`
  width: 15px;
  height: 15px;
  color: #FF5E14;
`;
