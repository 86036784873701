import React from 'react';
import styled, { css } from 'styled-components/macro';

import { colors } from '@theme/colors';


interface DomainsRowProps {
  isScrolling: boolean;
  name: string;
  isSelected: boolean;
  onRowClick: () => void;
  rowIndex: number;
}

export const DomainsRow: React.FC<DomainsRowProps> = ({
  isScrolling,
  name,
  isSelected,
  onRowClick,
}: DomainsRowProps) => {
  DomainsRow.displayName = "DomainsRow";

  return (
    <Container
      onClick={onRowClick}
      selected={isSelected}
      $scrolling={isScrolling}
    >
      <DomainDetailsAndCheckboxContainer
        $scrolling={isScrolling}
      >
        <DomainDetailsContainer>
          <SummaryLabelsAndIconContainer>
            <SummaryLabelsContainer>
              <SummaryLabel>
                <SummaryLabelValue>
                  {name}
                </SummaryLabelValue>
              </SummaryLabel>
            </SummaryLabelsContainer>
          </SummaryLabelsAndIconContainer>
        </DomainDetailsContainer>
      </DomainDetailsAndCheckboxContainer>
    </Container>
  );
};

const Container = styled.div<{ selected: boolean, $scrolling: boolean }>`
  display: flex;
  flex-direction: row;

  ${({ selected }) => selected && `
    background-color: ${colors.rowSelectedColor};
    box-shadow: none;
  `}

  ${({ selected, $scrolling }) => (!selected && !$scrolling) && `
    &:hover {
      background-color: ${colors.rowHoverColor};
      box-shadow: none;
    }
  `}
`;

const DomainDetailsAndCheckboxContainer = styled.div<{ $scrolling: boolean }>`
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
  padding: 1rem;
  line-height: 24px;

  ${({ $scrolling }) => $scrolling && css`
    padding-right: 1.5rem;
    transition: padding-right 0.3s ease;
  `}

  ${({ $scrolling }) => !$scrolling && css`
    padding-right: 1rem;
    transition: padding-right 0.3s ease;
  `}
`;

const DomainDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SummaryLabelsAndIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

const SummaryLabelsContainer = styled.div`
  width: 100%; 
  display: flex;
  gap: 2px;
  flex-direction: column;
  line-height: 24px;
`;

const SummaryLabel = styled.label`
  display: flex;
  font-size: 15px;
  color: #FFFFFF;
  align-items: center;
`;

const SummaryLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;
