import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { useNavigate } from 'react-router-dom';
import { AutoColumn } from '@components/layouts/Column';
import { ListingsTable } from '@components/SellerListings/Seller/ListingsTable';
import { NewListing } from '@components/SellerListings/NewListing';
import { VerifyDomainsForm } from '@components/SellerListings/NewListing/VerifyDomains';
import { BID_REFETCH_INTERVAL } from '@helpers/constants';
import useSellers from '@hooks/contexts/useSellers';


export default function Listings() {
  /*
   * Contexts
   */

  const { shouldFetchSellerListings, refetchSellerDetailedListings } = useSellers();

  /*
   * State
   */

  const navigate = useNavigate();

  const [isCreateListing, setIsCreateListing] = useState<boolean>(false);

  const [isVerifyDomains, setIsVerifyDomains] = useState<boolean>(false);

  /*
   * Hooks
   */

  useEffect(() => {
    if (shouldFetchSellerListings) {
      const intervalId = setInterval(() => {
        refetchSellerDetailedListings?.();
      }, BID_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchSellerListings]);

  /*
   * Handlers
   */

  const handleCreateListingClicked = () => {
    setIsCreateListing(true);
  }

  const handleCreateListingBackClicked = () => {
    setIsCreateListing(false);

    navigate('/my-listings');
  }

  const handleVerifyDomainsClicked = () => {
    setIsVerifyDomains(true);
  }

  const handleVerifyDomainsBackClicked = () => {
    setIsVerifyDomains(false);
  }


  /*
   * Component
   */

  function renderContent() {
    if (isVerifyDomains) {
      return (
        <VerifyDomainsForm
          handleBackClick={handleVerifyDomainsBackClicked}
        />
      );
    } else {
      if (isCreateListing) {
        return (
          <NewListing
            handleVerifyDomainsPressed={handleVerifyDomainsClicked}
            handleBackClick={handleCreateListingBackClicked}
          />
        );
      } else {
        return (
          <ListingsTable
            handleCreateListingPressed={handleCreateListingClicked}
          />
        )
      }
    }
  }

  return (
    <Wrapper>
      {renderContent()}
    </Wrapper>
  );
};

const Wrapper = styled(AutoColumn)`
  width: 920px;
  margin: auto;
`;
